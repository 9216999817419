import React, { PureComponent } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import isIE11 from 'utils/isIE11';
import { ThemeContext } from 'components/Chapter';
import withResponsive from 'containers/Responsive/withResponsive';
import withSound from 'components/Sound/withSound';
import Teaser from './Teaser';
import { ChapterContent, SVG } from './styled';

class Intro extends PureComponent {
  static contextType = ThemeContext;

  static propTypes = {
    children: PropTypes.element.isRequired,
    isMobile: PropTypes.bool
  };

  static defaultProps = {
    isMobile: false
  };

  state = {
    progress: 0,
    done: false,
    teaserMounted: true,
    scrollBlocked: true,
    isIE: false,
    innerWidth: 0,
    innerHeight: 0
  };

  componentDidMount() {
    this.checkForIE();
    this.setScreenSize();
  }

  setScreenSize() {
    const { innerHeight, innerWidth } = window;
    this.setState({ innerHeight, innerWidth });
  }

  setProgress = (progress, cb) => {
    const { done } = this.state;
    if (progress <= 105 && !done) {
      return this.setState({ progress }, cb || null);
    }
    return this.setState({ progress: 100, done: true }, cb || null);
  };

  checkForIE() {
    this.setState({
      isIE: isIE11()
    });
  }

  unblockScroll = () => {
    const { scrollBlocked } = this.state;
    if (scrollBlocked) {
      this.setState({
        scrollBlocked: false
      });
    }
  };

  activateSound = () => {
    this.props.sound.actions.togglePlayStatus('PLAYING');
  };

  handleAnimationEnd = () => {
    if (this.state.done) {
      this.unblockScroll();
      this.activateSound();
    }
  };

  render() {
    const { done, scrollBlocked, isIE, innerWidth, innerHeight } = this.state;
    const { children, isMobile } = this.props;
    const { chapter } = this.context;

    return isMobile ? (
      children
    ) : (
      <div>
        {scrollBlocked ? (
          <Teaser
            {...this.state}
            setProgress={this.setProgress}
            chapter={chapter}
          />
        ) : null}
        <ChapterContent done={done} isIE={isIE} scrollBlocked={scrollBlocked}>
          {children(done, this.handleAnimationEnd)}
        </ChapterContent>
      </div>
    );
  }
}

export default compose(
  withSound,
  withResponsive
)(Intro);
