import React, { Component } from 'react';
import Swipeable from 'react-swipeable';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { injectIntl } from 'react-intl';
import breakpoints from 'styles/breakpoints';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { Overlay } from './Desktop/styled';
import { EverythingWrapper, BodyWrapper } from './styled';

class NavMenu extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    intl: PropTypes.shape({
      locale: PropTypes.string
    })
  };

  static defaultProps = {
    intl: { locale: 'en' }
  };

  state = {
    open: false,
    topBarOpen: true
  };

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  toggleTopBar = () => {
    this.setState({
      topBarOpen: !this.state.topBarOpen
    });
  };

  handleClick = () => {
    this.toggle();
  };

  handleSwipe = direction => {
    const { topBarOpen, open } = this.state;
    if (direction === 'up' && topBarOpen && !open) {
      this.toggleTopBar();
    }
    if (direction === 'down' && !topBarOpen && !open) {
      this.toggleTopBar();
    }
  };

  render() {
    const {
      children,
      intl: { locale }
    } = this.props;
    const { open } = this.state;
    return (
      <EverythingWrapper>
        {/* -- Mobile -- */}

        <MediaQuery query={`(max-width: ${breakpoints.sm * 16}px)`}>
          <Swipeable
            onSwipedUp={() => this.handleSwipe('up')}
            onSwipedDown={() => this.handleSwipe('down')}
          >
            <Mobile
              locale={locale}
              {...this.props}
              {...this.state}
              handleClick={this.handleClick}
            />
            <BodyWrapper menuOpen={open}>{children}</BodyWrapper>
          </Swipeable>
        </MediaQuery>

        {/* -- Desktop -- */}
        <MediaQuery query={`(min-width: ${breakpoints.sm * 16}px)`}>
          <Desktop
            {...this.props}
            locale={locale}
            open={open}
            handleClick={this.handleClick}
          />
          <Overlay open={open} onClick={open ? this.handleClick : null} />
          {children}
        </MediaQuery>
      </EverythingWrapper>
    );
  }
}

export default injectIntl(NavMenu);
