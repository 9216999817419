import { css } from 'styled-components';
import breakpoints from './breakpoints';

/**
 * Iterate through breakpoints creating media query templates for each.
 * Convert min-widths of grid breakpoints to max-widths for large to small
 * CSS, except for lg which is min-width. Example:
 * xs: max-width 47em
 * sm: max-width 64em
 * md: max-width 75em
 * lg: min-width 75em
 * portait: tablets in portrait mode
 *
 * This can be added directly inside styled component like so:
 *
 * import { media } from 'styles';
 * const StyledComponent = styled.div`
 *   ${media.sm`
 *     width: 100%;
 *   `}
 * `;
 */

const labels = Object.keys(breakpoints);

const media = labels.reduce((acc, label, i) => {
  acc[label] = (...args) => {
    if (label === 'lg') {
      return css`
        @media (min-width: ${breakpoints[label]}em) {
          ${css(...args)};
        }
      `;
    }
    return css`
      @media (max-width: ${breakpoints[labels[i + 1]]}em) {
        ${css(...args)};
      }
    `;
  };
  return acc;
}, {});

// Breakpoint for tablets in portrait mode
media.portrait = (...args) => css`
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    ${css(...args)};
  }
`;

// Breakpoint for tablets in landscape mode
media.landscape = (...args) => css`
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    ${css(...args)};
  }
`;

/**
 * Returns an array of media queries for a specified style.
 * Sorts the media queries from highest to lowest breakpoint.
 * @param {string} a css style declaration
 * @param {object} an object of values foor that style at the breakpoints specified in styles/breakpoints
 */

media.queries = (style, prop = {}) =>
  labels
    .map(point => ({ name: point, point: breakpoints[point] }))
    .sort((first, second) => second.point - first.point)
    .map(({ name }) => media[name]`${style}: ${prop[name]};`);

export default media;
