import styled from 'styled-components';
import Image from 'components/Image';
import media from 'styles/media';
import { P } from 'styles/typography';
import { serif } from 'styles/variables';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 90px 0 0 0;
  direction: ${props => (props.locale === 'ar' ? 'rtl' : 'ltr')};

  ${media.xs`
    flex-flow: row wrap;
    padding: 0;
  `};
`;

export const Person = styled.div`
  margin: 0 1rem 0 0;
  flex: ${props => (props.imageWidth ? `1 0 ${props.imageWidth}` : '1 0 auto')};

  ${media.xs`
    display: flex;
    width: 100%;
    flex: 1 0 auto;
    margin: 0 0 0 0;
    justify-content: center;
  `};
`;

export const PersonPicture = styled(Image)`
  width: 75%;
  height: 75%;
`;

export const QuoteWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;

  ${media.xs`
    margin: 1rem 0 0;
  `};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
`;

export const Quote = styled.div`
  font-family: ${serif};
  font-size: 1.625rem;
  line-height: 2.25rem;
  margin: 0;
  width: 100%;

  ${media.md`
    font-size: 1.375rem;
    line-height: 2rem;
  `};

  ${media.xs`
    margin: 0;
  `};
`;

export const QuoteCaption = styled(P)`
  width: 100%;
  margin: 1rem 0 0;

  ${media.xs`
    margin: 1rem 0 0;
  `};
`;
