import styled from 'styled-components';

import {offblue, white, titleSans, sans, chetwodeBlue} from 'styles/variables';
import { H2, P } from 'styles/typography';
import media from 'styles/media';

export const Wrapper = styled.div`
  //min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: ${offblue};
  overflow: hidden;

  @media screen and (max-width: 751px) {
    overflow-y: auto;
  }

  ${media.xs`
    margin: 2rem 0;
  `}
`;

export const Title = styled(H2)`
  font-size: 3.1rem;
  color: ${white};
  text-align: center;
  text-transform: uppercase;
  padding: 4rem 0;
  margin: 0;
  font-family: ${titleSans};

  @media screen and (max-width: 1024px) {
    font-size: 40px
  }
`;

export const MainWrapper = styled.div`
  padding: 18px 48px 18px 18px;

  @media screen and (max-width: 1280px) {
    padding: 18px 0px 5rem 18px;
  }

  @media screen and (max-width: 1024px) {
    padding: 18px 18px 5rem 18px;
  }
`;

export const Main = styled.div`
  width: 80%;
  margin: 0 auto 0 auto;

  @media screen and (max-width: 1280px) {
    width: 90%;
    margin: 0 auto 0 15px;
  }

  @media screen and (max-width: 751px) {
    width: 100%;
    margin: 0 auto 0 auto;
  }
`;

export const SelectSection = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 0 auto 0 auto;
  position: relative;
`;

export const LastUpdated = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  font-family: ${sans};
  font-size: 15px;
  color: ${chetwodeBlue};

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }

  ${media.xs`
    position: relative;
    top: initial;
    max-width: 400px;
    margin: 25px auto 0 auto;
    font-size: 12px;
  `};

  span {
    display: block;
    margin-bottom: 2px;

    ${media.xs`
      display: inline;
    `};
  }
`;

export const Row = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 6rem;

  &:last-child {
    margin-bottom: 4rem;
  }

  ${media.xs`
    margin-bottom: 2rem;
 `};

 @media only screen and (min-width: 753px) and (max-width: 1080px) {
   &:last-child {
    margin-bottom: 0;
   }
 }
`;

export const SelectLabel = styled(P)`
  color: ${white};
  text-align: left;
  margin-bottom: 5px;

  @media screen and (max-width: 1280px) {
    font-size: 15px;
  }

  ${media.xs`
   margin-bottom: 14px;
 `};
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${media.xs`
    display: block;
 `};

  @media only screen and (max-width: 768px) {
    justify-content: space-around;
  }
`;

export const HiddenItem = styled.div`
  width: 220px;
  height: 220px;
  display: none;

  @media only screen and (min-device-width : 769px) and (max-width: 1024px) {
    display: block;
  }
`;
