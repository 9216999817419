import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import withResponsive from 'containers/Responsive/withResponsive';
import Caption from 'components/Collage/Caption';
import ParallaxMousemove from './ParallaxMousemove';
import { Wrapper, StyledImage } from './styled';

const configBottom = {
  xFactor: -0.05,
  yFactor: -0.05,
  springSettings: {
    stiffness: 60,
    damping: 50
  }
};

const configMiddle = {
  xFactor: -0.08,
  yFactor: 0.06,
  springSettings: {
    stiffness: 60,
    damping: 50
  }
};

const configTop = {
  xFactor: 0.05,
  yFactor: 0.05,
  springSettings: {
    stiffness: 60,
    damping: 50
  }
};

const Collage = ({ isMobile, layers, layerStyle, imageStyle, caption }) => (
  <VisibilitySensor partialVisibility>
    {({ isVisible }) => (
      <Wrapper>
        <ParallaxMousemove
          containerStyle={{
            width: '100%',
            height: 'auto',
            position: 'relative',
            display: 'inline-block'
          }}
          disabled={!isVisible || isMobile}
        >
          <ParallaxMousemove.Layer
            layerStyle={{
              position: 'relative',
              top: 0,
              left: 0,
              ...layerStyle
            }}
            config={configBottom}
            disabled={!isVisible || isMobile}
          >
            <StyledImage id={layers[2]} imageStyle={imageStyle} />
          </ParallaxMousemove.Layer>
          <ParallaxMousemove.Layer
            layerStyle={{
              position: 'absolute',
              top: 0,
              left: 0,
              ...layerStyle
            }}
            config={configMiddle}
            disabled={!isVisible || isMobile}
          >
            <StyledImage id={layers[1]} imageStyle={imageStyle} />
          </ParallaxMousemove.Layer>
          <ParallaxMousemove.Layer
            layerStyle={{
              position: 'absolute',
              top: 0,
              left: 0,
              ...layerStyle
            }}
            config={configTop}
            disabled={!isVisible || isMobile}
          >
            <StyledImage id={layers[0]} imageStyle={imageStyle} />
          </ParallaxMousemove.Layer>
        </ParallaxMousemove>
        {caption.message ? (
          <Caption {...caption} />
        ) : null}
      </Wrapper>
    )}
  </VisibilitySensor>
);

Collage.propTypes = {
  isMobile: PropTypes.bool,
  layers: PropTypes.arrayOf(PropTypes.string).isRequired,
  layerStyle: PropTypes.shape({}),
  imageStyle: PropTypes.shape({}),
  caption: PropTypes.shape({})
};

Collage.defaultProps = {
  isMobile: false,
  layerStyle: {},
  imageStyle: {
    width: { lg: null, md: null, sm: null, xs: null },
    height: { lg: null, md: null, sm: null, xs: null }
  },
  caption: {
    message: null,
    colProps: {
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12
    }
  }
};

export default withResponsive(Collage);
