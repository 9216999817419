import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import white from 'assets/images/twitter.svg';
import black from 'assets/images/twitter-black.svg';
import { Link, Button } from './styled';

const icon = {
  white,
  black
};

const Twitter = ({ size, color, post, intl: { messages, formatMessage } }) => (
  <Link
    href={`https://twitter.com/home?status=${encodeURIComponent(
      formatMessage({
        id: post,
        defaultMessage: messages.home_socialMedia
      })
    )}`}
    target="_blank"
  >
    <Button size={size} src={icon[color]} />
  </Link>
);

const { string, shape, func } = PropTypes;
Twitter.propTypes = {
  post: string,
  intl: shape({
    messages: shape({}).isRequired,
    formatMessage: func.isRequired
  }).isRequired,
  color: string
};

Twitter.defaultProps = {
  post: 'home_socialMedia',
  color: 'black'
};

export default injectIntl(Twitter);
