import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  position: relative;
  display: inline-block;
  min-height: 60rem;
  width: 100%;
  margin: 5rem 0 0;

  ${media.sm`
    min-height: 40rem;
  `};

  .parallax-outer {
    position: absolute;
    left: 0;
    top: 20%;
  }

  ${media.xs`
    height: auto;
    margin: 2rem 0;

    .parallax-outer {
      position: relative;
      display: inline-block;
      top: 0;
    }
  `};
`;

export const FigureWrapper = styled.div`
  width: 50vw;

  ${media.md`
    width: 50vw;
  `};

  ${media.portrait`
    width: 60vw;
  `};
`;

export default Wrapper;
