import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Figure from 'components/Figure';
import { Row, Col } from 'react-styled-flexboxgrid';
import InfoBox from 'components/InfoBox';
import getContent from 'utils/getContentBySubstring';
import { Wrapper, FigureWrapper, InnerWrapper } from './styled';

class Details extends Component {
  static propTypes = {
    messages: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    figureProps: PropTypes.shape({
      image: PropTypes.string,
      caption: PropTypes.string
    })
  };

  static defaultProps = {
    messages: [''],
    figureProps: {
      image: '',
      caption: ''
    }
  };

  getMessages = () => {
    const { messages, intl } = this.props;
    if (typeof messages === 'string') {
      return getContent(intl.messages, messages);
    }
    return messages;
  };

  render() {
    const { figureProps } = this.props;

    const messages = this.getMessages();

    return (
      <Wrapper>
        <InnerWrapper>
          <FigureWrapper>
            <Row>
              <Col xs={12} smOffset={1} sm={6} mdOffset={1} md={5}>
                <Figure
                  {...figureProps}
                  captionColor="white"
                  captionPadding={{
                    lg: '0 25% 0 0',
                    md: '0 25% 0 0',
                    sm: '0 40% 0 0'
                  }}
                  width="100%"
                />
              </Col>
            </Row>
          </FigureWrapper>
          <Row>
            <Col
              xs={12}
              smOffset={5}
              sm={6}
              mdOffset={5}
              md={5}
              lgOffset={5}
              lg={5}
            >
              <InfoBox type="step" messages={messages} />
            </Col>
          </Row>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default injectIntl(Details);
