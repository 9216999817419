import React from 'react';
import PropTypes from 'prop-types';
import Language from '../../Language';
import { LanguageMenu, Option, Select, SelectorWrapper } from './styled';

const LanguagesMenu = ({ mobile, open }) => (
  <LanguageMenu open={open}>
    <Language
      render={({ value, languages, setLanguage }) =>
        mobile ? (
          <SelectorWrapper>
            <Select
              value={value}
              onChange={event => setLanguage(event.target.value)}
            >
              {languages.map(lang => (
                <option key={lang.value} value={lang.value}>
                  {lang.text.toUpperCase()}
                </option>
              ))}
            </Select>
          </SelectorWrapper>
        ) : (
          languages.map(lang => (
            <Option
              selected={lang.value === value}
              key={lang.value}
              onClick={() => setLanguage(lang.value)}
            >
              {lang.text}
            </Option>
          ))
        )
      }
    />
  </LanguageMenu>
);

const { bool } = PropTypes;

LanguagesMenu.propTypes = {
  mobile: bool,
  open: bool
};

LanguagesMenu.defaultProps = {
  mobile: false,
  open: false
};

export default LanguagesMenu;
