/* eslint-disable react/prefer-stateless-function */

import React, { Fragment, PureComponent } from 'react';
import { compose } from 'redux';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import Soundbyte from 'components/Sound/Soundbyte';
import Icon from 'components/Sound/Icon';
import Rings from 'components/Sound/Rings';
import withSound from 'components/Sound/withSound';
import {
  Wrapper,
  Person,
  PersonPicture,
  QuoteWrapper,
  Quote,
  QuoteCaption,
  IconWrapper
} from './styled';

class QuoteWithSound extends PureComponent {
  static propTypes = {
    imageID: PropTypes.string,
    imageWidth: PropTypes.string,
    soundID: PropTypes.string.isRequired,
    quoteID: PropTypes.string.isRequired,
    captionID: PropTypes.string.isRequired,
    sound: PropTypes.shape({}).isRequired
  };

  static defaultProps = {
    imageWidth: null,
    imageID: null
  };

  state = {
    visible: false,
    status: 'STOPPED',
    played: false
  };

  onVisible = isVisible => {
    this.setState({
      visible: isVisible
    });
  };

  getIconPlayStatus = (contextStatus, stateStatus) => {
    if (contextStatus === 'PLAYING' && stateStatus === 'PLAYING') {
      return 'PLAYING';
    }
    return 'STOPPED';
  };

  handleFinishedPlaying = () => {
    this.setState({
      played: true,
      status: 'STOPPED'
    });
  };

  handleOnPlaying = () => {
    this.setState({
      status: 'PLAYING'
    });
  };

  handleIconClick = () => {
    const { status } = this.state;
    if (status === 'PLAYING') {
      return this.setState({ played: true, status: 'STOPPED' });
    }
    return this.setState({ played: false, status: 'PLAYING' });
  };

  render() {
    const { visible, played, status } = this.state;
    const {
      imageID,
      soundID,
      imageWidth,
      quoteID,
      captionID,
      sound,
      intl: { locale }
    } = this.props;
    return (
      <Fragment>
        {!played ? (
          <Soundbyte
            id={soundID}
            playing={visible}
            onFinishedPlaying={this.handleFinishedPlaying}
            onPlaying={this.handleOnPlaying}
          />
        ) : null}
        <VisibilitySensor
          partialVisibility
          delayedCall
          resizeCheck
          onChange={this.onVisible}
        >
          <Wrapper locale={locale}>
            {imageID ? (
              <Person imageWidth={imageWidth}>
                <PersonPicture id={imageID} />
              </Person>
            ) : null}
            <QuoteWrapper>
              <IconWrapper>
                {soundID && (
                  <Rings>
                    <Icon
                      onClick={this.handleIconClick}
                      status={this.getIconPlayStatus(sound.playStatus, status)}
                    />
                  </Rings>
                )}
              </IconWrapper>
              <Quote>
                <FormattedHTMLMessage id={quoteID} />
              </Quote>
              <QuoteCaption>
                <FormattedHTMLMessage id={captionID} />
              </QuoteCaption>
            </QuoteWrapper>
          </Wrapper>
        </VisibilitySensor>
      </Fragment>
    );
  }
}

export default compose(
  withSound,
  injectIntl
)(QuoteWithSound);
