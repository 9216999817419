import styled from 'styled-components';
import { paragraphMixin } from 'styles/typography';
import media from 'styles/media';

export const Wrapper = styled.section`
  display: inline-block;
  width: 100%;
  margin: 5rem 0;
  height: auto;

  ${media.xs`
    margin: 2rem 0;
  `}

  p {
    text-align: center;
    ${paragraphMixin};
  }
`;

export default Wrapper;
