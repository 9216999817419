import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 5rem 0;

  ${media.sm`
    margin: 2rem 0;
  `};

  .video-col {

    &:nth-child(odd) {
      padding: 0 15px 30px 0;
    }

    &:nth-child(even) {
      padding: 0 0 30px 15px;
    }

    ${media.xs`
      margin: 0 0 2rem 0;

      &:nth-child(odd) {
        padding: 0;
      }

      &:nth-child(even) {
        padding: 0;
      }
    `};
  }
`;

export const FullWidthThumb = styled.div`
  width: 100%;
  height: 100%;
  max-height: 490px;
  background-color: #000;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: baseline;

  .video-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export default Wrapper;
