/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import media from 'styles/media';
import { black, sans, white } from 'styles/variables';
import { P } from 'styles/typography';
import { margin, animation, navMaxHeight } from '../variables';
import { fadeIn } from '../effects';

export const Nav = styled.nav`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  justify-content: flex-start;
  padding: 0 ${margin.right.lg}rem 0 ${margin.left.lg + margin.inner.lg}rem;
  direction: ${props => (props.locale === 'ar' ? 'rtl' : 'ltr')};
  max-height: ${navMaxHeight}rem;

  ${fadeIn};

  ${media.md`
    padding: 0 ${margin.right.md}rem 0 ${margin.left.md + margin.inner.md}rem;
  `};

  ${media.xs`
    max-height: initial;
    animation: none;
    opacity: 1;
    justify-content: space-between;
    padding: 0 ${margin.right.sm}rem 0 ${margin.left.sm}rem;
    flex: 1 1 auto;
  `};
`;

export const AboutWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  flex: 1 1 auto;

  &:before,
  &:after {
    content: '';
    display: block;
  }

  ${media.md`
    flex: 1 1 auto;
  `};
`;

export const HR = styled.hr`
  border: none;
  border-top: 1px solid ${white};
  margin: 0.625rem ${margin.right.lg}rem 0 0;

  ${media.md`
    margin: 0.625rem ${margin.right.md}rem 0 0;
  `};

  ${media.xs`
    margin: 0 ${margin.right.sm + margin.inner.sm}rem 0 ${margin.inner.sm +
    0.8}rem;
  `};
`;

export const AboutLinks = styled.div`
  margin: ${props =>
    props.locale === 'ar' ? `0 ${margin.right.lg}rem 0 0` : 0};

  ${media.md`
    margin: ${props =>
      props.locale === 'ar' ? `0 ${margin.right.md}rem 0 0` : 0};
  `};

  ${media.xs`
    margin: 0;
    &:before {
      font-size: 0.866rem;
      content: '${props => (props.isSelected ? '\\25A0' : '\\25A2')}';
      margin: ${props =>
        props.locale === 'ar'
          ? `0 0 0 ${margin.inner.sm}rem`
          : `0 ${margin.inner.sm}rem 0 0`} ;
    }
  `};
`;

export const InlineDivider = styled.span`
  display: inline-block;
  margin: 0;
  padding: 0;
  color: ${black};
  opacity: 0.5;
`;

export const About = styled(P)`
  display: inline-block;
  font-family: ${sans};
  opacity: 0.5;
  color: ${props => (props.color === 'white' ? white : black)};
  font-size: 0.866rem;
  line-height: 0.9375rem;
  text-decoration: none;
  padding: 0;
  margin: 1rem 0 0;
  transition: opacity ${animation.medium}ms ease;

  &:hover {
    opacity: 1;
  }

  ${media.xs`
    font-size: 0.8rem;
    margin: 1.5rem 0 0;
  `};
`;
