import styled from 'styled-components';
import media from 'styles/media';
import { peat, black, sans, white } from 'styles/variables';
import { P } from 'styles/typography';
import { margin } from '../variables';

export const Footer = styled.footer`
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props =>
    props.locale === 'ar' ? 'flex-end' : 'flex-start'};
  align-items: center;
  border: none;
  background-color: ${black};
  padding: ${props =>
    props.locale === 'ar'
      ? `0 ${margin.right.lg * 2}rem 0 0`
      : `0 0 0 ${margin.left.lg + margin.inner.lg}rem`};
  flex: 1 0 auto;

  p {
    margin: 0;
  }

  a {
    display: inline-block;
  }

  & > a p {
    &:after {
      content: '|';
      margin: 0 3px;
    }
  }

  & a:last-child p {
    &:after {
      content: '';
    }
  }

  ${media.md`
      padding: ${props =>
        props.locale === 'ar'
          ? `0 ${margin.right.lg + margin.inner.lg}rem 0 0`
          : `0 0 0 ${margin.left.md + margin.inner.md}rem`};};
  `};

  ${media.xs`
    justify-content: flex-end;
    background-color: ${peat};
    height: 5rem;
    flex: 1 0 2.5rem;
    padding: 0 ${margin.right.sm}rem 0 0;
  `};
`;

export const FooterItem = styled(P)`
  font-family: ${sans};
  color: ${white};
  font-size: 0.7rem;
  text-decoration: none;

  ${media.md`
    font-size: 0.7rem;
  `}

  ${media.sm`
    font-size: 0.7rem;
  `}

`;
