import { keyframes, css } from 'styled-components';
import media from 'styles/media';
import { animation } from './variables';

export const appear = keyframes`
  from  { opacity: 0; }
  to { opacity: 1; }
`;

export const slideIn = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

export const appearAndSlideIn = keyframes`
  from  { opacity: 0; transform: scale(0.9);}
  to { opacity: 1; transform: scale(1);}
`;

export const fadeIn = css`
  opacity: 0;
  animation: ${props =>
    props.open ? css`${appear} ease ${animation.slow}ms` : 'none'};
  animation-fill-mode: forwards;
  animation-delay: ${animation.medium}ms;

  ${media.xs`
    animation: none;
    opacity: 1;
  `};
`;
