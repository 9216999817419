import styled from 'styled-components';
import media from 'styles/media';
import CloudinaryImage from 'components/Image';

const Tear = styled.div`
  position: absolute;
  bottom: -2px;
  content: '';
  background: ${`url(${CloudinaryImage.getImageUrlById(
      `components/chapter-tear`
    )})`}
    no-repeat;
  background-size: 100%;
  width: 100vw;
  height: 150px;
  pointer-events: none;

  ${media.sm`
    background-position: bottom;
    bottom: -2px;
  `};
`;

export default Tear;
