import styled, { css } from 'styled-components';
import { H3, paragraphMixin, h3Mixin, P } from 'styles/typography';
import {
  sans,
  serif,
  white,
  black,
  alto,
  grime,
  yellow,
  green
} from 'styles/variables';
import media from 'styles/media';
import zoom from 'assets/images/magnifying-glass.svg';

const padding = {
  lg: '6.25rem',
  md: '3.1265rem'
};

export const ScrollWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;

  &:after {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    bottom: 0;
    content: '';
    height: 2rem;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 3;

    ${media.md`
      bottom: 0;
    `};

    ${media.xs`
      display: none;
    `};
  }
`;

export const OuterWrapper = styled.div`
  position: relative;
  background-color: ${white};
  border: 1px solid ${black};
  box-shadow: 2rem 2rem ${white};
  padding: ${padding.lg};
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: inherit;
  direction: ${props => (props.locale === 'ar' ? 'rtl' : 'ltr')};
  height: ${props => props.size.lg || 'auto'};

  ${media.md`
    height: ${props => props.size.md || props.size.lg || 'auto'};
    padding: ${padding.md};
  `};

  ${media.sm`
    height: ${props =>
      props.size.sm || props.size.md || props.size.lg || 'auto'};
  `};

  ${media.xs`
    height: auto;
    position: relative;
    top: initial;
    max-width: 100%;
    order: 2;
    padding: 20px 20px 20px 20px;
    box-shadow: 0.5rem 0.5rem ${white};
  `};

  ${ScrollWrapper} {
    &:after {
      right: 10px;
    }

    .scrollbar-view {
      padding: 0 20px;
      margin-right: 0 !important;
      margin-left: ${props => (props.locale === 'ar' ? '-17px' : 0)};
      overflow-x: visible;
    }

    .scrollbar-view::-webkit-scrollbar {
      display: none;
    }

    .scrollbar-view {
      padding: 0 15px;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    mark {
      &:before {
        right: 0;
      }
    }
  }

  h3 {
    ${h3Mixin};
    display: block;
    font-size: 2.5rem;
    line-height: 3.25rem;
    font-weight: 400;
    color: ${black};
    word-break: break-word;
    font-family: ${serif};

    margin: 0 0 2.8rem 0;
    text-align: initial;

    ${media.md`
      font-size: 2rem;
      line-height: 2.5rem;
    `};

    ${media.sm`
      font-size: 1.875rem;
    `};

    ${media.xs`
      line-height: 1;
    `};
  }

  p {
    ${paragraphMixin};
    font-size: 0.95rem;
    font-weight: 400;
    width: 100%;
    display: inline-block;

    &:last-child {
      margin: 0;
    }
  }

  ul {
    ${props => css`
      direction: ${props.locale === 'ar' ? 'rtl' : 'ltr'};
      padding: ${props.locale === 'ar' ? '0 15px 0 0' : '0 0 0 15px'};
    `};
    columns: 1;
    margin: 0 0 1.5rem;
    list-style: none;
    width: 100%;

    ${media.xs`
      justify-content: space-between;
    `};
  }

  p ~ ul {
    margin-top: -1.9rem;
  }

  li {
    ${paragraphMixin};
    font-size: 0.95rem;
    padding: 0 0 0 5px;
    margin: 0;
    vertical-align: center;
    transform: translateY(7px);

    &:before {
      content: '•';
      color: ${green};
      display: inline-block;
      width: 1.3rem;
      font-size: 2rem;
      transform: translateY(5px);

      ${props =>
        props.locale === 'ar'
          ? css`
              margin-right: -1.3rem;
            `
          : css`
              margin-left: -1.3rem;
            `};
    }
  }

  .infobox-text-wrapper span {
    &:after {
      position: relative;
      content: '\00a0';
      height: 3rem;
      display: inline-block;

      ${media.xs`
        display: none;
      `};
    }
  }

  mark {
    background-color: ${yellow};
    cursor: pointer;
    padding: 0 2px;
    display: inline;

    ${media.xs`
      background-color: ${white};
    `};
  }
`;

OuterWrapper.defaultProps = {
  size: {
    lg: null,
    md: null,
    sm: null,
    xs: null
  }
};

export const StepOuterWrapper = styled(OuterWrapper)`
  padding-bottom: 3rem;
  box-shadow: none;
  border: none;
  min-height: 500px;
  display: flex;
  flex-flow: column;

  ${media.portrait`
    min-height: 300px;
  `};

  ${media.landscape`
    min-height: 250px;
  `};

  ${media.xs`
    min-height: initial;
    height: auto;
  `};

  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .counter {
    color: ${grime};
  }

  ${media.xs`
    flex-flow: column-reverse;
    justify-content: flex-start;
  `};
`;

export const InfoBoxHead = styled.div`
  font-family: ${sans};
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  ${media.xs`
    margin: 0 0 1rem;
  `};
`;

export const InfoBoxContent = styled.div`
  margin: 0 0 2rem;
  flex: 1 0 auto;
`;

export const InfoBoxTitle = styled(H3)`
  display: block;
  font-size: 2.5rem;
  font-weight: 400;
  color: ${black};
  word-break: break-word;
  font-family: ${serif};
  line-height: 3.25rem;
  margin: 0 0 2.8rem 0;
  text-align: left;

  ${media.xs`
    line-height: 1;
  `};
`;

export const InfoBoxDescription = styled(P)`
  font-size: 0.95rem;
  font-weight: 400;
  width: 100%;
  display: inline-block;
`;

export const Icon = styled.img`
  display: block;
  align-self: center;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${alto};
  margin: 0 20px;
`;
