import styled from 'styled-components';
import { eternalGray } from 'styles/variables';

export const Wrapper = styled.div`
  position: 'relative';
  z-index: 1;
`;

export const Main = styled.main`
  display: block;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  overflow: hidden;
  z-index: 0;
  position: absolute;
  background-color: ${eternalGray};
`;

export default Main;
