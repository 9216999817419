import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';
import Soundbyte from 'components/Sound/Soundbyte';
import Player from 'components/Player';
import {
  Figure,
  VideoWrapper,
  Video,
  FigCaption,
  VideoOverlay,
  OverlayContent,
  OverlayCaption
} from './styled';

class DesktopMovingImage extends Component {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    videoID: PropTypes.string.isRequired,
    soundID: PropTypes.string.isRequired,
    intl: PropTypes.shape({
      locale: PropTypes.string
    }).isRequired,
    overlay: PropTypes.bool.isRequired,
    overlayMessage: PropTypes.string.isRequired,
    overlayCaption: PropTypes.bool.isRequired
  };

  state = {
    isVisible: false
  };

  videoRef = React.createRef();

  visibilityHandler = isVisible => {
    this.setState({ isVisible }, () => {
      if (this.state.isVisible) {
        return this.videoRef.current.play();
      }
      return this.videoRef.current.pause();
    });
  };

  render() {
    const { isVisible } = this.state;
    const {
      soundID,
      videoID,
      caption,
      overlay,
      overlayMessage,
      overlayCaption
    } = this.props;
    return (
      <Fragment>
        <Soundbyte id={soundID} playing={isVisible} loop />
        <VisibilitySensor partialVisibility onChange={this.visibilityHandler}>
          <Figure>
            <VideoWrapper>
              <Video
                ref={this.videoRef}
                loop
                muted
                preload="auto"
                poster={`${Player.getVideoUrlById(videoID)}.jpg`}
                isVisible={isVisible}
              >
                <source
                  src={Player.getVideoUrlById(videoID, 'auto', 75, 'auto', {
                    ac: 'none',
                    f: 'webm'
                  })}
                  type="video/webm"
                />
                <source
                  src={Player.getVideoUrlById(videoID, 'auto', 75, 'auto', {
                    ac: 'none',
                    f: 'mp4'
                  })}
                  type="video/mp4"
                />
                <p>
                  <FormattedMessage id="interface_html5VideoError" />
                </p>
              </Video>
              {overlay ? <VideoOverlay /> : null}
              {overlayMessage ? (
                <OverlayContent>
                  <FormattedMessage id={overlayMessage} />
                </OverlayContent>
              ) : null}
              {overlayCaption ? (
                <OverlayCaption>
                  <FigCaption>{caption}</FigCaption>
                </OverlayCaption>
              ) : null}
            </VideoWrapper>
            {overlayCaption ? null : <FigCaption>{caption}</FigCaption>}
          </Figure>
        </VisibilitySensor>
      </Fragment>
    );
  }
}

export default DesktopMovingImage;
