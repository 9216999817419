import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {labelColorsByCode, labelIdByCode} from "modules/Conventions/const";
import {
  Wrapper,
  Circle,
  Body,
  Head,
  ColorLabel,
  Name,
  Count,
  Notice,
  NoticeCircle,
  P,
} from './styled';

const ConventionItem = ({ data, max, onClick, protocolData }) => {
  const { convention: { code }, countries } = data;
  const countriesLength = countries.length;
  const labelId = labelIdByCode[code];
  const color = labelColorsByCode[code];
  const codeNumber = parseInt(code.replace('C', ''), 0);
  const shortNameId = `rights_ilsShortNameC${codeNumber}`;

  const handleClick = () => {
    onClick(data);
  };

  const handleProtocolClick = (e) => {
    e.stopPropagation();
    onClick(data, true);
  };

  return (
    <Wrapper onClick={handleClick} max={max} current={countriesLength}>
      <Circle>
        <Head>
          <ColorLabel bgColor={color}><FormattedMessage id={labelId} /></ColorLabel>
          <Name>
            <FormattedMessage id={shortNameId} />
          </Name>
        </Head>
        <Body>
          <Count>{countriesLength}</Count>
          <P><strong><FormattedMessage id="rights_visConventionsCountries" /></strong></P>
          <P><FormattedMessage id="rights_visConventionsRatifs" /></P>
        </Body>
        {codeNumber === 29 && (
          <Notice onClick={handleProtocolClick}>
            <ColorLabel className="notice--label" bgColor={color}><FormattedMessage id="rights_visConventionsP29" /></ColorLabel>
            <NoticeCircle>{protocolData.countries.length}</NoticeCircle>
          </Notice>
        )}
      </Circle>
    </Wrapper>
  );
};

ConventionItem.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  max: PropTypes.number.isRequired,
  protocolData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
};

export default ConventionItem;