/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';

class ImageLoader extends React.Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    if (this.image && this.image.complete) {
      this.handleImageLoaded();
    }
  }

  handleImageLoaded = () => {
    const { delay, onLoadCallback } = this.props;
    window.setTimeout(() => {
      if (!this.state.loaded) {
        this.setState({ loaded: true });
        onLoadCallback();
      }
    }, delay);
  };

  render() {
    const {
      src,
      alt,
      Component,
      delay,
      onLoadCallback,
      ...restProps
    } = this.props;

    if (!src) {
      return (
        <Component
          ref={el => (this.image = el)}
          style={this.state.loaded ? { opacity: 1 } : { opacity: 0 }}
          onLoad={this.handleImageLoaded}
          key={src}
          alt={alt}
          {...restProps}
        />
      );
    }

    return (
      <Component
        ref={el => (this.image = el)}
        onLoad={this.handleImageLoaded}
        src={src}
        key={src}
        alt={alt}
        {...restProps}
      />
    );
  }
}

ImageLoader.propTypes = {
  delay: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onLoadCallback: PropTypes.func
};

ImageLoader.defaultProps = {
  delay: 0,
  alt: 'image',
  src: '',
  Component: Image,
  onLoadCallback: () => {}
};

export default ImageLoader;
