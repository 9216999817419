import styled, { css, keyframes } from 'styled-components';
import { rose, gray, eternalGray, pavement } from 'styles/variables';

export const foreground = keyframes`
  0% {
    stroke-dashoffset: 1005;
  }

  25% {
    stroke-dashoffset: 753.75;
  }

  50% {
     stroke-dashoffset: 502.5;
  }

  75% {
    stroke-dashoffset: 251.25;
  }

  100% {
    stroke-dashoffset: 0;
  }
`;

export const pulse = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const colflex = css`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  ${colflex};
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: ${eternalGray};

  p {
    display: block;
    color: ${rose};
    margin: 0;
  }
`;

export const SVG = styled.svg`
  height: 20rem;
  width: 20rem;
  position: absolute;
  top: 0;
  left: 0;

  .background {
    fill: transparent;
    stroke: ${gray};
    stroke-dasharray: 1005;
    stroke-dashoffset: 0;
  }

  .foreground {
    fill: transparent;
    stroke-dasharray: 1005;
    stroke-dashoffset: 1005;
    stroke: ${rose};
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: all 800ms ease;
    /*animation: ${foreground} 20s ease-in 1000ms forwards;*/
    animation: ${props =>
      css`
        ${foreground} ${props.delay - 500}ms ease-in 500ms forwards;
      `};
  }
`;

export const Circle = styled.div`
  ${colflex};
  width: 100%;
  height: 100%;
  max-width: 20rem;
  max-height: 20rem;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  img {
    display: block;
    width: auto;
    height: 169px;
    transform: rotate(90deg);
  }
`;

export const Headphones = styled.div`
  ${colflex};
  margin: 4rem 0 0;

  p {
    margin: 1rem 0 0;
    color: ${gray};
  }
`;
