/* eslint-disable import/no-mutable-exports, global-require, prefer-destructuring, no-return-assign, no-param-reassign, react/no-did-mount-set-state */


let Mask = null;
let PIXI;
let CustomPIXIComponent;

if (typeof window !== 'undefined' && window) {
  PIXI = require('pixi.js');
  CustomPIXIComponent = require('react-pixi-fiber').CustomPIXIComponent;

  const type = 'Mask';

  const circle = (x, y, r) => {
    const g = new PIXI.Graphics();
    g.clear();
    g.beginFill();
    g.drawCircle(x, y, r);
    g.endFill();
    return g;
  };

  const behavior = {
    customDisplayObject: ({ size, stage: { height, width } }) => {
      const container = new PIXI.Container();
      container.mask = circle(width / 2, height / 2, size / 2);
      return container;
    },
    customApplyProps: (
      instance,
      oldprops,
      { size, stage: { height, width } }
    ) => (instance.mask = circle(width / 2, height / 2, size / 2))
  };

  Mask = CustomPIXIComponent(behavior, type);
}

module.exports = Mask;

