import styled, { css } from 'styled-components';
import matchColor from 'utils/matchColor';
import media from 'styles/media';

const borderRadius = '1.4';
const ringSize = '78';

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Ring = styled.div`
  width: ${props => ringSize * props.size}px;
  height: ${props => ringSize * props.size}px;
  border: ${props => css`${borderRadius}px solid ${matchColor(props.shade)}`};
  border-radius: 50%;
  position: relative;
  ${center};
`;

export const Wrapper = styled.div`
  position: relative;
  ${center};

  ${media.xs`
    display: none;
  `};
`;
