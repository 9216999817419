import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import InfoBox from 'components/InfoBox';
import { Wrapper } from './styled';

const InfoBoxBlock = ({ id, col, infobox }) => (
  <Outer>
    <Inner>
      <Wrapper>
        <Row>
          <Col {...col}>
            <InfoBox id={id} {...infobox}>
              <FormattedHTMLMessage id={id} />
            </InfoBox>
          </Col>
        </Row>
      </Wrapper>
    </Inner>
  </Outer>
);

InfoBoxBlock.propTypes = {
  id: PropTypes.string,
  col: PropTypes.shape({}),
  infobox: PropTypes.shape({})
};

InfoBoxBlock.defaultProps = {
  id: '',
  col: {},
  infobox: {}
};

export default InfoBoxBlock;
