import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { animation } from 'styles/variables';

const defaultStyle = {
  transition: `opacity ${animation.medium}ms ease-in-out`,
  opacity: 0,
  willChange: 'opacity',
  width: '100%'
};

const transitionStyles = {
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
  entering: { opacity: 0 },
  entered: { opacity: 1 }
};

const Fade = ({
  inProp,
  children,
  onExiting,
  onEntering,
  timeout,
  outerStyle,
  ...restProps
}) => (
  <Transition
    in={inProp}
    timeout={timeout}
    onExiting={onExiting}
    OnEntering={onEntering}
    {...restProps}
  >
    {state => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
          ...outerStyle
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

const { bool, node, func, number } = PropTypes;
Fade.propTypes = {
  inProp: bool.isRequired,
  children: node.isRequired,
  onExiting: func,
  onEntering: func,
  timeout: number
};

Fade.defaultProps = {
  onExiting: () => {},
  onEntering: () => {},
  timeout: animation.inter
};

export default Fade;
