import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  height: 61rem;
  overflow: hidden;

  ${media.md`
    height: 48rem;
  `}

  ${media.sm`
    height: 42rem;
  `}

  ${media.landscape`
    margin: 0;
  `}

  ${media.portrait`
    margin: 0;
    min-height: 35rem;
  `}

  ${media.xs`
    height: auto;
    min-height: initial;
  `};
`;

export const CollageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${media.xs`
    position: relative;
  `};

  .parallax-mousemove-container {
    ${media.xs`
      left: -25% !important;
  `};
  }
`;

export default Wrapper;
