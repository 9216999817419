/* eslint-disable no-nested-ternary */

import React, { Component, Fragment, createContext } from 'react';
import PropTypes from 'prop-types';

export const SoundContext = createContext({
  playStatus: '',
  actions: {
    togglePlayStatus: () => {}
  }
});

class CloudinarySound extends Component {
  static getUrlfromId = id => {
    const cloudinaryname = process.env.GATSBY_CLOUDINARY_CLOUD_NAME;
    const cloudinaryFolder =
      process.env.GATSBY_CLOUDINARY_FOLDER === '/'
        ? 'ilo-staging'
        : process.env.GATSBY_CLOUDINARY_FOLDER;
    const getBaseUrl = () =>
      `https://res.cloudinary.com/${cloudinaryname}/video/upload`;

    return `${getBaseUrl()}/v1/${cloudinaryFolder}/${id}`;
  };

  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    playStatus: 'STOPPED',
    actions: {
      // String: null, PLAYING, STOPPED, PAUSED
      togglePlayStatus: status => {
        const { playStatus } = this.state;
        if (!status) {
          return playStatus === 'PLAYING'
            ? this.setState({ playStatus: 'PAUSED' })
            : playStatus === 'PAUSED'
              ? this.setState({ playStatus: 'PLAYING' })
              : null;
        }
        return this.setState({
          playStatus: status
        });
      }
    }
  };

  activateSound = () => {
    this.state.actions.togglePlayStatus('PLAYING');
    window.removeEventListener('click', this.activateSound);
    window.removeEventListener('keydown', this.activateSound);
  };

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <SoundContext.Provider value={this.state}>
          {children}
        </SoundContext.Provider>
      </Fragment>
    );
  }
}

export default CloudinarySound;
