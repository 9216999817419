import React from 'react';
import { ThemeContext } from 'components/Chapter';
import { injectIntl, intlShape } from 'react-intl';
import NextChapter from './NextChapter';

function Next({ intl }) {
  return (
    <ThemeContext.Consumer>
      {({ chapter }) => <NextChapter chapter={chapter} intl={intl} />}
    </ThemeContext.Consumer>
  );
}

Next.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(Next);
