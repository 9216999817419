import React from 'react';
import { theme } from 'config/rights.json';
import { withIntl } from 'i18n';
import Template from 'modules/ChapterTemplate';
import CalloutStampBlock from 'modules/CalloutStamp';
import CalloutHeroBlock from 'modules/CalloutHero';
import MovingImageBlock from 'modules/MovingImage';
import InfoBoxBlock from 'modules/InfoBox';
import Block3Collage from 'blocks/rights/Block-3-Collage';
import Block5Parallax from 'blocks/rights/Block-5-Parallax';
import Block8Collage from 'blocks/rights/Block-8-Collage';
import Block18Collage from 'blocks/rights/Block-18-Collage';
import Conventions from 'modules/Conventions';
import Conclusion from 'modules/Conclusion';
import Videos from 'modules/Videos';
import List from 'components/List';

import countries from 'config/conventions/countries.json';
import c029 from 'config/conventions/c029.json';
import c087 from 'config/conventions/c087.json';
import c098 from 'config/conventions/c098.json';
import c100 from 'config/conventions/c100.json';
import c105 from 'config/conventions/c105.json';
import c111 from 'config/conventions/c111.json';
import c138 from 'config/conventions/c138.json';
import c182 from 'config/conventions/c182.json';
import p029 from 'config/conventions/p029.json';

const Rights = () => (
  <Template theme={theme}>
    <main>
      <Videos videos={['rights/videoFrancis', 'rights/videoZaineb']} />
      <CalloutStampBlock
        stamp="bluecircle"
        calloutId="rights_calloutRightsTitle"
        subtextId="rights_calloutRightsBody"
      />
      <Block3Collage />
      <CalloutStampBlock
        stamp="circle"
        calloutId="rights_calloutDevstandards"
      />
      <Block5Parallax />
      <CalloutStampBlock
        stamp="fingerprint"
        calloutId="rights_calloutSohowprobTitle"
        subtextId="rights_calloutSohowprobBody"
      />
      <MovingImageBlock
        videoID="rights/videoGlobalMarch"
        soundID="rights/global-march-theme"
      />
      <InfoBoxBlock
        id="rights_infoGlobalmarchBody1"
        infobox={{
          scroll: true,
          size: {
            lg: '27rem',
            md: '20rem'
          }
        }}
        col={{
          xs: 12,
          sm: 7,
          smOffset: 5,
          md: 6,
          mdOffset: 6
        }}
      />
      <CalloutHeroBlock
        imageId="rights/universal-values"
        calloutId="rights_calloutUniversalvalues"
        infoboxId="rights_infoGbfourissues"
        outerHeight={{
          lg: '65rem',
          md: '60rem',
          sm: '48rem',
          xs: 'initial'
        }}
      />
      <List
        items={[
          'rights_listFprw1',
          'rights_listFprw2',
          'rights_listFprw3',
          'rights_listFprw4'
        ]}
      />
      <Block8Collage />
      <Conventions
        data={[c029, c087, c098, c100, c105, c111, c138, c182]}
        protocolData={p029}
        countries={countries}
      />
      <InfoBoxBlock
        infobox={{
          detailMessages: [
            'rights_detailSupervisory1',
            'rights_detailSupervisory2',
            'rights_detailSupervisory3',
            'rights_detailSupervisory4',
            'rights_detailSupervisory5'
          ],
          detailFigure: {
            image: 'rights/detail-committee',
            caption: 'rights_imageCasCaption'
          }
        }}
        id="rights_infoSupervisoryBody"
        col={{
          xs: 12,
          smOffset: 3,
          sm: 7,
          mdOffset: 3,
          md: 5,
          lgOffset: 3,
          lg: 6
        }}
      />
      <Videos videos="rights/videoBachelet" />
      <InfoBoxBlock
        id="rights_infoAdoptionils"
        col={{
          xs: 12,
          sm: 6,
          smOffset: 6
        }}
      />
      <CalloutStampBlock
        stamp="pinkcircle"
        calloutId="rights_calloutManyideas"
        subtextId="rights_calloutManyIdeasSub"
      />
      <Block18Collage />
      <Conclusion
        mainTextId="rights_calloutConclusion1"
        subTextId="rights_calloutConclusion2"
      />
    </main>
  </Template>
);

export default withIntl(Rights);
