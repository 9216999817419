import React from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import MediaQuery from 'react-responsive';

import ConventionModal from "modules/Conventions/ConventionModal";
import Select from "components/Select";
import CountryModal from "modules/Conventions/CountryModal";
import ConventionItem from "modules/Conventions/ConventionItem";
import Carousel from "modules/Conventions/Carousel";
import Modal from "components/Modal";
import modalCloseIconBlack from "assets/images/modal-close-black.svg";

import {
  Wrapper,
  Title,
  Main,
  SelectSection,
  SelectLabel,
  List,
  LastUpdated,
  Row,
  MainWrapper,
  HiddenItem,
} from './styled';


class Conventions extends React.PureComponent {
  static allowedLanguages = ['en', 'fr', 'es'];

  state = {
    selectedOption: null,
    currentConvention: null,
    isProtocol29: false,
    showModal: false,
  };

  handleClose = () => {
    this.setState({ selectedOption: null });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleConventionClick = (convention, isProtocol29 = false) => {
    const documentGlobal = typeof document !== 'undefined' && document;
    if (!documentGlobal) {
      return;
    }
    document.body.classList.add('overflow');
    this.setState({ currentConvention: convention, isProtocol29, showModal: true });
  };

  handleProtocolChange = (isProtocol29) => {
    this.setState({ isProtocol29 });
  };

  handleConventionClose = () => {
    const documentGlobal = typeof document !== 'undefined' && document;
    if (!documentGlobal) {
      return;
    }
    document.body.classList.remove('overflow');

    this.setState({ currentConvention: null, showModal: false });
  };

  sortByCountriesLength = (conventions) => (
    conventions.sort((a, b) => {
      if (a.countries.length > b.countries.length)
        return -1;
      if (a.countries.length < b.countries.length)
        return 1;
      return 0;
    })
  );

  sortByRatificationYear = (conventions, countryCode) => (
    conventions.sort((a, b) => {
      const { ratification: { year: aYear } } = a.countries.filter(country => country.country.isocode3 === countryCode)[0];
      const { ratification: { year: bYear } } = b.countries.filter(country => country.country.isocode3 === countryCode)[0];

      if (aYear < bYear)
        return -1;
      if (aYear > bYear)
        return 1;
      return 0;
    })
  );

  renderConventionItem = (item, max) => (
    <ConventionItem
      onClick={this.handleConventionClick}
      key={item.convention.code}
      data={item}
      protocolData={item.convention.code === 'C029' ? this.props.protocolData : {}}
      max={max}
    />
  );

  renderConventions = (conventions) => {
    const maxConventionCountriesLength = conventions[0].countries.length;

    return (
      <List id="convention-list">
        <MediaQuery query="(max-device-width: 751px)">
          <Carousel>
            {conventions.map((convention) => this.renderConventionItem(convention, maxConventionCountriesLength))}
          </Carousel>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 752px)">
          {conventions.map((convention) => this.renderConventionItem(convention, maxConventionCountriesLength))}
        </MediaQuery>
        <HiddenItem />
      </List>
    )
  };

  renderCountry = (conventions, selectedOption) => {
    const { data: allConventions } = this.props;
    const {value: countryCode} = selectedOption;
    const conventionsRatifiedByCountry = conventions.filter(convention => convention.countries.some(country => country.country.isocode3 === countryCode));
    const conventionsRatifiedByCountrySorted = this.sortByRatificationYear(conventionsRatifiedByCountry, countryCode);

    return (
      <CountryModal
        onClose={this.handleClose}
        allConventions={allConventions}
        conventions={conventionsRatifiedByCountrySorted}
        selectedCountry={selectedOption}
      />
    )
  };

  renderMain = () => {
    const { data: conventions, intl: { locale: language }, countries } = this.props;
    const sortedConventions = this.sortByCountriesLength(conventions);
    const {selectedOption} = this.state;

    const { updated } = sortedConventions[0];
    const options = countries[language];
    return (
      [
        <Title key="title"><FormattedMessage id="rights_visConventionsTitle" /></Title>,
        <Main key="main">
          <Row>
            <SelectSection>
              <SelectLabel><FormattedMessage id="rights_visConventionsSee" /></SelectLabel>
              <Select
                value={selectedOption}
                isClearable
                placeholder={<FormattedMessage id="rights_visConventionsSelect" />}
                onChange={this.handleChange}
                options={options}
                noOptionsMessage={() => <FormattedMessage id="interface_noOptions" />}
              />
            </SelectSection>

            <LastUpdated>
              <FormattedMessage id="rights_visConventionsUpdate" />
              <FormattedMessage
                id="latestUpdateConvention"
                defaultMessage="{day} {month} {year}"
                values={{
                  day: <FormattedDate value={updated} day="2-digit">{value => value}</FormattedDate>,
                  month: <FormattedDate value={updated} month="long">{value => value}</FormattedDate>,
                  year: <FormattedDate value={updated} year="numeric">{value => value}</FormattedDate>
                }}
              />
            </LastUpdated>
          </Row>

          <Row>
            {selectedOption
              ? this.renderCountry(sortedConventions, selectedOption)
              : this.renderConventions(sortedConventions)
            }
          </Row>
        </Main>
      ]
    )
  };

  renderConventionModal = () => {
    const { intl: { locale: language }, protocolData } = this.props;
    const { currentConvention, isProtocol29, showModal } = this.state;

    return (
      <Modal
        showModal={showModal}
        onRequestClose={this.handleConventionClose}
        closeIcon={modalCloseIconBlack}
      >
        <ConventionModal
          convention={isProtocol29 ? protocolData : currentConvention}
          allCountries={this.props.countries[language]}
          onProtocolChange={this.handleProtocolChange}
          activeButton={isProtocol29}
        />
      </Modal>
    )
  };

  render () {
    const { intl: { locale: language } } = this.props;
    const { showModal } = this.state;

    if (!Conventions.allowedLanguages.includes(language)) return null;

    return (
      <Wrapper>
        <MainWrapper>
          {this.renderMain()}
        </MainWrapper>
        {showModal && this.renderConventionModal()}
      </Wrapper>
    )
  }
}

Conventions.propTypes = {
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  protocolData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: intlShape.isRequired,
  countries: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(Conventions);
