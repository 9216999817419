import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Screen extends Component {
  static propTypes = {
    children: PropTypes.element
  };

  static defaultProps = {
    children: null
  };

  state = {
    stage: {
      width: 0,
      height: 0
    }
  };

  componentDidMount() {
    this.resizeWindow();
    window.addEventListener('resize', this.resizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  resizeWindow = () => {
    this.setState({
      stage: { width: window.innerWidth, height: window.innerHeight }
    });
  };

  render() {
    const { children } = this.props;
    return children
      ? React.Children.map(children, child =>
          React.cloneElement(child, {
            ...this.state
          })
        )
      : null;
  }
}

export default Screen;
