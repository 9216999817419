import styled from 'styled-components';
import { paragraphMixin } from 'styles/typography';

export const ArrowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  p {
    ${paragraphMixin};
    font-weight: 500;
    padding: 1rem 0;
    margin: 0;
  }
`;

export default ArrowWrapper;
