/* eslint-disable no-nested-ternary */

import React from 'react';
import styled from 'styled-components';
import media from 'styles/media';
import { captionMixin } from 'styles/typography';
import matchColor from 'utils/matchColor';
import Image from 'components/Image';
import { padding } from 'styles/variables';

export const Fig = styled.figure`
  width: ${props => props.width || '100%'};
  height: auto;
  display: table;
  margin: 2rem 0;
  padding: 0;
  direction: ${props =>
    props.captionAlign
      ? props.captionAlign
      : props.locale === 'ar'
        ? 'rtl'
        : 'ltr'};

  ${media.xs`
    position: relative;
    width: 100vw;
    margin: 2rem 0 2rem -16px;
  `};
`;

export const FigImage = styled(({ width, ...rest }) => <Image {...rest} />)`
  width: ${props => props.width};
  height: auto;
  min-height: 100%;
  transition: opacity 500ms ease;

  ${media.xs`
    width: 100%;
    position: relative;
  `};
`;

export const FigCaption = styled.figcaption`
  ${captionMixin};
  display: table-caption;
  caption-side: bottom;
  color: ${props => matchColor(props.captionColor)};
  margin: 1.375rem 0 0;
  width: 100%;

  ${props => media.queries('padding', props.captionPadding)};

  ${media.xs`
    padding: 0 ${padding.right.xs}rem 0 ${padding.left.xs}rem;
    box-sizing: border-box;
    font-size: 0.75rem;
    line-height: 1.125rem;
  `};
`;
