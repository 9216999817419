/* eslint-disable consistent-return */

export default function isiOS() {
  const windowGlobal = typeof window !== 'undefined' && window;
  const documentGlobal = typeof document !== 'undefined' && document;
  if (!windowGlobal || !documentGlobal) return;

  return (
    /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator/.test(
      navigator.userAgent
    ) && !window.MSStream
  );
}
