import styled from 'styled-components';
import media from 'styles/media';
import { sans, black, white } from 'styles/variables';
import arrow from 'assets/images/arrow.png';
import { margin, animation } from '../variables';
import { fadeIn } from '../effects';

export const LanguageMenu = styled.menu`
  flex: 1 0 auto;
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: space-evenly;
  margin: 0;
  width: 100%;
  height: inherit;
  padding: 0 ${margin.right.md * 2}rem 0 0;
  list-style: none;

  ${fadeIn};

  ${media.md`
    padding: 0 ${margin.right.md}rem 0 0;
  `};

  ${media.xs`
    opacity: 1;
    flex: 1 1 auto;
    justify-content: flex-end;
    background-color: ${black};
    padding: 0 ${margin.right.sm}rem 0 0;
  `};
`;

export const Option = styled.li`
  font-family: ${sans};
  font-size: 0.75rem;
  font-weight: ${props => (props.selected ? 500 : 400)};
  opacity: ${props => (props.selected ? 1 : 0.5)};
  text-transform: uppercase;
  flex: 0 33%;
  text-align: center;
  cursor: pointer;
  transition: opacity ${animation.medium}ms ease;

  &:hover {
    opacity: 1;
  }
`;

export const SelectorWrapper = styled.div`
  position: relative;
  width: 6.2rem;
  text-align: right;
  &:after {
    position: absolute;
    top: 25%;
    right: 0;
    content: '';
    background-image: url(${arrow});
    background-repeat: no-repeat;
    display: inline-block;
    width: 17px;
    height: 10px;
    pointer-events: none;
  }
`;

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
  background: 0 0;
  border: none;
  border-radius: 0;
  color: ${white};
  cursor: pointer;
  display: inline-block;
  font-family: ${sans};
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  overflow: visible;
  position: relative;
  text-align-last: right;
  width: 100%;
  right: 1.5rem;

  option {
    -webkit-appearance: none;
    font-family: ${sans};
    font-size: 1rem;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
  }
`;
