import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  display: inline-block;
  position: relative;
  margin: 5rem 0;
  width: 100%;

  ${media.xs`
    margin: 2rem 0;
  `}
`;

export default Wrapper;