import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape, FormattedMessage, FormattedDate} from "react-intl";
import {renderTrackVerticalDefault} from "react-custom-scrollbars/lib/Scrollbars/defaultRenderElements";
import MediaQuery from 'react-responsive';

import {ColorLabel, LastUpdated} from "modules/Conventions/ConventionModal/styled";
import SelectScrollBar from "components/ScrollBar";
import {mercury, silverChalice1} from "styles/variables";
import {labelColorsByCode, labelIdByCode} from "modules/Conventions/const";
import Caption from "modules/Conventions/Caption";
import CloudinaryImage from "components/Image";

import {
  Wrapper,
  Content,
  Image,
  Title,
  P,
  Filters,
  Filter,
  ListWrapper,
  Count,
  ListItem,
  ColorLabelToggle,
  ToggleWrapper,
  Left,
} from "./styled";

class ConventionModal extends React.PureComponent {
  state = {
    showRatified: true,
    activeTab: 0,
  };

  getCountriesNotRatified = (allCountries, countriesRatified) => {
    const countriesRatifiedCodes = countriesRatified.map(country => country.country.isocode3);

    return allCountries.filter(({ value }) => countriesRatifiedCodes.indexOf(value) === -1);
  };

  handleTabChange = (activeTab) => {
    this.setState({ showRatified: !activeTab, activeTab })
  };

  is29 = (isProtocol29, code) => isProtocol29 || code === 'C029';

  sortCountriesRatifiedByName = (countries, language) => (
    countries.sort((a, b) => {
      if (a.country[language] < b.country[language]) return -1;
      if (a.country[language] > b.country[language]) return 1;

      return 0;
    })
  );

  sortCountriesNotRatifiedByName = (countries, language) => (
    countries.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;

      return 0;
    })
  );

  renderTrackVertical = ({ style, ...props }) => renderTrackVerticalDefault({
    ...props,
    className: 'track-vertical',
    style: {
      ...style,
      backgroundColor: mercury,
    }
  });

  renderThumbVertical = ({ style, ...props }) => (
    <div
      {...props}
      style={{
        ...style,
        backgroundColor: silverChalice1,
        borderRadius: 20,
      }}
      className="thumb-vertical"
    />
  );

  renderRatifiedList = (countriesRatified, language) => (
    <ListWrapper>
      <Count>
        {countriesRatified.length}
        <div><FormattedMessage id="rights_visConventionsCountries" /></div>
      </Count>
      <SelectScrollBar
        renderThumbVertical={this.renderThumbVertical}
        renderTrackVertical={this.renderTrackVertical}
        height={350}
      >
        {countriesRatified.map(country => {
          const {country: { isocode3: code, [language]: name }} = country;
          const { ratification: { date } } = country;
          return (
            <ListItem key={code}>
              <span>{name}</span>
              <MediaQuery minDeviceWidth={752}>
                <FormattedMessage
                  id="ratificationDate"
                  tagName="div"
                  defaultMessage="{month} {year}"
                  values={{
                    month: <FormattedDate value={date} month="long">{value => value}</FormattedDate>,
                    year: <FormattedDate value={date} year="numeric">{value => value}</FormattedDate>
                  }}
                />
              </MediaQuery>
            </ListItem>
          )
        })}
      </SelectScrollBar>
    </ListWrapper>
  );

  renderNotRatifiedList = (countriesNotRatified) => (
    <ListWrapper>
      <Count>
        {countriesNotRatified.length}
        <div><FormattedMessage id="rights_visConventionsCountries" /></div>
      </Count>
      <SelectScrollBar
        renderThumbVertical={this.renderThumbVertical}
        renderTrackVertical={this.renderTrackVertical}
        height={350}
      >
        {countriesNotRatified.map(({ value, label }) => (
          <ListItem key={value}>
            <span>{label}</span>
          </ListItem>
         )
        )}
      </SelectScrollBar>
    </ListWrapper>
  );

  render() {
    const { showRatified, activeTab } = this.state;
    const {
      intl: { locale: language },
      convention: { convention: { [language]: title, code }, countries: countriesRatified, updated},
      allCountries,
      onProtocolChange,
      activeButton,
    } = this.props;

    const color = labelColorsByCode[code];
    const labelId = labelIdByCode[code];
    const isProtocol29 = code === 'P029';
    const codeNumber = parseInt(code.replace(`${isProtocol29 ? 'P' : 'C'}`, ''), 0);
    const imageId = `rights/ils-c${codeNumber}`;
    const descriptionId = `rights_ilsBlurbC${codeNumber}`;
    const countriesRatifiedSorted = this.sortCountriesRatifiedByName(countriesRatified, language);
    const countriesNotRatified = this.getCountriesNotRatified(allCountries, countriesRatifiedSorted);
    const countriesNotRatifiedSorted = this.sortCountriesNotRatifiedByName(countriesNotRatified, language);

    return (
      <Wrapper className="convention-modal">
        <Left>
          <Image imageSrc={CloudinaryImage.getImageUrlById(imageId)} />
          <Caption text={<FormattedMessage id={`rights_imageC${codeNumber}Caption`} />} />
        </Left>

        <Content>
          <LastUpdated>
            <span style={{ display: 'inline-block' }}><FormattedMessage id="rights_visConventionsUpdate"/></span>{' '}
            <FormattedMessage
              id="latestUpdateConvention"
              tagName="em"
              defaultMessage="{day} {month} {year}"
              values={{
                day: <FormattedDate value={updated} day="2-digit">{value => value}</FormattedDate>,
                month: <FormattedDate value={updated} month="long">{value => value}</FormattedDate>,
                year: <FormattedDate value={updated} year="numeric">{value => value}</FormattedDate>
              }}
            />
          </LastUpdated>

          {this.is29(isProtocol29, code)
            ? (
              <ToggleWrapper>
                <ColorLabelToggle
                  className={!activeButton && 'active'}
                  onClick={() => onProtocolChange(false)}
                  bgColor={color}
                >
                  <FormattedMessage id="rights_visConventionsC29" />
                </ColorLabelToggle>
                <ColorLabelToggle
                  className={activeButton && 'active'}
                  onClick={() => onProtocolChange(true)}
                  bgColor={color}
                >
                  <FormattedMessage id="rights_visConventionsP29" />
                </ColorLabelToggle>
              </ToggleWrapper>
            )
            : (
              <ColorLabel bgColor={color}><FormattedMessage id={labelId} /></ColorLabel>
            )
          }
          <Title>{title}</Title>
          <P><FormattedMessage id={descriptionId} /></P>

          <div>
            <Filters>
              <Filter
                bgColor={color}
                className={activeTab === 0 && 'active'}
                onClick={() => this.handleTabChange(0)}
              >
                <FormattedMessage id="rights_visConventionsRatified" />
              </Filter>
              <Filter
                bgColor={color}
                className={activeTab === 1 && 'active'}
                onClick={() => this.handleTabChange(1)}
              >
                <FormattedMessage id="rights_visConventionsNotRatified" />
              </Filter>
            </Filters>

            {showRatified
              ? this.renderRatifiedList(countriesRatifiedSorted, language)
              : this.renderNotRatifiedList(countriesNotRatifiedSorted, language)
            }
          </div>
        </Content>
      </Wrapper>
    )
  }
}

ConventionModal.propTypes = {
  convention: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  allCountries: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: intlShape.isRequired,
  // onClose: PropTypes.func.isRequired,
  onProtocolChange: PropTypes.func.isRequired,
};

export default injectIntl(ConventionModal);