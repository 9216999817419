import React from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Video from 'components/Video';
import { Wrapper, FullWidthThumb } from './styled';

const halfWidthThumb = video => (
  <Col key={video} className="video-col" xs={12} sm={6}>
    <Video videoID={video} />
  </Col>
);

const fullWidthThumb = video => (
  <Col xs={12} key={video}>
    <FullWidthThumb>
      <div className="video-wrapper">
        <Video fullwidth videoID={video} />
      </div>
    </FullWidthThumb>
  </Col>
);

const renderEven = videos => videos.map(halfWidthThumb);

const renderOdd = videos =>
  videos.map((video, i, arr) => {
    if (arr.length === 1) {
      return fullWidthThumb(video);
    }
    if ((i + 1) % 3 === 0) {
      return fullWidthThumb(video);
    }
    return halfWidthThumb(video);
  });

const Videos = ({ videos }) => {
  const vids = Array.isArray(videos) ? videos : [ videos ];
  const vidsIsEven = vids.length % 2 === 0;
  return (
    <Outer>
      <Inner>
        <Wrapper>
          <Row>{vidsIsEven ? renderEven(vids) : renderOdd(vids)}</Row>
        </Wrapper>
      </Inner>
    </Outer>
  );
};

Videos.propTypes = {
  videos: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

Videos.defaultProps = {
  videos: ['']
};

export default Videos;
