/**
 * @param Object of react-intl messages
 * @param String that you want to search for
 * @returns Array of keys with the values you wanted to search for
 */

const getContentBySubstring = (messages, substring) =>
  Object.keys(messages).filter(message => message.indexOf(substring) > -1);

export default getContentBySubstring;
