import styled from 'styled-components';
import { padding, black } from 'styles/variables';
import matchColor from 'utils/matchColor';
import { topBarHeight, animation } from '../variables';

/* -- Mobile -- */
export const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props => (props.open ? black : matchColor(props.color))};
  right: 0;
  width: 100vw;
  height: ${topBarHeight}rem;
  top: ${props => (props.shown ? 0 : `-${topBarHeight}rem`)};
  transition: top ease ${animation.medium}ms;
  padding: 1rem ${padding.right.xs}rem 1rem ${padding.left.xs}rem;
  animation: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`;

export default TopBar;
