/* eslint-disable class-methods-use-this */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ParallaxBanner } from 'react-scroll-parallax';
import withResponsive from 'containers/Responsive/withResponsive';

class ParallaxBannerWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    disabled: this.props.isMobile
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isMobile !== this.props.isMobile) {
      return this.toggleDisabled();
    }
    return null;
  }

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  };

  render() {
    const { disabled } = this.state;
    const { children, ...restProps } = this.props;
    return (
      <ParallaxBanner disabled={disabled} {...restProps}>
        {children}
      </ParallaxBanner>
    );
  }
}

export default withResponsive(ParallaxBannerWrapper);
