import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Logo from 'components/Logo';
import sound from 'assets/sounds/silence.mp3';
import {
  OuterWrapper,
  TitleWrapper,
  Title,
  ChapterButtonWrapper,
  Chapter,
  Button,
  ProgressBar,
  Label,
  MobileLabel,
  LogoWrapper
} from './styled';

const { func, number, string, bool } = PropTypes;

class Content extends PureComponent {
  static propTypes = {
    toggleActive: func.isRequired,
    progress: number.isRequired,
    chapter: string.isRequired,
    active: bool.isRequired
  };

  // Hack to make auto-play work on iOS and Safari
  componentDidMount() {
    this.button = document.getElementById('intro-button');
    this.audio = new Audio(sound);
    this.playAudio = e => {
      e.preventDefault();
      this.audio.play();
      this.removeListeners();
    };
    this.button.addEventListener('click', this.playAudio);
    this.button.addEventListener('touchstart', this.playAudio);
    window.addEventListener('keydown', this.playAudio);
  }

  componentWillUnmount() {
    this.button.removeEventListener('click', this.playAudio);
    this.button.removeEventListener('touchstart', this.playAudio);
    window.removeEventListener('keydown', this.playAudio);
  }

  removeListeners = () => {
    this.button.removeEventListener('click', this.playAudio);
    this.button.removeEventListener('touchstart', this.playAudio);
    window.removeEventListener('keydown', this.playAudio);
  };

  // End of hack

  render() {
    const { chapter, toggleActive, active, progress } = this.props;
    return (
      <OuterWrapper>
        <LogoWrapper>
          <Logo color="black" />
        </LogoWrapper>
        <TitleWrapper>
          <Title>
            <FormattedMessage id={`${chapter}_chapter`} />
          </Title>
        </TitleWrapper>
        <ChapterButtonWrapper>
          <Chapter>
            <FormattedMessage id={`${chapter}_heading`} />
          </Chapter>
          <Button
            id="intro-button"
            onTouchStart={toggleActive}
            onTouchEnd={toggleActive}
            onMouseUp={toggleActive}
            onMouseDown={toggleActive}
            active={active}
            progress={progress}
          >
            <ProgressBar
              progress={progress}
              style={{ transform: `translateX(${progress}%)` }}
            />
            <Label>
              <FormattedMessage id="interface_start" />
            </Label>
            <MobileLabel>
              <FormattedMessage id="interface_mobileStart" />
            </MobileLabel>
          </Button>
          <div className="spacer" />
        </ChapterButtonWrapper>
      </OuterWrapper>
    );
  }
}

export default Content;
