import styled from 'styled-components';
import media from 'styles/media';
import breakpoints from 'styles/breakpoints';
import { padding } from 'styles/variables';

const { lg } = breakpoints;

// Outer padding from left edge of screen and to the edge of the right menu
export const Outer = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 ${padding.right.lg}rem 0 ${padding.left.lg}rem;

  ${media.md`
    padding: 0 ${padding.right.md}rem 0 ${padding.left.md}rem;
  `}

  ${media.sm`
    padding: 0 ${padding.right.sm}rem 0 ${padding.left.sm}rem;
  `}

   ${media.xs`
    padding: 0 ${padding.right.xs}rem 0 ${padding.left.xs}rem;
   };
  `}
`;

// Max-width for content plus right padding from menu
export const Inner = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${lg}em;
  margin: 0 auto;
  padding: 0;

  ${media.md`
    padding: 0 ${padding.left.md}rem 0 0;
  `};

  ${media.sm`
    padding: 0 ${padding.left.sm}rem 0 0;
  `};

  ${media.xs`
    padding: 0;
  `};
`;
