import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
import Layout from 'containers/Layout';
import Background from 'containers/Background';
import Title from 'modules/Title';
import Head from 'components/Head';
import Menu from 'components/Menu';
import Chapter from 'components/Chapter';
import Sound from 'components/Sound';

class MobileTemplate extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({}).isRequired,
    children: PropTypes.node,
    unpublished: PropTypes.bool
  };

  static defaultProps = {
    children: null,
    unpublished: false
  };

  render() {
    const { theme, children, unpublished } = this.props;
    if (unpublished) {
      return (
        <Fragment>
          <ParallaxProvider>
            <Chapter theme={theme}>
              <Head
                title={`${theme.chapter}_heading`}
                description={`${theme.chapter}_title`}
                shareImg="intro/sm-story"
              />
              <Sound>
                <Fragment>
                  <Menu>
                    <Layout>
                      <Background theme="grey">
                        <Title unpublished={unpublished} />
                        {children}
                      </Background>
                    </Layout>
                  </Menu>
                </Fragment>
              </Sound>
            </Chapter>
          </ParallaxProvider>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <ParallaxProvider>
          <Chapter theme={theme}>
            <Head
              title={`${theme.chapter}_heading`}
              description={`${theme.chapter}_title`}
              shareImg="intro/sm-story"
            />
            <Sound>
              <Fragment>
                <Menu>
                  <Layout>
                    <Background theme="grey">
                      <Title unpublished={unpublished} />
                      {children}
                    </Background>
                  </Layout>
                </Menu>
              </Fragment>
            </Sound>
          </Chapter>
        </ParallaxProvider>
      </Fragment>
    );
  }
}

export default MobileTemplate;
