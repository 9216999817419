import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'i18n';
import { Row } from '../styled';
import {
  Nav,
  AboutWrapper,
  AboutLinks,
  About,
  InlineDivider,
  HR
} from './styled';
import Chapters from '../Chapters';
import { row } from '../variables';

const Navbar = ({ open, locale }) => (
  <Nav open={open} locale={locale}>
    <Chapters locale={locale} />
    <Row size={row.inter}>
      <AboutWrapper>
        <HR />
        <AboutLinks locale={locale}>
          <Link to="/about">
            <About>
              <FormattedMessage id="interface_about" />
            </About>
          </Link>{' '}
          <InlineDivider> | </InlineDivider>{' '}
          <Link to="/about#credits">
            <About>
              <FormattedMessage id="interface_credits" />
            </About>
          </Link>
        </AboutLinks>
      </AboutWrapper>
    </Row>
  </Nav>
);

const { bool, string } = PropTypes;
Navbar.propTypes = {
  open: bool,
  locale: string
};

Navbar.defaultProps = {
  open: false,
  locale: 'en'
};

export default Navbar;
