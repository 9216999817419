/* eslint-disable react/no-did-mount-set-state */

import { Component } from 'react';
import PropTypes from 'prop-types';
import { languages } from 'i18n/locales';

class Language extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired
  };

  static contextTypes = {
    language: PropTypes.object
  };

  state = {
    value: ''
  };

  componentDidMount() {
    const { language } = this.context;
    this.setState({
      value: language.locale || language.detected
    });
  }

  getLanguageName = () => {
    const { value } = this.state;
    const langArray = languages.filter(lang => lang.value === value);
    if (langArray.length > 0) {
      return langArray[0].text;
    }
    return '';
  };

  handleChange = value => {
    const { language } = this.context;
    const { originalPath } = language;
    const pathPrefix =
      process.env.GATSBY_PATH_PREFIX &&
      process.env.GATSBY_PATH_PREFIX.length > 1
        ? process.env.GATSBY_PATH_PREFIX
        : undefined;

    if (value === this.state.value) {
      return;
    }

    this.setState({ value }, () => {
      localStorage.setItem('language', value);
      window.location.href = pathPrefix
        ? `${pathPrefix}/${value}${originalPath}`
        : `/${value}${originalPath}`;
    });
  };

  render() {
    const { value } = this.state;
    const language = this.getLanguageName();
    return this.props.render({
      value,
      language,
      languages,
      setLanguage: this.handleChange
    });
  }
}

export default Language;
