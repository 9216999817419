/* eslint-disable react/no-did-mount-set-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SoundButton from 'components/Sound/Icon';
import shareIcon from 'assets/images/icon_share_black.svg';
import isiOS from 'utils/isiOS';
import { SoundContext } from 'components/Sound';
import Logo from '../../Logo';
import LanguageMenu from '../LanguageMenu';
import Footer from '../Footer';
import Language from '../../Language';
import ShareMenu from '../ShareMenu';
import Heading from '../Heading';
import Nav from '../Nav';
import Bullets from '../Bullets';
import {
  Sidebar,
  SoundButtonWrapper,
  Label,
  LogoWrapper,
  ShareLabel,
  ShareIcon
} from './styled';
import { Menu, Main, Row } from '../styled';
import { row } from '../variables';

class DesktopMenu extends Component {
  static contextType = SoundContext;

  state = {
    iOS: false
  };

  componentDidMount() {
    const checkios = isiOS();
    this.setState({
      iOS: checkios
    });
  }

  handleSoundButtonClick = e => {
    e.stopPropagation();
    this.context.actions.togglePlayStatus();
  };

  render() {
    const { playStatus } = this.context;
    const { iOS } = this.state;
    const {
      open,
      handleClick,
      locale,
      intl: { formatMessage, messages },
      hideSoundButton
    } = this.props;
    return (
      <Menu isiOS={iOS}>
        <Main open={open}>
          <Row border size={row.small} />
          <Row border size={row.medium}>
            <LanguageMenu open={open} />
          </Row>
          <Row border size={row.medium}>
            <ShareMenu open={open} locale={locale} />
          </Row>
          <Row size={row.medium}>
            <Heading open={open} locale={locale} />
          </Row>
          <Row flex size={row.large}>
            <Nav open={open} locale={locale} />
          </Row>
          <Row size={row.inter}>
            <LogoWrapper open={open} locale={locale}>
              <Logo color="black" />
            </LogoWrapper>
          </Row>
          <Row size={row.small}>
            <Footer />
          </Row>
        </Main>
        <Sidebar onClick={handleClick} onMouseEnter={open ? null : handleClick}>
          <Row border size={row.small}>
            <SoundButtonWrapper>
              <SoundButton
                hidden={hideSoundButton}
                onClick={this.handleSoundButtonClick}
                status={playStatus}
              />
            </SoundButtonWrapper>
          </Row>
          <Row border size={row.medium}>
            <Label locale={locale}>
              <Language render={({ language }) => language} />
            </Label>
          </Row>
          <Row border size={row.medium}>
            <ShareLabel
              locale={locale}
              aria-label={formatMessage({
                id: messages.interface_share,
                defaultMessage: messages.interface_share
              })}
            >
              <ShareIcon src={shareIcon} alt="" />
            </ShareLabel>
          </Row>
          <Row size={row.medium} />
          <Row flex size={row.large}>
            <Bullets />
          </Row>
          <Row size={row.inter} />
          <Row size={row.small} />
        </Sidebar>
      </Menu>
    );
  }
}

const { bool, func, string, shape } = PropTypes;
DesktopMenu.propTypes = {
  open: bool.isRequired,
  handleClick: func.isRequired,
  locale: string,
  intl: shape({
    formatMessage: func.isRequired,
    messages: shape({}).isRequired
  }).isRequired,
  hideSoundButton: bool
};

DesktopMenu.defaultProps = {
  locale: 'en',
  hideSoundButton: false
};

export default DesktopMenu;
