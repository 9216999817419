import styled, { css } from 'styled-components';

export const ChapterContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  backface-visibility: hidden;
  ${props => css`
    visibility: ${props.done ? 'visible' : 'hidden'};
    max-height: ${props.scrollBlocked ? '100vh' : '100%'};
    overflow: ${props.scrollBlocked ? 'hidden' : 'visible'};
  `};
`;
