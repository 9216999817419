import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import { FormattedMessage } from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';
import Player from 'components/Player';
import {
  Figure,
  VideoWrapper,
  VideoOverlay,
  Video,
  FigCaption,
  OverlayContent
} from './styled';

class MobileMovingImage extends Component {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    videoID: PropTypes.string.isRequired,
    intl: PropTypes.shape({
      locale: PropTypes.string
    }).isRequired,
    overlayMessage: PropTypes.string.isRequired
  };

  state = {
    playing: false
  };

  videoRef = React.createRef();

  togglePlayPause = () => {
    this.setState({ playing: !this.state.playing }, () => {
      if (this.state.playing) {
        return this.videoRef.current.play();
      }
      return this.videoRef.current.pause();
    });
  };

  handleClick = () => {
    this.togglePlayPause();
  };

  handleVisibility = isVisible => {
    if (!isVisible && this.state.playing) {
      this.togglePlayPause();
    }
  };

  render() {
    const { playing } = this.state;
    const { videoID, caption, overlayMessage } = this.props;
    return (
      <Fragment>
        {overlayMessage ? (
          <Outer>
            <Inner>
              <OverlayContent>
                <FormattedMessage id={overlayMessage} />
              </OverlayContent>
            </Inner>
          </Outer>
        ) : null}
        <VisibilitySensor onChange={this.handleVisibility}>
          <Figure>
            <VideoWrapper playing={playing} onClick={this.handleClick}>
              <Video
                loop
                muted
                ref={this.videoRef}
                poster={`${Player.getVideoUrlById(videoID)}.jpg`}
              >
                <source
                  src={Player.getVideoUrlById(videoID, 'auto', 80, 'auto', {
                    ac: 'none',
                    f: 'webm'
                  })}
                  type="video/webm"
                />
                <source
                  src={Player.getVideoUrlById(videoID, 'auto', 80, 'auto', {
                    ac: 'none',
                    f: 'mp4'
                  })}
                  type="video/mp4"
                />
                <p>
                  <FormattedMessage id="interface_html5VideoError" />
                </p>
              </Video>
              <VideoOverlay />
            </VideoWrapper>
            <FigCaption>{caption}</FigCaption>
          </Figure>
        </VisibilitySensor>
      </Fragment>
    );
  }
}

export default MobileMovingImage;
