import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import key from 'assets/images/small_key.svg';
import headphones from 'assets/images/headphones.svg';
import { P } from 'styles/typography';
import { Wrapper, Circle, Headphones, SVG } from './styled';

const Loader = ({ delay }) => (
    <Wrapper>
      <Circle>
        <img className='key' src={key} alt="" />
        <P>
          <FormattedMessage id="interface_loading" />
          ...
        </P>
        <SVG delay={delay} xmlns="http://www.w3.org/2000/svg">
          <g>
            <ellipse
              className="background"
              ry="160"
              rx="160"
              cy="160"
              cx="160"
              strokeWidth="2"
            />
            <ellipse
              fill="transparent"
              className="foreground"
              ry="160"
              rx="160"
              cy="160"
              cx="160"
              strokeWidth="4"
            />
          </g>
        </SVG>
      </Circle>
      <Headphones>
        <img src={headphones} alt="" />
        <P>
          <FormattedMessage id="interface_headphones" />
        </P>
      </Headphones>
    </Wrapper>
  );

Loader.propTypes = {
  delay: PropTypes.number
};

Loader.defaultProps = {
  delay: 5000
};

export default Loader;
