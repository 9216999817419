import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Outer, Inner } from 'containers/Wrapper';
import { Col, Row } from 'react-styled-flexboxgrid';
import Callout from 'components/Callout';
import { Wrapper } from './styled';

const CalloutStamp = ({
  stamp,
  calloutId,
  subtextId,
  className,
  innerStyle
}) => (
  <Outer>
    <Inner style={{ ...innerStyle }}>
      <Wrapper className={className}>
        <Row center="xs">
          <Col xs={12} sm={8}>
            <Callout size="small" stamp={stamp}>
              <FormattedMessage id={calloutId} />
            </Callout>
          </Col>
        </Row>
        {subtextId.length > 0 ? (
          <Row center="xs">
            <Col xs={12} sm={8}>
              <p>
                <FormattedMessage id={subtextId} />
              </p>
            </Col>
          </Row>
        ) : null}
      </Wrapper>
    </Inner>
  </Outer>
);

CalloutStamp.propTypes = {
  stamp: PropTypes.string,
  calloutId: PropTypes.string,
  subtextId: PropTypes.string,
  className: PropTypes.string,
  innerStyle: PropTypes.shape({})
};

CalloutStamp.defaultProps = {
  stamp: '',
  calloutId: '',
  subtextId: '',
  className: null,
  innerStyle: PropTypes.null
};

export default CalloutStamp;
