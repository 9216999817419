import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import NextChapter from 'components/NextChapter';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';

import { Wrapper, Paragraph, StyledCallout } from './styled';

const Conclusion = ({ mainTextId, subTextId }) => (
  <Outer>
    <Inner>
      <Wrapper>
        <Row center="xs" style={{ width: '100%' }}>
          <Col xs={12} sm={8}>
            <div style={{ display: 'inlineBlock' }}>
              <StyledCallout size="small" color="black">
                <FormattedMessage id={mainTextId} />
              </StyledCallout>
              <Paragraph>
                <FormattedMessage id={subTextId} />
              </Paragraph>
            </div>
          </Col>
        </Row>
        <NextChapter />
      </Wrapper>
    </Inner>
  </Outer>
);

Conclusion.propTypes = {
  mainTextId: PropTypes.string,
  subTextId: PropTypes.string
};

Conclusion.defaultProps = {
  mainTextId: '',
  subTextId: ''
};

export default Conclusion;
