import React from 'react';
import PropTypes from 'prop-types';

import { Background, StripedBackground, SolidBackground } from './styled';

const BG = ({ theme, children }) => {
  if (theme === 'white' || theme === 'blue' || theme === 'grey' || theme === 'offblue') {
    return <SolidBackground color={theme}>{children}</SolidBackground>;
  }
  if (theme === 'striped') {
    return <StripedBackground>{children}</StripedBackground>;
  }
  return <Background>{children}</Background>;
};

BG.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node.isRequired
};

BG.defaultProps = {
  theme: null
};

export default BG;
