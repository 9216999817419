import styled from 'styled-components';
import Callout from 'components/Callout';
import { Outer } from 'containers/Wrapper';
import media from 'styles/media';
import Tear from 'components/Tear';
import { captionMixin } from 'styles/typography';

export const OuterWrapper = styled.section`
  position: relative;
  min-height: ${props => props.outerHeight.lg};
  margin: 5rem 0;
  display: block;
  width: 100%;
  height: 100%;

  ${media.md`
    min-height: ${props => props.outerHeight.md};
  `};

  ${media.sm`
    min-height: ${props => props.outerHeight.sm};
  `}

  ${media.portrait`
    min-height: ${props => props.outerHeight.portrait};
  `}

  ${media.xs`
    min-height: ${props => props.outerHeight.xs};
    margin: 2rem  0;
    height: auto;
  `};
`;

export const BannerWrapper = styled(Outer)`
  padding-left: 0 !important;
  position: relative;
  height: 50rem;

  ${media.sm`
    height: 40rem;
  `};

  ${media.xs`
    height: 20rem;
    padding: 0;
    .parallax-banner-layer-0 {
      background-position: top 0 right 40% !important;
      top: 0 !important;
      bottom: 0 !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  `};

  figcaption {
    ${captionMixin};
    margin: 0.5rem 0 0 1rem;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
`;

export const CalloutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
`;

export const StyledCallout = styled(Callout)`
  position: relative;
`;

export const BottomTear = styled(Tear)`
  transform: scaleX(-1);
  bottom: -2px !important;
`;

export const InfoBoxWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  ${media.xs`
    position: relative;
    margin: 5rem 0;
  `};
`;
