import styled from 'styled-components';
import media from 'styles/media';
import { H2 } from 'styles/typography';
import { margin } from '../variables';
import { fadeIn } from '../effects';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  direction: ${props => (props.locale === 'ar' ? 'rtl' : 'ltr')};
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
  padding: ${props =>
    props.locale === 'ar'
      ? `0 ${margin.right.lg * 2}rem 0 ${margin.left.lg}rem`
      : `0 ${margin.right.lg}rem 0 ${margin.left.lg}rem`};

  ${fadeIn};

  ${media.md`
    padding: ${props =>
      props.locale === 'ar'
        ? `0 ${margin.right.md * 2}rem 0 ${margin.left.md}rem`
        : `0 ${margin.right.md} 0 ${margin.left.md}`};
  `};

  ${media.xs`
    animation: none;
    opacity: 1;
    padding: ${props =>
      props.locale === 'ar'
        ? `0 ${margin.right.sm + margin.inner.sm}rem 0 ${margin.left.sm}rem`
        : `0 ${margin.right.sm}rem 0 ${margin.left.sm +
            margin.inner.sm +
            0.5}rem`}};
  `};
`;

export const Heading = styled(H2)`
  font-weight: 400;
  font-size: 2.18rem;
  line-height: 2.25rem;
  margin: 0;

  ${media.md`
    font-size: 1.7rem;
  `} ${media.xs`
    font-size: 2.5rem;
    line-height: 2.8rem;
  `};
`;
