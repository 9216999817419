import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Parallax from 'containers/Parallax';
import Figure from 'components/Figure';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import InfoBox from 'components/InfoBox';
import { Wrapper, FigureWrapper } from './styled';

const Block = () => (
  <Outer>
    <Inner>
      <Wrapper>
        <Row>
          <Col xs={12} smOffset={5} sm={7} mdOffset={6} md={6}>
            <InfoBox scroll size={{lg: "35rem", md: "24rem"}}>
              <FormattedHTMLMessage id="rights_infoRoadtolsBody1" />
            </InfoBox>
          </Col>
        </Row>
        <Parallax offsetYMax={60} offsetYMin={-20}>
          <Row>
            <Col xs={12} sm={8} md={10}>
            <FigureWrapper>
              <Figure
                width="inherit"
                image="rights/road-standards"
                caption="rights_imageIlc1998Caption"
              />
              </FigureWrapper>
            </Col>
          </Row>
        </Parallax>
      </Wrapper>
    </Inner>
  </Outer>
);

export default Block;
