import Language from './Language';
import Share from './Share';
import Head from './Head';
import Header from './Header';
import Logo from './Logo';
import Footer from './Footer';
import Hamburger from './Hamburger';
import Image from './Image';
import Menu from './Menu';
import InfoBox from './InfoBox';
import Chapter from './Chapter';
import Figure from './Figure';
import Quote from './Quote';
import Sound from './Sound';

export {};
export {};
export {
  Language,
  Head,
  Header,
  Logo,
  Share,
  Footer,
  Hamburger,
  Image,
  Menu,
  InfoBox,
  Chapter,
  Figure,
  Quote,
  Sound
};
