import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { navigate } from 'gatsby';
import Arrow from 'components/Arrow';
import { chapters } from 'config/chapters';
import { ArrowWrapper } from './styled';

class NextChapter extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    chapter: PropTypes.string.isRequired
  };

  state = {
    nextChapter: ''
  };

  componentDidMount() {
    this.getNextChapter();
  }

  getNextChapter = () => {
    const { chapter } = this.props;
    const chapterIndex = chapters.filter(chap => chap.name === chapter)[0]
      .index;
    const nextChapterIndex = chapterIndex + 1;
    const nextChapter = chapters.filter(
      chap => chap.index === nextChapterIndex
    )[0];
    this.setState({
      nextChapter: nextChapter ? nextChapter.name : false
    });
  };

  goToNextChapter = () => {
    const { nextChapter } = this.state;
    const {
      intl: { locale }
    } = this.props;
    if (!nextChapter) {
      return navigate(`/${locale}/about`);
    }
    return navigate(`/${locale}/story/${nextChapter}`);
  };

  handleClick = () => {
    this.goToNextChapter();
  };

  render() {
    const { nextChapter } = this.state;
    return (
      <ArrowWrapper onClick={() => this.handleClick()}>
        <Arrow
          cta={nextChapter ? 'interface_nextChapter' : 'interface_learnMore'}
          color="black"
        />
      </ArrowWrapper>
    );
  }
}

export default NextChapter;
