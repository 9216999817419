import React from 'react';
import { Outer, Inner } from 'containers/Wrapper';
import Collage from 'components/Collage';
import { Wrapper, CollageWrapper } from './styled';

const collageLayers = [
  'rights/employer-1',
  'rights/employer-2',
  'rights/employer-3'
];

const Block = () => (
  <Outer>
    <Inner>
      <Wrapper>
        <CollageWrapper>
          <Collage
            imageStyle={{
              width: { lg: '80vw', md: '80vw', sm: '80vw', xs: '150vw' }
            }}
            layers={collageLayers}
            caption={{
              message: 'rights_imageEbelingCaption',
              colProps: {
                xs: 12,
                sm: 8,
                smOffset: 2,
                md: 5,
                mdOffset: 3,
                lg: 6
              }
            }}
          />
        </CollageWrapper>
      </Wrapper>
    </Inner>
  </Outer>
);

export default Block;
