import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Caption } from './styled';

const CollageCaption = ({ message, colProps, voffset }) => (
  <Caption voffset={voffset}>
    <Row>
      <Col {...colProps}>
        <figcaption>
          <FormattedMessage id={message} />
        </figcaption>
      </Col>
    </Row>
  </Caption>
);

Caption.propTypes = {
  message: PropTypes.string,
  cols: PropTypes.shape({})
};

Caption.defaultProps = {
  message: 'caption_missing',
  voffset: {
    lg: null,
    sm: null,
    md: null,
    xs: null
  },
  colProps: {
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12
  }
};

export default CollageCaption;
