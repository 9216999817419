import React, { Fragment, Component } from 'react';
import { compose } from 'redux';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { sanitize } from 'dompurify';
import withResponsive from 'containers/Responsive/withResponsive';
import Player from 'components/Player';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import getVideoUrlById from 'utils/video';
import Desktop from './Desktop';
import Mobile from './Mobile';

class Video extends Component {
  static propTypes = {
    videoID: PropTypes.string,
    isMobile: PropTypes.bool.isRequired,
    fullwidth: PropTypes.bool
  };

  static defaultProps = {
    videoID: '',
    fullwidth: false
  };

  state = {
    showModal: false,
    details: ''
  };

  async componentDidMount() {
    try {
      const {
        videoID,
        intl: { locale }
      } = this.props;
      const request = await axios.get(
        `${process.env.API_URL}/api/content/captions/${locale}/${videoID}Thumb`
      );
      this.setState({
        details: sanitize(request.data)
      });
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  handleShowModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  renderModal = () => {
    const { videoID } = this.props;
    return (
      <Modal
        showModal={this.state.showModal}
        onRequestClose={this.handleCloseModal}
      >
        <Player
          src={getVideoUrlById(`${videoID}Video`)}
          id={`${videoID}Video`}
          volume={70}
          controls
          autoPlay
        />
        )
      </Modal>
    );
  };

  render() {
    const { videoID, isMobile, fullwidth } = this.props;
    const { showModal, details } = this.state;
    return (
      <Fragment>
        {!isMobile ? (
          <Desktop
            fullwidth={fullwidth}
            videoID={videoID}
            details={details}
            handleShowModal={this.handleShowModal}
          />
        ) : (
          <Mobile
            videoID={videoID}
            details={details}
            handleShowModal={this.handleShowModal}
          />
        )}
        {showModal ? this.renderModal() : null}
      </Fragment>
    );
  }
}

export default compose(
  withResponsive,
  injectIntl
)(Video);
