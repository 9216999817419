import styled from 'styled-components';
import { black } from 'styles/variables';
import media from 'styles/media';
import { margin } from '../variables';
import { fadeIn } from '../effects';

export const ShareMenu = styled.menu`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  margin: 0;
  padding: ${props =>
    props.locale === 'ar'
      ? `0 ${margin.right.lg * 2}rem 0 ${margin.left.lg +
          margin.inner.lg +
          11}rem`
      : `0 ${margin.right.lg * 4.3}rem 0 ${margin.left.lg +
          margin.inner.lg +
          0.5}rem`};

  ${fadeIn};

  ${media.md`
    padding: ${props =>
      props.locale === 'ar'
        ? `0 ${margin.right.md * 2}rem 0 ${margin.left.md * 6}rem`
        : `0 ${margin.right.md * 5}rem 0 ${margin.left.md +
            margin.inner.md}rem`};
  `};

  ${media.xs`
    opacity: 1;
    align-items: center;
    background-color: ${black};
    padding: 0 0 0 ${margin.left.sm}rem;
  `};
`;

export default ShareMenu;
