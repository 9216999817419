import React from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import ParallaxBanner from 'containers/Parallax/ParallaxBanner';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import CloudinaryImage from 'components/Image';
import { FigCaption } from 'components/Figure/styled';
import InfoBox from 'components/InfoBox';

import {
  OuterWrapper,
  BannerWrapper,
  CalloutWrapper,
  InnerWrapper,
  StyledCallout,
  BottomTear,
  InfoBoxWrapper,
  Overlay
} from './styled';

const CalloutHeroBlock = ({
  imageId,
  calloutId,
  infoboxId,
  infoBoxProps,
  infoBoxColProps,
  outerHeight,
  bottomTear,
  figcaption,
  captionPadding
}) => (
  <OuterWrapper outerHeight={outerHeight}>
    <BannerWrapper>
      <ParallaxBanner
        layers={[
          {
            image: CloudinaryImage.getImageUrlById(imageId),
            amount: 0.1,
            slowerScrollRate: false
          }
        ]}
        style={{
          zIndex: 0,
          height: '100%',
          width: '100%'
        }}
      >
        <CalloutWrapper>
          <Overlay />
          <InnerWrapper>
            <Row center="xs" style={{ flex: 1 }}>
              <Col xs={10} sm={8}>
                <StyledCallout size="medium" color="white">
                  <FormattedMessage id={calloutId} />
                </StyledCallout>
              </Col>
            </Row>
          </InnerWrapper>
        </CalloutWrapper>
      </ParallaxBanner>
      {figcaption && (
        <FigCaption style={{display: 'inline-block'}} captionPadding={captionPadding}>
          <FormattedMessage id={figcaption} />
        </FigCaption>
      )}
      {bottomTear && <BottomTear />}
    </BannerWrapper>
    {infoboxId && (
      <InfoBoxWrapper>
        <Outer>
          <Inner>
            <Row>
              <Col {...infoBoxColProps}>
                <InfoBox {...infoBoxProps}>
                  <FormattedHTMLMessage id={infoboxId} />
                </InfoBox>
              </Col>
            </Row>
          </Inner>
        </Outer>
      </InfoBoxWrapper>
    )}
  </OuterWrapper>
);

CalloutHeroBlock.propTypes = {
  imageId: PropTypes.string,
  calloutId: PropTypes.string,
  infoboxId: PropTypes.string,
  outerHeight: PropTypes.shape({}),
  bottomTear: PropTypes.bool,
  infoBoxProps: PropTypes.shape({}),
  infoBoxColProps: PropTypes.shape({}),
  figcaption: PropTypes.string
};

CalloutHeroBlock.defaultProps = {
  imageId: '',
  calloutId: '',
  bottomTear: true,
  infoboxId: null,
  infoBoxProps: null,
  infoBoxColProps: {
    xs: 12,
    smOffset: 4,
    sm: 6,
    mdOffset: 5,
    md: 5
  },
  outerHeight: {
    lg: 'auto',
    md: 'auto',
    sm: 'auto',
    xs: 'auto',
    portrait: 'auto',
    landscape: 'auto'
  },
  figcaption: null
};

export default CalloutHeroBlock;
