import styled, { keyframes } from 'styled-components';
import Arrow from 'assets/svgs/arrow-scroll.svg';
import matchColor from 'utils/matchColor';

const float = keyframes`
  0% { transform: translateY(2px); }
  100% { transform: translateY(8px); }
`;

export const ScrollDown = styled.div`
  border: 1px solid ${props => matchColor(props.color)};
  border-radius: 50%;
  position: relative;
  transition: opacity ease 500ms;
  width: 50px;
  height: 50px;
  pointer-events: none;

  span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .arrow-scroll {
    stroke: ${props => matchColor(props.color)};
  }
`;

export const ScrollArrow = styled(Arrow)`
  width: 26px;
  height: 25px;
  animation: ${float} 500ms infinite alternate;
  transition: inherit;
  opacity: inherit;
`;
