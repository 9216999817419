import React from 'react';
import InfoCollage from 'modules/InfoCollage';

const collageLayers = [
  'rights/domestic-1',
  'rights/domestic-2',
  'rights/domestic-3'
];

const Block = () => (
  <InfoCollage
    collage={{
      layers: collageLayers,
      caption: {
        message: 'rights_imageDomesticCaption',
        colProps: {
          xs: 12,
          md: 5,
          mdOffset: 7
        },
        voffset: {
          lg: '3rem'
        }
      },
      colProps: {
        xs: 12
      }
    }}
    infoBoxes={[
      {
        infoBox: {
          scroll: true,
          size: {
            lg: '30rem',
            md: '25rem',
            sm: '22rem'
          }
        },
        message: 'rights_infoPathtodw',
        colProps: {
          xs: 12,
          smOffset: 4,
          sm: 8,
          mdOffset: 6,
          md: 6,
          lgOffset: 5,
          lg: 7
        }
      },
      {
        message: 'rights_infoLevelplayBody1',
        infoBox: {
          scroll: true,
          size: { lg: '32rem', md: '25rem' }
        },
        colProps: {
          xs: 12,
          sm: 7,
          md: 6
        },
        justify: 'flex-end'
      }
    ]}
    voffset={{
      md: '15rem 0',
      lg: '25rem 0',
      xs: '0'
    }}
  />
);

export default Block;
