import styled from 'styled-components';
import playButton from 'assets/images/play-button-traced.svg';
import { paragraphMixin, h2Mixin } from 'styles/typography';
import { white } from 'styles/variables';
import media from 'styles/media';

export const Details = styled.div`
  opacity: 0;
  transition: opacity 500ms ease;
  color: ${white};
  text-align: center;
  margin: 0 0 15%;

  ${media.sm`
    opacity: 1 !important;
    margin: 0 0 3rem;
  `};

  ${media.portrait`
    margin: 0 0 0.5rem;
  `}

  ${media.xs`
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
  `};

  h3 {
    ${h2Mixin};
    font-weight: 400;
    margin: 0 0 0.5rem;

    ${media.portrait`
      font-size: 1.6rem;
    `};

    ${media.xs`
      margin: 0;
      font-size: 1.6rem;
    `}
  }

  p {
    ${paragraphMixin};

    ${media.sm`
      margin: 0;
    `};

    ${media.xs`
      margin: 0.1rem 0 1rem;
    `}

    ${media.portrait`
      line-height: 1rem;
    `};
  }
`;

export const Overlay = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 500ms ease;

  ${media.sm`
    background-color: rgba(0, 0, 0, 0.5);
  `};

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);

    ${Details} {
      opacity: 1;
    }

    &:after {
      opacity: 1 !important;
    }
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    background: center no-repeat url(${playButton});
    transition: opacity 500ms ease;

    ${media.sm`
      opacity: 1 !important;
    `};
  }
`;

export const Wrapper = styled.figure`
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin: 0;
  direction: ltr;

  ${media.xs`
    min-height: 250px;
    background-image: ${props => `url(${props.thumbsrc})`};
    background-size: cover;
  `};

  video {
    width: ${props => (props.fullwidth ? '50%' : 'inherit')};
    height: inherit;
  }

  ${Details} {
    opacity: ${props => (props.fullwidth ? 1 : 0)};
  }

  ${Overlay} {
    width: ${props => (props.fullwidth ? '50%' : '100%')};
    left: ${props => (props.fullwidth ? 'auto' : 0)};
    right: ${props => (props.fullwidth ? 0 : 'auto')};

    &:after {
      opacity: ${props => (props.fullwidth ? 1 : 0)};
    }
  }
`;
