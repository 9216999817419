import React, { Component } from 'react';
import PropTypes from 'prop-types';
import white from 'assets/images/linkedin-white.svg';
import black from 'assets/images/linkedin.svg';
import { Link, Button } from './styled';

const icon = {
  white,
  black
};

const linkedinshare = 'https://www.linkedin.com/sharing/share-offsite/?url=';

class Linkedin extends Component {
  static propTypes = {
    color: PropTypes.string,
    size: PropTypes.string
  };

  static defaultProps = {
    color: 'black',
    size: 'small'
  };

  state = {
    isBrowser: typeof window !== 'undefined',
    location: 'https://www.ilo.org/100'
  };

  componentDidMount() {
    const { isBrowser } = this.state;
    if (isBrowser) {
      this.setState({
        location: window.location.href
      });
    }
  }

  render() {
    const { location } = this.state;
    const { size, color } = this.props;
    return (
      <Link href={`${linkedinshare}${location}`} target="_blank">
        <Button size={size} src={icon[color]} />
      </Link>
    );
  }
}

export default Linkedin;
