import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  position: relative;
  display: inline-block;
  min-height: 38rem;
  margin: 5rem 0;
  width: 100%;

  ${media.md`
    min-height: 35rem;
  `}

  ${media.sm`
    min-height: 35rem;
    margin: 5rem 0;
  `}

  ${media.portrait`
    margin: 0;
    min-height: 48rem;
  `}

  ${media.xs`
    margin: 2rem 0;
    height: auto;
    min-height: initial;
  `};
`;

export const CollageWrapper = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;

  ${media.portrait`
    top: 5rem;
    left: -5rem;
  `};

  ${media.xs`
    width: 100%;
    position: relative;
    display: inline-block;
    height: 400px;

  .parallax-mousemove-container {
    ${media.xs`
      left: -10% !important;
    `};
  `};
`;

