/* eslint-disable global-require, prefer-destructuring, react/destructuring-assignment, react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cover } from '../helpers';
import Mask from '../Mask';

/*
let Sprite;
let PIXI;
let texture; */

class Layer extends PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    mask: PropTypes.number.isRequired,
    size: PropTypes.number,
    offset: PropTypes.number,
    type: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    stage: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    })
  };

  static defaultProps = {
    offset: 1,
    size: 0,
    stage: {
      height: 0,
      width: 0
    }
  };

  state = {
    isBrowser: false,
    Sprite: null,
    texture: null
  };

  async componentDidMount() {
    const { Sprite } = await import('react-pixi-fiber');
    const PIXI = await import('pixi.js');

    const textures = {
      video: PIXI.Texture.fromVideo,
      image: PIXI.Texture.fromImage
    };

    const texture = textures[this.props.type](this.props.src);

    if (this.props.type === 'video') {
      texture.baseTexture.source.muted = true;
      texture.baseTexture.source.autoplay = true;
      texture.baseTexture.source.loop = true;
    }

    this.setState({ isBrowser: true, Sprite, texture });
  }

  render() {
    const { isBrowser, texture, Sprite } = this.state;
    const { index, mask, size, offset, stage } = this.props;

    if (isBrowser && Sprite) {
      const baseTexture = {
        width: texture.baseTexture.realWidth,
        height: texture.baseTexture.realHeight
      };

      return index === 0 ? (
        // Background cover image
        <Mask stage={stage} size={mask}>
          <Sprite
            x={stage.width / 2}
            y={stage.height / 2}
            anchor="0.5,0.5"
            texture={texture}
            scale={cover(baseTexture, stage)}
          />
        </Mask>
      ) : (
        // Concentric circles
        <Mask stage={stage} size={mask <= size ? mask : size}>
          <Sprite
            x={stage.width / 2}
            y={stage.height / 2}
            anchor="0.5,0.5"
            texture={texture}
            width={size * offset}
            height={size}
          />
        </Mask>
      );
    }
    return null;
  }
}

export default Layer;
