import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Footer, FooterItem } from './styled';

const MenuFooter = ({ intl: { locale } }) => {
  const copyright = `https://www.ilo.org/global/copyright/lang--${locale}/index.htm`;
  const disclaimer = `https://www.ilo.org/global/disclaimer/lang--${locale}/index.htm`;
  return (
    <Footer locale={locale}>
      <a href={copyright}>
        <FooterItem color="white">
          <FormattedMessage id="interface_privacyPolicy" />
        </FooterItem>
      </a>
      <a href={disclaimer}>
        <FooterItem color="white">
          <FormattedMessage id="interface_disclaimer" />
        </FooterItem>
      </a>
    </Footer>
  );
};

const { shape, string } = PropTypes;
MenuFooter.propTypes = {
  intl: shape({
    locale: string.isRequired
  }).isRequired
};

export default injectIntl(MenuFooter);
