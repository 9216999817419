import styled from 'styled-components';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import media from 'styles/media';

export const OuterWrapper = styled(Outer)`
  position: relative;
  display: inline-block;
  margin: 5rem 0;
  height: auto;

  ${media.sm`
    margin: 0;
  `};

  ${media.xs`
    margin: 1rem 0;
  `};
`;

export const InnerWrapper = styled(Inner)`
  position: relative;
`;

export const BottomLayer = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;

  ${({ voffset }) => media.queries('padding', voffset)};
`;

export const CollageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${media.xs`
    margin: 2rem 0;
  `};
`;

export const TopLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: ${props => props.justify};

  ${({ position }) => media.queries('position', position)};

  ${media.sm`
    margin: 5rem 0;
  `};
`;

TopLayer.defaultProps = {
  position: {
    lg: 'absolute',
    md: 'absolute',
    sm: 'absolute',
    xs: 'absolute'
  }
}

export const MobileRow = styled(Row)`
  display: flex;
  flex-flow: column;
`;
export const MobileCol = styled(Col)`
  order: ${props => props.order};
`;
