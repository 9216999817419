import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  renderTrackVerticalDefault,
  renderThumbVerticalDefault,
  renderViewDefault
} from 'react-custom-scrollbars/lib/Scrollbars/defaultRenderElements';

const SelectScrollBar = ({
  children,
  height,
  renderThumbVertical,
  renderTrackVertical,
  renderView
}) => (
  <Scrollbars
    style={{ width: '100%', height: '100%' }}
    universal
    autoHeight
    autoHide={false}
    autoHeightMax={height}
    renderThumbVertical={renderThumbVertical}
    renderTrackVertical={renderTrackVertical}
    renderView={renderView}
    thumbMinSize={40}
  >
    {children}
  </Scrollbars>
);

SelectScrollBar.defaultProps = {
  renderTrackVertical: props =>
    renderTrackVerticalDefault({ ...props, className: 'track-vertical' }),
  renderThumbVertical: props =>
    renderThumbVerticalDefault({ ...props, className: 'thumb-vertical' }),
  renderView: props =>
    renderViewDefault({ ...props, className: 'scrollbar-view' }),
  height: 200
};

SelectScrollBar.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  renderThumbVertical: PropTypes.func,
  renderTrackVertical: PropTypes.func,
  renderView: PropTypes.func
};

export default SelectScrollBar;
