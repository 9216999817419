import styled from 'styled-components';
import { silverChalice, black } from 'styles/variables';
import media from 'styles/media';
import { navMaxHeight } from '../variables';

export const BulletsWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  justify-content: flex-start;
  max-height: ${navMaxHeight}rem;
`;

export const Bullets = styled.ul`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  flex: 1 1 auto;
  font-size: 1rem;

  ${media.sm`
    pointer-events: none;
  `}
`;

export const BulletWrapper = styled.li`
  font-size: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
`;

export const Bullet = styled.div`
  &:before {
    color: ${props => (props.isSelected ? black : silverChalice)};
    content: '${props => (props.isSelected ? '\\25CF' : '\\25CB')}';
  }
`;

export const AboutBullets = styled(Bullets)`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  padding: 0;
  margin: 1px 0 0 0;

  &:before,
  &:after {
    content: '';
  }
`;

export const HR = styled.hr`
  border: none;
  border-top: 1px solid white;
  margin: 0.625rem 0 0 0;
`;

export const SquareBullet = styled.li`
  font-size: 0.866rem;
  padding: 0;
  margin: 1rem 0 0;
  line-height: 0.9375rem;
  &:before {
      display: block;
      color: ${props => (props.isSelected ? black : silverChalice)};
      content: '${props => (props.isSelected ? '\\25A0' : '\\25A2')}';
    }
`;
