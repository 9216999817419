import styled, { css, keyframes } from 'styled-components';
import media from 'styles/media';
import { H1, H3, paragraphMixin } from 'styles/typography';
import matchColor from 'utils/matchColor';
import { padding, white } from 'styles/variables';

const expand = keyframes`
  ${Array(102)
    .join(0)
    .split('')
    .map(
      (frames, i) => css`
        ${i}% {
          transform: scale(${i * 0.03});
        }
      `
    )}
`;

const shrink = keyframes`
  ${Array(102)
    .join(0)
    .split('')
    .map(
      (frames, i) => css`
        ${i}% {
          transform: scale(${1 / (0.02976 * i)});
        }
      `
    )}
`;

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const Expando = styled.div`
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: perspective;
  z-index: 1;

  .expando-outer {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    clip-path: circle(30%);

    ${props => css`
      animation: ${props.ready
        ? css`
            ${expand} 1000ms linear forwards;
          `
        : null};
    `};

    ${media.xs`
      clip-path: none;
      animation: none;
    `};
  }

  .expando-inner {
    will-change: transform;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    ${props => css`
      animation: ${props.ready
        ? css`
            ${shrink} 1000ms linear forwards;
          `
        : null};
    `};

    ${media.xs`
      animation: none;
    `};
  }
`;

export const Background = styled.div`
  height: ${props => (props.unpublished ? '100vh' : '105vh')};
  min-height: 50rem;
  background-color: ${props => matchColor(props.color)};
  min-width: 100vw;
  padding: ${padding.top.lg}rem 0 0;
  position: relative;

  ${media.md`
    min-height: 35rem;
  `};

  ${media.portrait`
    min-height: initial;
    height: 100vh;
  `};

  ${media.xs`
    height: ${props => (props.unpublished ? '100vh' : '92vh')};
    min-height: 40rem;
    padding: ${padding.top.xs}rem 0 0;
  `};
`;

export const LogoWrapper = styled.div`
  ${media.xs`
    display: none;
  `};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
  height: 50vh;
  margin: 5rem 0 0 0;
  background: ${props => props.background} 0 0 no-repeat;
  background-position: center center;
  background-size: contain;

  ${media.md`
    margin: 14vh 0 0 0;
  `};

  ${media.sm`
    margin: 10vh 0 0 0;
  `};

  ${media.xs`
    height: 45vh;
  `};
`;

export const Title = styled(H1)`
  position: relative;
  z-index: 1;
  margin: 0;
  font-size: 5.625rem;

  ${media.xs`
    text-align: left;
    font-size: ${props => (props.locale === 'ru' ? '2.2rem' : '3rem')};
    line-height: ${props => (props.locale === 'ru' ? '3rem' : '3.5rem')};
  `};
`;

export const ChapterNumber = styled.span`
  font-size: 1rem;
  display: inline;
  color: #fff;
  text-align: center;
  margin-right: 15px;
  letter-spacing: 3.5px;
  opacity: 0.5;
  text-transform: uppercase;

  ${media.md`
    margin: 0;
  `};
`;

export const ChapterName = styled(H3)`
  ${media.md`
    margin: 0.5rem 0 0;
  `};
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: ${props => (props.locale === 'ar' ? 'row-reverse' : 'row')};
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  ${ChapterNumber} {
    ${props =>
      props.locale === 'ar'
        ? css`
            margin-right: 0;
            margin-left: 15px;
            letter-spacing: 0;
          `
        : css`
            margin-right: 15px;
            margin-left: 0;
          `};

    ${media.md`
      margin: 0;
    `};
  }

  ${media.md`
    flex-flow: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 0 ${padding.inner.md}rem 0 0;
  `};

  ${media.xs`
    align-items: flex-start;
    margin: ${padding.inner.xs}rem 0 0 0;
  `};
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  left: 46%;
  bottom: 15vh;
  cursor: pointer;
  z-index: 3;

  ${media.sm`
    display: none;
  `};
`;

export const ComingSoon = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 8vh;

  p {
    ${paragraphMixin};
    color: ${white};
    font-size: 1.5rem;
    line-height: 2.1875rem;
    margin: 0;

    &:nth-child(2) {
      font-weight: 500;
    }

    ${media.md`
      font-size: 1rem;
      line-height: 1rem;
      margin: 0 0 0.5rem;
    `};
  }
`;
