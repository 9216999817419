import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Controls, { Control, ControlBox } from 'components/Controls';
import arrow from 'assets/images/timeline/arrow-in-circle.svg';
import {
  StepOuterWrapper,
  InfoBoxHead,
  InfoBoxContent,
  Line,
  Icon
} from './styled';

export default class StepBox extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string),
    intl: PropTypes.shape({}).isRequired,
    size: PropTypes.shape({}).isRequired
  };

  static defaultProps = {
    messages: ['']
  };

  state = {
    currentMessage: 0
  };

  handleNextMessage = () => {
    const { currentMessage } = this.state;
    const { messages } = this.props;
    if (currentMessage + 1 < messages.length) {
      this.setState({
        currentMessage: this.state.currentMessage + 1
      });
    }
  };

  handlePreviousMessage = () => {
    const { currentMessage } = this.state;
    if (currentMessage > 0) {
      this.setState({
        currentMessage: this.state.currentMessage - 1
      });
    }
  };

  renderStepNumber = () =>
    `${this.state.currentMessage + 1}/${this.props.messages.length}`;

  render() {
    const {
      messages,
      intl: { locale },
      size
    } = this.props;
    const { currentMessage } = this.state;
    return (
      <StepOuterWrapper
        size={size}
        locale={locale}
        style={{ boxShadow: 'none', paddingTop: '3rem' }}
      >
        <InfoBoxContent>
          <FormattedHTMLMessage id={messages[currentMessage]} />
        </InfoBoxContent>
        <InfoBoxHead>
          <div className="counter">{this.renderStepNumber()}</div>
          <Line />
          <Controls>
            <ControlBox>
              {currentMessage > 0 ? (
                <Control
                  right={locale === 'ar'}
                  onClick={this.handlePreviousMessage}
                >
                  <Icon src={arrow} alt="left arrow" />
                </Control>
              ) : (
                <div />
              )}
            </ControlBox>
            <ControlBox>
              {currentMessage + 1 < messages.length ? (
                <Control
                  right={locale !== 'ar'}
                  onClick={this.handleNextMessage}
                >
                  <Icon src={arrow} alt="right arrow" />
                </Control>
              ) : (
                <div />
              )}
            </ControlBox>
          </Controls>
        </InfoBoxHead>
      </StepOuterWrapper>
    );
  }
}
