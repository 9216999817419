import React from 'react';
import PropTypes from 'prop-types';
import Share from 'components/Share';
import { ShareMenu } from './styled';

const SharerMenu = ({ color, size, open, locale, post }) => (
  <ShareMenu open={open} locale={locale}>
    <Share color={color} size={size} post={post} />
  </ShareMenu>
);

const { string, bool } = PropTypes;
SharerMenu.propTypes = {
  color: string,
  size: string,
  open: bool,
  locale: string,
  post: string
};

SharerMenu.defaultProps = {
  color: 'black',
  size: 'small',
  open: false,
  locale: 'en',
  post: 'intro_post'
};

export default SharerMenu;
