import React from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import MovingImage from 'components/MovingImage';
import { Wrapper, InnerWrapper } from './styled';

const MovingImageBlock = ({ videoID, soundID }) => (
  <Wrapper>
    <Outer>
      <Inner>
        <Row center="xs">
          <Col xs={12} sm={9} md={8} lg={8}>
            <InnerWrapper>
              <MovingImage videoID={videoID} soundID={soundID} offset={15} />
            </InnerWrapper>
          </Col>
        </Row>
      </Inner>
    </Outer>
  </Wrapper>
);

MovingImageBlock.propTypes = {
  videoID: PropTypes.string.isRequired,
  soundID: PropTypes.string.isRequired
};

export default MovingImageBlock;
