/* eslint-disable class-methods-use-this */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
import withResponsive from 'containers/Responsive/withResponsive';

class ParallaxWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    offsetYMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offsetYMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    offsetYMax: 0,
    offsetYMin: 0
  };

  state = {
    disabled: this.props.isMobile
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isMobile !== this.props.isMobile) {
      return this.toggleDisabled();
    }
    return null;
  }

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  };

  render() {
    const { disabled } = this.state;
    const { children, offsetYMax, offsetYMin, ...restProps } = this.props;
    return (
      <Parallax
        styled={{ width: '100%' }}
        offsetYMax={offsetYMax}
        offsetYMin={offsetYMin}
        disabled={disabled}
        {...restProps}
      >
        {children}
      </Parallax>
    );
  }
}

export default withResponsive(ParallaxWrapper);
