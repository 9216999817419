import styled, { css } from 'styled-components';
import { white, black, titleSans, serif, yellow } from 'styles/variables';
import { h3Mixin, paragraphMixin } from 'styles/typography';
import zoom from 'assets/images/magnifying-glass.svg';
import media from 'styles/media';

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  margin: 5rem 0;
  position: relative;
  display: inline-block;

  ${media.xs`
    margin: 2rem 0;
  `};
`;

export const Item = styled.div`
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0.5rem;
  width: 100%;
  overflow: hidden;
  cursor: ${props => (props.withDetail ? 'pointer' : 'initial')};
  height: 100%;

  .item-inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: ${white};
    padding: 1.5rem;
    width: 100%;
    height: 100%;

    ${props =>
      props.withDetail
        ? css`
            &:after {
              content: '';
              background-image: url(${zoom});
              background-repeat: no-repeat;
              background-size: contain;
              height: 30px;
              width: 30px;
              right: ${props.locale === 'ar' ? 'initial' : '2rem'};
              left: ${props.locale === 'ar' ? '2rem' : 'initial'};
              z-index: 1;
              top: 2rem;
              background-color: #fff;
              position: absolute;

              ${media.sm`
                right: ${props.locale === 'ar' ? 'initial' : '1rem'};
                left: ${props.locale === 'ar' ? '1rem' : 'initial'};
                top: 1rem;
              `};
            }
          `
        : null};
  }

  .item-content {
    margin: 0 1rem;
  }

  .item-number {
    font-family: ${titleSans};
    font-size: 4rem;
    font-weight: 600;
    color: ${yellow};
  }

  .item-description {
    ${h3Mixin};
    color: ${black};
    font-family: ${serif};
    font-size: 1.7rem;
    text-align: initial;

    display: block;

    ${media.sm`
      font-size: 1rem;
    `};

    ${media.portrait`
      font-size: 1rem;
    `};
  }

  .item-subtext {
    ${paragraphMixin};
    display: block;
    font-size: 14px;
    margin: 0.5rem 0 0;

    ${media.sm`
      font-size: 11px;
      line-height: 14px;
    `};
  }
`;

export default Wrapper;
