import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Wrapper, Link } from './styled';

const Copyright = ({ intl: locale }) => (
    <Wrapper>
      <Link
        href={`https://www.ilo.org/global/copyright/lang--${
          locale.locale
        }/index.htm`}
      >
        © {new Date().getFullYear()} <FormattedMessage id="interface_ilo" />
      </Link>
      <Link
        href={`https://www.ilo.org/global/privacy-policy/lang--${
          locale.locale
        }/index.htm`}
      >
        <FormattedMessage id="interface_privacyPolicy" />
      </Link>
      <Link
        href={`https://www.ilo.org/global/fraud-alert/lang--${
          locale.locale
        }/index.htm`}
      >
        <FormattedMessage id="interface_fraudAlert" />
      </Link>
      <Link
        href={`https://www.ilo.org/global/disclaimer/lang--${
          locale.locale
        }/index.htm`}
      >
        <FormattedMessage id="interface_disclaimer" />
      </Link>
    </Wrapper>
  );

Copyright.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(Copyright);
