/* eslint-disable react/no-array-index-key, react/no-did-update-set-state */

import React, { PureComponent } from 'react';
import withResponsive from 'containers/Responsive/withResponsive';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ScrollBar from 'components/ScrollBar';
import StepBox from './StepBox';
import { OuterWrapper, ScrollWrapper } from './styled';

class Infobox extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    size: PropTypes.shape({}),
    scroll: PropTypes.bool,
    intl: PropTypes.shape({
      locale: PropTypes.string.isRequired
    }).isRequired,
    type: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    children: null,
    size: {
      lg: null,
      md: null,
      sm: null,
      xs: null
    },
    scroll: false,
    type: 'normal',
    messages: ['']
  };

  renderScrollBox = () => {
    const {
      size,
      className,
      infoBoxStyles,
      intl: { locale }
    } = this.props;
    return (
      <OuterWrapper
        size={size}
        locale={locale}
        style={infoBoxStyles}
        className={className}
      >
        <ScrollWrapper locale={locale}>
          <ScrollBar height="100%">
            <div className="infobox-text-wrapper" style={{ direction: 'ltr' }}>
              {this.props.children}
            </div>
          </ScrollBar>
        </ScrollWrapper>
      </OuterWrapper>
    );
  };

  renderInfoBox = () => {
    const {
      isMobile,
      type,
      scroll,
      children,
      size,
      intl: { locale }
    } = this.props;
    if (!isMobile && (scroll || type === 'scroll')) {
      return this.renderScrollBox();
    }
    if (type === 'step') {
      return <StepBox {...this.props} />;
    }
    return (
      <OuterWrapper size={size} locale={locale}>
        {children}
      </OuterWrapper>
    );
  };

  render() {
    return this.renderInfoBox();
  }
}

export default compose(
  withResponsive,
  injectIntl
)(Infobox);
