import React from 'react';
import PropTypes from 'prop-types';
import Player from 'components/Player';
import { Wrapper, Overlay, Details } from './styled';

const MobileVideo = ({ videoID, details, handleShowModal }) => (
  <Wrapper
    onClick={handleShowModal}
    thumbsrc={`${Player.getVideoUrlById(`${videoID}Thumb`)}.jpg`}
  >
    <Overlay />
    <Details dangerouslySetInnerHTML={{ __html: details }} />
  </Wrapper>
);

MobileVideo.propTypes = {
  videoID: PropTypes.string,
  details: PropTypes.string,
  handleShowModal: PropTypes.func
};

MobileVideo.defaultProps = {
  videoID: '',
  details: '',
  handleShowModal: () => {}
};

export default MobileVideo;
