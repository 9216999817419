import styled from 'styled-components';
import { P } from 'styles/typography';
import media from 'styles/media';
import Callout from 'components/Callout';

export const Wrapper = styled.section`
  min-height: 50rem;
  height: 100vh;
  padding: 2rem 0 4rem  ;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  position: relative;

  ${media.portrait`
    min-height: 60rem;
    max-height: 65rem;
  `};

  ${media.xs`
    height: auto;
    padding: 0;
    display: block;
    min-height: initial;
    max-height: initial;
    margin: 2rem 0 0;
  `};
`;

export const StyledCallout = styled(Callout)`
  ${media.xs`
    font-size: 1.875rem;
    line-height:  3.125rem;
    display: block;
    margin: 0 0 2rem;
    min-height: initial;
  `};
`;

export const Paragraph = styled(P)`
  text-align: center;
  margin: 0 0 5rem;
`;

export const ArrowWrapper = styled.div`
  height: 10rem;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
`;

export const Spacer = styled.div`
  height: 10rem;
  display: block;
`;

export default Wrapper;
