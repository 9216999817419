// Only xs, sm, md and lg are used as grid breakpoints
// These all use min-width


const breakpoints = {
  xs: 0, // <752px
  sm: 47, // 752px - 1024px
  md: 64.1, // 1024px - 1280px
  lg: 80.1 // >1280px,
};

export default breakpoints;
