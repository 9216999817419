import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImageLoader from 'components/Image/Loader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Fig, FigImage, FigCaption } from './styled';

class Figure extends PureComponent {
  static propTypes = {
    image: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    width: PropTypes.string,
    intl: PropTypes.shape({
      locale: PropTypes.string.isRequired
    }).isRequired,
    captionColor: PropTypes.string,
    captionPadding: PropTypes.shape({}),
    onLoad: PropTypes.func
  };

  static contextTypes = {
    parallaxController: PropTypes.object
  };

  static defaultProps = {
    width: '100%',
    captionColor: 'grime',
    captionPadding: {
      lg: '0',
      md: '0',
      sm: '0',
      xs: '0'
    },
    onLoad: () => {}
  };

  state = {
    loaded: false
  };

  handleLoad = () => {
    this.setState(
      {
        loaded: true
      },
      () => {
        if (this.context.parallaxController) {
          this.context.parallaxController.update();
        }
        this.props.onLoad();
      }
    );
  };

  render() {
    const {
      image,
      caption,
      width,
      captionColor,
      intl: { locale }
    } = this.props;
    const { loaded } = this.state;

    const captionPadding = Object.assign(
      {},
      Figure.defaultProps.captionPadding,
      this.props.captionPadding
    );

    return (
      <Fig locale={locale} width={width}>
        <ImageLoader
          Component={FigImage}
          width={width}
          id={image}
          onLoadCallback={this.handleLoad}
        />
        {loaded ? (
          <FigCaption
            captionColor={captionColor}
            captionPadding={captionPadding}
          >
            <FormattedMessage id={caption} />
          </FigCaption>
        ) : null}
      </Fig>
    );
  }
}

export default injectIntl(Figure);
