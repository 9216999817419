import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { black } from 'styles/variables';
import { ThemeContext } from 'components/Chapter';
import Header from '../../Header';
import Heading from '../Heading';
import Hamburger from '../../Hamburger';
import Nav from '../Nav';
import ShareMenu from '../ShareMenu';
import LanguageMenu from '../LanguageMenu';
import Footer from '../Footer';
import { TopBar } from './styled';
import { Menu, Main, Row, Col } from '../styled';
import { row } from '../variables';

class MobileMenu extends PureComponent {
  static propTypes = {
    topBarOpen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    handleClick: PropTypes.func,
    locale: PropTypes.string
  };

  static defaultProps = {
    handleClick: () => {},
    locale: 'en'
  };

  state = {
    scrollPosition: 0
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (!prevProps.open && open) {
      this.scrollTop();
    }
    if (prevProps.open && !open) {
      this.restoreScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollTop = () => {
    window.scroll(0, 0);
  };

  restoreScroll = () => {
    const { scrollPosition } = this.state;
    window.scroll(0, scrollPosition);
  };

  handleScroll = () => {
    const { open } = this.props;
    if (!open) {
      this.setState({
        scrollPosition: window.pageYOffset
      });
    }
  };

  render() {
    const { topBarOpen, open, handleClick, locale } = this.props;
    return (
      <Menu>
        <Main open={open}>
          <Row size={row.medium}>
            <Heading title="mobileTitle" locale={locale} />
          </Row>
          <Row flex size={row.large}>
            <Nav locale={locale} />
          </Row>
          <Row row size={row.inter} bgcolor={black}>
            <Col size="45%">
              <ShareMenu color="white" size="medium" />
            </Col>
            <Col size="55%">
              <LanguageMenu mobile />
            </Col>
          </Row>
          <Row size={row.small}>
            <Footer />
          </Row>
        </Main>
        <ThemeContext.Consumer>
          {({ color }) => (
            <TopBar color={color} shown={topBarOpen} open={open}>
              <Header color="white">
                <Hamburger
                  selected={open}
                  handleClick={handleClick}
                  colors={{ select: '#fff', unselect: '#fff' }}
                />
              </Header>
            </TopBar>
          )}
        </ThemeContext.Consumer>
      </Menu>
    );
  }
}

export default MobileMenu;
