import React from 'react';
import PropTypes from 'prop-types';
import Facebook from './Facebook';
import Twitter from './Twitter';
import Linkedin from './Linkedin';
import { Widget } from './styled';

const Share = ({ size, color, post }) => (
  <Widget>
    <Facebook color={color} size={size} />
    <Twitter color={color} size={size} post={post} />
    <Linkedin color={color} size={size} />
  </Widget>
);

const { string } = PropTypes;

Share.propTypes = {
  size: string,
  color: string,
  post: string
};

Share.defaultProps = {
  size: 'small',
  color: 'black',
  post: 'home_socialMedia'
};

export default Share;
