/* eslint-disable react/no-did-mount-set-state */

import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'i18n';
import logo from './logo';
import { Wrapper, Image } from './styled';

class Logo extends PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      locale: PropTypes.string.isRequired
    }).isRequired,
    color: PropTypes.string
  };

  static defaultProps = {
    color: 'white'
  };

  state = {
    isRenderedAtRoot: false
  };

  componentDidMount() {
    const {
      intl: { locale }
    } = this.props;
    if (window !== 'undefined' && window) {
      const location = window.location.pathname.replace(/\/$/, '');
      const root = process.env.GATSBY_PATH_PREFIX;
      const isRenderedAtRoot = location === `${root}/${locale}`;
      this.setState({ isRenderedAtRoot });
    }
  }

  render() {
    const {
      color,
      intl: { locale }
    } = this.props;
    const { isRenderedAtRoot } = this.state;
    return (
      <Wrapper>
        <Link
          external={isRenderedAtRoot}
          to={isRenderedAtRoot ? 'https://www.ilo.org' : '/'}
        >
          <Image src={logo[locale][color]} alt="" />
        </Link>
      </Wrapper>
    );
  }
}

export default injectIntl(Logo);
