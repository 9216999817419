import React from 'react';
import styled, { css } from 'styled-components';
import Image from 'components/Image';
import media from 'styles/media';
import { captionMixin } from 'styles/typography';

export const Wrapper = styled.figure`
  position: relative;
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin: 0;
  padding: 0;
  direction: ltr;

  ${media.xs`
    display: inline-block;
  `};
`;

export const Caption = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  direction: ltr;

  figcaption {
    ${captionMixin};
    position: relative;
    display: inline-block;
    width: 100%;

    ${({ voffset }) => media.queries('bottom', voffset)};

    ${media.xs`
      width: 100%;
      font-size: 0.75rem;
      line-height: 1.125rem;
    `};
  }
`;

Caption.defaultProps = {
  justify: 'center',
  size: 'auto'
};

export const StyledImage = styled(({ imageStyle, ...rest }) => (
  <Image {...rest} />
))`
  height: ${props =>
    props.imageStyle.height ? props.imageStyle.height.lg : 'auto'};
  width: ${props =>
    props.imageStyle.width ? props.imageStyle.width.lg : 'auto'};

  ${media.md`
    height: ${props =>
      props.imageStyle.height ? props.imageStyle.height.md : 'auto'};
    width: ${props =>
      props.imageStyle.width ? props.imageStyle.width.md : 'auto'};
  `};

  ${media.sm`
    height: ${props =>
      props.imageStyle.height ? props.imageStyle.height.sm : 'auto'};
    width: ${props =>
      props.imageStyle.width ? props.imageStyle.width.sm : 'auto'};
  `};

  ${media.xs`
    height: ${props =>
      props.imageStyle.height ? props.imageStyle.height.xs : 'auto'};
    width: ${props =>
      props.imageStyle.width ? props.imageStyle.width.xs : 'auto'};
  `};
`;

export default Wrapper;
