import React from 'react';
import PropTypes from 'prop-types';

import Wrapper, { Content } from './styled';

const Caption = ({ text }) => (
  <Wrapper>
    <Content>
      {text}
    </Content>
  </Wrapper>
);

Caption.propTypes = {
  text: PropTypes.node.isRequired,
};

export default Caption;
