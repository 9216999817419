import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import breakpoints from 'styles/breakpoints';

const withResponsive = WrappedComponent =>
  class extends Component {
    state = {
      isMobile: false,
      windowHeight: 0,
      windowWidth: 0
    };

    componentDidMount() {
      this.handleWindowSizeChange();
      window.addEventListener('resize', this.debounceHandleWindowSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.debounceHandleWindowSizeChange);
    }

    setWindowDimensions = () => {
      this.setState({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    };

    toggleMobile = () => {
      this.setState({ isMobile: !this.state.isMobile });
    };

    handleWindowSizeChange = () => {
      if (typeof window && window !== 'undefined') {
        const isMobile = window.innerWidth <= breakpoints.sm * 16;
        this.setWindowDimensions();
        if (isMobile && !this.state.isMobile) {
          return this.toggleMobile();
        }
        if (!isMobile && this.state.isMobile) {
          return this.toggleMobile();
        }
      }
      return null;
    };

    debounceHandleWindowSizeChange = debounce(this.handleWindowSizeChange, 500);

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };

export default withResponsive;
