import styled from 'styled-components';
import { FigCaption } from 'components/Figure/styled';
import playButton from 'assets/images/player/play.svg';
import { animation, black } from 'styles/variables';
import media from 'styles/media';
import { h2Mixin } from 'styles/typography';

export const Figure = styled.figure`
  display: table;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

export const VideoOverlay = styled.div`
  position: absolute;
  background-color: #000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  transition: opacity ease ${animation.medium}ms;
`;

export const OverlayContent = styled.div`
  ${h2Mixin};
  margin: 0;
  color: white;
  font-size: 2.5;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 25%;
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: -0.5px;
  line-height: 3.4375rem;

  ${media.sm`
    font-size: 1.8rem;
    padding: 0 15%;
    line-height: 2.8125rem;
  `};

  ${media.xs`
    position: relative;
    color: ${black};
    padding: 0;
    margin: 2.5rem 0;
  `};

  span {
    width: 100%;
  }
`;

export const OverlayCaption = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30% 2rem;

  ${media.sm`
    padding: 0 20% 1rem;
  `};

  figcaption {
    display: block;
    text-align: center;
    color: #fff;
  }
`;

export { FigCaption };

export const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 2000ms ease;

  ${media.xs`
    opacity: 1;
  `};
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${media.xs`
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: ${props =>
        props.playing ? null : `center no-repeat url(${playButton})`};
    }
  `};

  ${VideoOverlay} {
    opacity: ${props => (props.playing ? 0 : 0.7)};
  }
`;
