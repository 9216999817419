/* eslint-disable react/no-did-mount-set-state, global-require */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PixiStage extends Component {
  static propTypes = {
    stage: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    }).isRequired,
    children: PropTypes.node.isRequired
  };

  state = {
    Stage: null,
    isBrowser: false
  };

  async componentDidMount() {
    const ReactPixiFiber = await import('react-pixi-fiber');
    const { Stage } = ReactPixiFiber;
    this.setState({ isBrowser: true, Stage });
  }

  options = {
    transparent: true,
    antialias: true
  };

  render() {
    const { isBrowser, Stage } = this.state;
    const { stage, children } = this.props;
    if (isBrowser) {
      return (
        <Stage options={this.options} height={stage.height} width={stage.width}>
          {children}
        </Stage>
      );
    }
    return null;
  }
}

export default PixiStage;
