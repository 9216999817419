import { chapters } from 'config/chapters.json';

// Returns a string with the formatted date from the CMS
// E.g. intro_dateChap1
const getFormattedChapterDate = chapter => {
  const chapterIndex = chapters.findIndex(chap => chap.name === chapter);
  return `intro_dateChap${chapterIndex + 1}`;
};

export default getFormattedChapterDate;
