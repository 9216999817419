import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'i18n';
import { HeadingWrapper, Heading } from './styled';

const MenuHeading = ({ open, title, locale }) => (
  <HeadingWrapper open={open} locale={locale}>
    <Link to="/story">
      <Heading>
        <FormattedHTMLMessage id={`interface_${title}`} />
      </Heading>
    </Link>
  </HeadingWrapper>
);

MenuHeading.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  locale: PropTypes.string
};

MenuHeading.defaultProps = {
  open: false,
  title: 'title',
  locale: 'en'
};

export default MenuHeading;
