import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import Responsive from 'containers/Responsive';
import DesktopMovingImage from './Desktop';
import MobileMovingImage from './Mobile';

class MovingImage extends Component {
  static propTypes = {
    videoID: PropTypes.string,
    soundID: PropTypes.string,
    intl: PropTypes.shape({
      locale: PropTypes.string
    }).isRequired,
    overlay: PropTypes.bool,
    overlayMessage: PropTypes.string,
    overlayCaption: PropTypes.bool,
  };

  static defaultProps = {
    videoID: '',
    soundID: '',
    overlay: false,
    overlayCaption: false,
    overlayMessage: null,
  };

  state = {
    caption: ''
  };

  async componentDidMount() {
    try {
      const {
        videoID,
        intl: { locale }
      } = this.props;
      const request = await axios.get(
        `${process.env.API_URL}/api/content/captions/${locale}/${videoID}`
      );
      this.setState({
        caption: request.data
      });
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  render() {
    const { caption } = this.state;
    return (
      <Fragment>
        <Responsive only="desktop">
          <DesktopMovingImage
            caption={caption}
            {...this.props}
          />
        </Responsive>
        <Responsive only="mobile">
          <MobileMovingImage caption={caption} {...this.props} />
        </Responsive>
      </Fragment>
    );
  }
}

export default injectIntl(MovingImage);
