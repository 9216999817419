import styled from 'styled-components';

const transformControl = props => {
  if (props.right) {
    return `
      transform: rotate(180deg);
    `;
  }
  if (props.down) {
    return `
      transform: rotate(270deg);
    `;
  }
  if (props.up) {
    return `
      transform: rotate(90deg);
    `;
  }

  return '';
};

export default styled.div`
  display: flex;
  justify-content: space-between;
  width: 77px;
`;

export const Control = styled.div`
  cursor: pointer;
  text-align: center;
  ${props => transformControl(props)};
`;

export const ControlBox = styled.div`
  display: block;
  min-width: 31px;
`;
