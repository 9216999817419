/* eslint-disable react/destructuring-assignment */

import { Component } from 'react';
import PropTypes from 'prop-types';
import { openMask, closeMask } from '../helpers';

const { shape, number, func, bool } = PropTypes;

class Controller extends Component {

  static propTypes = {
    children: func,
    stage: shape({
      height: number,
      width: number
    }),
    progress: number.isRequired,
    setProgress: func.isRequired,
    done: bool.isRequired
  };

  static defaultProps = {
    children: () => {},
    stage: {
      height: 0,
      width: 0
    }
  };

  state = {
    active: false,
    mask: 0
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
  }

  setMask = (mask, cb) => this.setState({ mask }, cb || null);

  getProgress = (mask, stage) =>
    Math.floor(((mask * 7) / Math.hypot(stage.height, stage.width)) * 100);

  handleKeydown = e => {
    e.preventDefault();
    const { active } = this.state;
    if (!active && e.keyCode === 32) {
      this.toggleActive();
    }
  };

  handleKeyup = e => {
    const { active } = this.state;
    if (active && e.keyCode === 32) {
      this.toggleActive();
    }
  };

  toggleActive = () => {
    const { active } = this.state;
    this.setState({ active: !active }, () => {
      if (this.state.active) {
        window.requestAnimationFrame(this.windowOpen);
      } else {
        window.requestAnimationFrame(this.windowClose);
      }
    });
  };

  windowOpen = () => {
    const { active, mask } = this.state;
    const { stage, progress, setProgress } = this.props;
    if (active && progress < 105) {
      this.setMask(openMask(mask, 0.05), () => {
        setProgress(this.getProgress(mask, stage));
        window.requestAnimationFrame(this.windowOpen);
      });
    }
  };

  windowClose = () => {
    const { active, mask } = this.state;
    const { stage, setProgress, done } = this.props;
    if (!active && mask > 0 && !done) {
      this.setMask(closeMask(mask, 0.1), () => {
        setProgress(this.getProgress(mask, stage));
        window.requestAnimationFrame(this.windowClose);
      });
    }
  };

  render() {
    const { children, stage, progress } = this.props;
    return children({
      toggleActive: this.toggleActive,
      stage,
      progress,
      ...this.state
    });
  }
}

export default Controller;
