import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import modalCloseIcon from 'assets/images/modal-close.svg';
import { black } from 'styles/variables';
import { Icon, Button } from './styled';

const overlayStyle = {
  backgroundColor: black,
  zIndex: 100,
};

const contentStyle = {
  backgroundColor: black,
  border: 0,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

const Modal = ({
  showModal,
  onRequestClose,
  children,
  closeIcon,
}) => (
  <ReactModal
    isOpen={showModal}
    ariaHideApp={false}
    shouldCloseOnEsc
    onRequestClose={onRequestClose}
    style={{
      overlay: overlayStyle,
      content: contentStyle,
    }}
  >
    {children}
    <Button onClick={onRequestClose}>
      <Icon src={closeIcon} alt="modal close" />
    </Button>
  </ReactModal>
);

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  closeIcon: PropTypes.string,
};

Modal.defaultProps = {
  children: '',
  closeIcon: modalCloseIcon,
};

export default Modal;
