import React from 'react';
import { animateScroll } from 'react-scroll';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Outer, Inner } from 'containers/Wrapper';
import { Logo, Image as CloudinaryImage } from 'components';
import { ThemeContext } from 'components/Chapter';
import Tear from 'components/Tear';
import Arrow from 'components/Arrow';
import getFormattedChapterDate from 'utils/getFormattedChapterDate';
import {
  Wrapper,
  Expando,
  Background,
  LogoWrapper,
  Heading,
  Title,
  TitleWrapper,
  ChapterNumber,
  ChapterName,
  ArrowWrapper,
  ComingSoon
} from './styled';

class TitleView extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      locale: PropTypes.string.isRequired
    }).isRequired,
    unpublished: PropTypes.bool,
    ready: PropTypes.bool,
    handleAnimationEnd: PropTypes.func
  };

  static defaultProps = {
    unpublished: false,
    ready: true,
    handleAnimationEnd: () => {}
  };

  state = {
    innerHeight: 0
  };

  componentDidMount() {
    const { innerHeight } = window;
    this.setState({ innerHeight });
  }

  scrollTo = () => {
    animateScroll.scrollTo(this.state.innerHeight);
  };

  renderArrow = () => (
    <ArrowWrapper onClick={this.scrollTo}>
      <Arrow />
    </ArrowWrapper>
  );

  renderComingSoon = chapter => (
    <ComingSoon>
      <Outer>
        <Inner>
          <p>
            <FormattedMessage id="interface_discoverChapter" />
          </p>
          <p>
            <FormattedMessage id={getFormattedChapterDate(chapter)} />
          </p>
        </Inner>
      </Outer>
    </ComingSoon>
  );

  render() {
    const {
      unpublished,
      ready,
      handleAnimationEnd,
      intl: { locale }
    } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ chapter, color }) => (
          <Wrapper>
            <Expando ready={ready} onAnimationEnd={handleAnimationEnd}>
              <div className="expando-outer">
                <div className="expando-inner">
                  <Background unpublished={unpublished} color={color}>
                    <Outer>
                      <Row>
                        <Col xs={0} sm={6} lg={4}>
                          <LogoWrapper>
                            <Logo />
                          </LogoWrapper>
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                          <Heading locale={locale}>
                            <ChapterNumber>
                              <FormattedMessage id={`${chapter}_chapter`} />
                            </ChapterNumber>
                            <ChapterName>
                              <FormattedMessage id={`${chapter}_heading`} />
                            </ChapterName>
                          </Heading>
                        </Col>
                      </Row>
                      <Inner>
                        <Row center="xs">
                          <Col xs={12} sm={8} md={8} lg={10}>
                            <TitleWrapper
                              background={`url(${CloudinaryImage.getImageUrlById(
                                `components/${chapter}_stamp`
                              )})`}
                            >
                              <Title locale={locale}>
                                <FormattedMessage id={`${chapter}_title`} />
                              </Title>
                            </TitleWrapper>
                          </Col>
                        </Row>
                      </Inner>
                    </Outer>
                    {unpublished
                      ? this.renderComingSoon(chapter)
                      : this.renderArrow()}
                    {unpublished ? null : <Tear />}
                  </Background>
                </div>
              </div>
            </Expando>
          </Wrapper>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default injectIntl(TitleView);
