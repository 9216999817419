import React from 'react';
import PropTypes from 'prop-types';
import withResponsive from 'containers/Responsive/withResponsive';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Template = ({ isMobile, children, ...props }) =>
  isMobile ? (
    <Mobile {...props}>{children}</Mobile>
  ) : (
    <Desktop {...props}>{children}</Desktop>
  );

Template.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  children: PropTypes.node
};

Template.defaultProps = {
  children: null
};

export default withResponsive(Template);
