import styled from 'styled-components';
import media from 'styles/media';

export const Icon = styled.img`
  display: block;
  width: 100%;
  margin: 0;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  top: 55px;
  right: 50px;
  position: absolute;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 70px;
  z-index: 2;

  ${media.xs`
    top: 20px;
    right: 15px;
  `};
`;
