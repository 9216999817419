import styled from 'styled-components';

import {P as PBase} from "styles/typography";
import {sans, white, offblue, titleSans, karla} from "styles/variables";
import media from 'styles/media';

const calcWidth = (max, current, midSize) => {
  const baseWidth = midSize ? 33 : 25;
  const proportion = current * 100 / max;
  const width = baseWidth * (proportion / 100);

  return parseFloat(width.toFixed(2))
};

export const Wrapper = styled.div`
  display: inline-flex;
  width: ${props => `${calcWidth(props.max, props.current)}%`};
  padding-top: ${props => `${calcWidth(props.max, props.current)}%`};
  border-radius: 50%;
  background: #fff;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
 
  @media screen and (max-width: 1024px) {
    width: 220px;
    padding-top: 220px;
  }
 
  ${media.xs`
    width: 275px;
    padding-top: 275px;
    display: block;
    margin: 0 auto 30px auto;
  `};
  
  &:nth-child(4n) {
    margin-right: 0;
  }
`;

export const Circle = styled.div`
  position: absolute;
  top: -5px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  font-family: ${sans};
  color: ${offblue};
  text-align: center;
  padding: 17%;
  white-space: pre-wrap;
  
  @media screen and (min-width: 752px) and (max-width: 1280px) {
    font-size: 13px;
  }
`;

export const Head = styled.div`
  text-align: center;
  min-height: 50px;
  width: 100%;

  @media screen and (max-width: 1280px) {
    min-height: 90px;
  }

  @media screen and (max-width: 1024px) {
    min-height: 65px;
  }

  ${media.xs`
    min-height: 75px;
 `};
`;

export const Body = styled.div`
  text-align: center;
  margin-top: 1.5vw;

  @media screen and (max-width: 1280px) {
    margin-top: 0;
  }
  
  > * {
    margin: 0;
  }
`;

export const Notice = styled.div`
  position: absolute;
  bottom: -30px;
  left: 0%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: 752px) and (max-width: 1280px) {
    left: 10%;
    bottom: -35px;
  }

`;

export const NoticeCircle = styled(Circle)`
  position: relative;
  border: 1px solid ${offblue};
  box-shadow: -1px -1px 2px 0 rgba(0,0,0,0.5);
  width: 47px;
  height: 47px;
  font-size: 15px;
  font-family: ${titleSans};
  font-weight: 700;
  color: ${offblue};
  background-color: ${white};
  border-radius: 50%;
  margin-top: 5px;

  @media screen and (max-width: 1280px) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 1024px) {
    width: 33px;
    height: 33px;
  }

  ${media.xs`
    width: 47px;
    height: 47px;
 `};
`;

export const ColorLabel = styled.div`
  padding: 4px;
  border-radius: 10.5px;
  color: ${white};
  background-color: ${props => props.bgColor || white};
  max-width: max-content;
  min-width: max-content;
  margin: 0 auto;
  font-weight: 600;
  font-family: ${karla};
  display: inline-block;
  
  &.notice--label {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Count = styled(PBase)`
  font-weight: 700;
  font-size: 3.7rem;
  font-family: ${titleSans};
  letter-spacing: -3px;
  line-height: 3rem;
  margin-left: -5px;
  margin-bottom: 10px;

  @media screen and (max-width: 1280px) {
    font-size: 50px;
    letter-spacing: -2px;
  }
 
  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }
 
  ${media.xs`
    font-size: 60px;
    margin-bottom: 25px;
  `};
`;

export const Name = styled(PBase)`
  font-weight: 600;
  font-size: 14px;
  margin-top: 7px;
  line-height: 1.2;

  @media screen and (max-width: 1280px) {
    line-height: 1.2;
  }

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
`;

export const P = styled(PBase)`
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 10px;

  @media screen and (max-width: 1280px) {
    font-size: 14px;
    line-height: 16px;
    padding: 0 20px;
  }
 
  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
 
  ${media.xs`
    font-size: 15px;
    padding: 0;
    margin-bottom: 5px;
  `};
`;