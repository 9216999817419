/* Colors */
export const white = '#fff';
export const offwhite = '#F7F3EF';
export const grey = '#f7f3ef';
export const pavement = '#e0e0e0';
export const grime = '#4b4b4b';
export const gray = '#8d8d8d';
export const darkGrey = '#423e50';
export const jailcell = '#939191';
export const silverChalice = '#A9A9A9';
export const dustyGray = '#979797';
export const silverChalice1 = '#A3A3A3';
export const eternalGray = '#F0E8E0';
export const mercury = '#E5E5E5';
export const emperor = '#535353';
export const peat = '#4B4B4B';
export const alto = '#dedede';
export const black = '#000';
export const codGray = '#151515';
export const mineSahft = '#262626';
export const mineSahft1 = '#1f1f1f';
export const yellow = '#f6b300';
export const amber = '#F6BE00';
export const magenta = '#e40046';
export const redRibbon = '#E40045';
export const chetwodeBlue = '#8896D4';
export const blue = '#38488c';
export const cloudBurst = '#202A54';
export const indigo = '#5469C0';
export const bunting = '#18224D';
export const cobalt = '#263062';
export const soberBlue = '#202c45';
export const offblue = '#273260';
export const fuchsiaBlue = '#7C4DC8';
export const maroon = '#a21942';
export const turquoise = '#00a3ad';
export const paleTurquoise = '#8cb1b2';
export const green = '#73B98D';
export const teal = '#8EB7B7';
export const beige = '#b27a69';
export const rose = '#af7c75';
export const nightblue = '#1B2343';
export const skyblue = '#A2DBE8';

export const mobileImageHeight = '67vh';

/* Fonts */
export const titleSans =
  'Oswald, "Noto Kufi Arabic", "PingFang SC", "Microsoft YaHei", "微软雅黑", Arial, sans-serif';
export const sans =
  '"Open Sans", "Noto Kufi Arabic", "PingFang SC", "Microsoft YaHei", "微软雅黑", Helvetica, sans-serif';
export const serif =
  '"Crimson Text", "Noto Kufi Arabic", "PingFang SC", "Microsoft YaHei", "微软雅黑", "Times New Roman", serif';
export const karla =
  '"Karla", "Noto Kufi Arabic", "PingFang SC", "Microsoft YaHei", "微软雅黑", "Times New Roman", serif';

/* Measurements */

export const padding = {
  right: {
    lg: 4.6,
    md: 3.125,
    sm: 3.125,
    xs: 1
  },
  left: {
    lg: 3.125,
    md: 3.125,
    sm: 3.125,
    xs: 1
  },
  inner: {
    lg: 3.125,
    md: 3.125,
    sm: 1,
    xs: 1
  },
  top: {
    lg: 3.125,
    md: 3.125,
    sm: 1,
    xs: 4.5
  },
  bottom: {
    lg: 3.125,
    md: 3.125,
    sm: 1,
    xs: 1
  }
};

export const animation = {
  slow: 500,
  inter: 300,
  medium: 250,
  fast: 150
};

export const sound = {
  volume: {
    loud: 50,
    medium: 45,
    mediumSoft: 20,
    soft: 10
  }
};
