/* eslint-disable no-return-assign, func-names */
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import { languages } from 'i18n/locales';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import {
  ControlBar,
  BigPlayButton,
  PlayToggle,
  VolumeMenuButton,
  CurrentTimeDisplay,
  DurationDisplay,
  TimeDivider,
  ProgressControl
} from 'video-react';

import Wrapper from './styled';

const getLanguageName = locale =>
  capitalizeFirstLetter(
    languages.filter(lang => lang.value === locale)[0].text
  );

const cloudinaryname = process.env.GATSBY_CLOUDINARY_CLOUD_NAME;
const cloudinaryFolder =
  process.env.GATSBY_CLOUDINARY_FOLDER === '/'
    ? 'ilo-staging'
    : process.env.GATSBY_CLOUDINARY_FOLDER;

const allParams = (baseParams, additionalParams = {}) => {
  const keys = Object.keys(additionalParams);
  if (keys.length > 0) {
    return `${baseParams},`.concat(
      keys.map(key => `${key}_${additionalParams[key]}`).join()
    );
  }
  return baseParams;
};

const getBaseUrl = () =>
  `https://res.cloudinary.com/${cloudinaryname}/video/upload`;

class Player extends React.PureComponent {
  componentDidMount() {
    const {
      volume,
    } = this.props;

    this.player.volume = volume / 100;

    this.player.video.video.addEventListener(
      'loadedmetadata',
      this.handleVideoLoadedMetaData,
      true
    );
  }

  componentWillUnmount = () => {
    this.player.video.video.removeEventListener('loadedmetadata', this.handleVideoLoadedMetaData);
    window.removeEventListener('resize', this.handleWindowResize)
  };

  setCueSettings = () => {
    const trackItem = document.getElementById('video-track').track;
    const { cues } = trackItem;
    const keys = Object.keys(cues);

    if (window.innerWidth > 1200) {
      keys.forEach(key => {
        const cue = cues[key];
        cue.line = -6;
        cue.size = 70;
      });
    } else if (window.innerWidth > 620) {
      keys.forEach(key => {
        const cue = cues[key];
        cue.line = -4;
        cue.size = 80;
      });
    } else if (window.innerWidth > 480) {
      keys.forEach(key => {
        const cue = cues[key];
        cue.line = -4;
        cue.size = 90;
      });
    } else {
      keys.forEach(key => {
        const cue = cues[key];
        cue.line = -11;
        cue.size = 90;
      });
    }
  };

  static getVideoUrlById = (
    id,
    width = 'auto',
    quality = 70,
    videoCodec = 'auto',
    ...rest
  ) => {
    const baseParams = `w_${width},vc_${videoCodec},q_${quality}`;
    return `${getBaseUrl()}/${allParams(
      baseParams,
      ...rest
    )}/${cloudinaryFolder}/${id}`;
  };

  handleVideoLoadedMetaData = () => {
    const {
      intl: { locale },
      id
    } = this.props;
    const self = this;

    const track = document.createElement('track');
    track.id = 'video-track';
    track.mode = 'hidden';
    track.default = true;
    track.kind = 'captions';
    track.label = getLanguageName(locale);
    track.srclang = locale;
    track.src = withPrefix(`/captions/${locale}/${id}.vtt`);

    track.addEventListener('load', function() {
      self.player.video.video.textTracks[0].mode = 'hidden';
      self.setCueSettings();
      window.addEventListener('resize', self.handleWindowResize);

      this.mode = 'showing';
      self.player.video.video.textTracks[0].mode = 'showing';
    });
    self.player.video.video.appendChild(track);
  };

  handleWindowResize = () => {
    this.setCueSettings();
  };

  render() {
    const { src, ...restProps } = this.props;

    return (
      <Wrapper ref={el => (this.player = el)} {...restProps} playsInline>
        <source src={src} />
        <BigPlayButton position="center" />
        <ControlBar autoHide={false}>
          <PlayToggle />
          <CurrentTimeDisplay order={3} />
          <TimeDivider order={3.2} />
          <DurationDisplay order={3.3} />
          <ProgressControl order={5} />
          <VolumeMenuButton vertical order={6} />
        </ControlBar>
      </Wrapper>
    );
  }
}

Player.defaultProps = {
  volume: 100
};

Player.propTypes = {
  src: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  volume: (props, propName) => {
    if (props[propName] < 0 || props[propName] > 100) {
      return new Error('Volume range is from 0 to 100');
    }

    return null;
  }
};

export default injectIntl(Player);
