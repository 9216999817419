import React from 'react';
import { chapters } from 'config/chapters.json';
import { Link } from 'i18n';
import { Row } from '../styled';
import {
  BulletsWrapper,
  Bullets,
  BulletWrapper,
  Bullet,
  AboutBullets,
  SquareBullet,
  HR
} from './styled';
import { isSelected } from '../utils';
import { row } from '../variables';

const ChapterBullets = () => (
  <BulletsWrapper>
    <Bullets>
      {chapters.map(chapter => (
        <BulletWrapper key={chapter.name}>
          <Link to={`/story/${chapter.name}`}>
            <Bullet key={chapter.name} isSelected={isSelected(chapter.name)} />
          </Link>
        </BulletWrapper>
      ))}
    </Bullets>
    <Row size={row.inter}>
      <AboutBullets>
        <HR />
        <Link to="/about">
          <SquareBullet />
        </Link>
      </AboutBullets>
    </Row>
  </BulletsWrapper>
);

export default ChapterBullets;
