import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 10;
  width: 2.8rem;

  ${media.sm`
    top: 15px;
    right: 15px;
  `};

  ${media.xs`
      top: 8px;
  `};
`;

export const SVGWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
`;

export const SVG = styled.svg`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
`;

export const G = styled.g`
  pointer-events: all;
  cursor: pointer;
  transform: color 800ms ease;
`;

export const Bar = styled.path`
  transition: transform 250ms ease, stroke-dasharray 250ms ease;
  stroke-dasharray: 50;
  stroke-dashoffset: 0;
  opacity: 1;

  &:nth-of-type(1) {
    transform-origin: 15px 15px;
    transform: ${props => (props.selected ? 'rotate(-45deg) ' : 'none')};
  }

  &:nth-of-type(2) {
    transform-origin: 29px 28px;
    transform: ${props => (props.selected ? 'rotate(45deg) ' : 'none')};
    stroke-dasharray: ${props => (props.selected ? 50 : 20)};
  }
`;

export const Rect = styled.rect`
  stroke: none;
`;
