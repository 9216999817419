import React from 'react';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import { FormattedHTMLMessage } from 'react-intl';
import InfoBox from 'components/InfoBox';
import Collage from 'components/Collage';
import { Wrapper, CollageWrapper } from './styled';

const collageLayers = [
  'rights/declaration-1',
  'rights/declaration-2',
  'rights/declaration-3'
];

const Block = () => (
  <Wrapper>
    <Outer>
      <Inner>
        <Row>
          <Col
            xs={12}
            smOffset={-2}
            sm={5}
            mdOffset={-1}
            md={5}
            lgOffset={-1}
            lg={6}
          >
            <CollageWrapper>
              <Collage
                imageStyle={{
                  width: { lg: '750px', md: '600px', sm: '600px', xs: '100vw' }
                }}
                layers={collageLayers}
                caption={{
                  message: 'rights_imageAdoptfprwCaption',
                  colProps: {
                    xs: 12,
                    sm: 10,
                    smOffset: 5,
                    md: 8,
                    mdOffset: 2
                  },
                  voffset: {
                    lg: '2rem',
                    md: '2rem',
                    sm: '2rem',
                    xs: '0'
                  }
                }}
              />
            </CollageWrapper>
          </Col>
          <Col
            xs={12}
            smOffset={6}
            sm={6}
            mdOffset={2}
            md={5}
            lgOffset={1}
            lg={6}
          >
            <InfoBox>
              <FormattedHTMLMessage id="rights_infoBasisfprw" />
            </InfoBox>
          </Col>
        </Row>
      </Inner>
    </Outer>
  </Wrapper>
);

export default Block;
