import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import Copyright from './Copyright';
import { Foot } from './styled';

const Footer = () => (
  <Foot>
    <Row>
      <Col xs={12}>
        <Copyright />
      </Col>
    </Row>
  </Foot>
);

export default Footer;
