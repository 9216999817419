/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import breakpoints from 'styles/breakpoints';

const Responsive = ({ only, children }) =>
  only === 'desktop' ? (
    <MediaQuery query={`(min-width: ${breakpoints.sm * 16}px)`}>
      {children}
    </MediaQuery>
  ) : only === 'mobile' ? (
    <MediaQuery query={`(max-width: ${breakpoints.sm * 16}px)`}>
      {children}{' '}
    </MediaQuery>
  ) : (
    children
  );

Responsive.propTypes = {
  children: PropTypes.node.isRequired,
  only: PropTypes.string
};

Responsive.defaultProps = {
  only: null
};

export default Responsive;
