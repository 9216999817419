import { padding } from 'styles/variables';

export const row = {
  small: {
    lg: 2.75,
    md: 2.75,
    sm: 2.75,
    xs: 2.75
  },
  medium: {
    lg: 6,
    md: 5.5,
    sm: 5.5,
    xs: 10
  },
  inter: {
    lg: 5,
    md: 5,
    sm: 5,
    xs: 4
  },
  large: {
    lg: 30,
    md: 28,
    sm: 28,
    xs: 28
  }
};

export const margin = {
  right: {
    lg: 4.6,
    md: 3.2,
    sm: 1
  },
  inner: {
    lg: 1,
    md: 1,
    sm: 2
  },
  left: {
    lg: 2,
    md: 2,
    sm: 1
  }
};

export const topBarHeight = padding.top.xs;

export const navWidth = {
  lg: {
    width: '34.625rem'
  },
  md: {
    width: '29.0625rem'
  }
};

export const navMaxHeight = 40;

export const animation = {
  slow: 500,
  medium: 250,
  fast: 150
};
