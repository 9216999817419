import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'i18n';
import { chapters } from 'config/chapters.json';
import { Chapter, ChapterNumber, ChapterName, ChapterWrapper } from './styled';
import { isSelected } from '../utils';

const Chapters = ({ locale }) =>
  chapters.map((chapter, index) => {
    const chapterIsSelected = isSelected(chapter.name);
    return (
      <ChapterWrapper isSelected={chapterIsSelected} key={chapter.index} locale={locale}>
        <Link to={`/story/${chapter.name}`}>
          <Chapter index={index} isSelected={chapterIsSelected} locale={locale}>
            <ChapterNumber>
              <FormattedMessage id={`${chapter.name}_chapter`} />{' '}
            </ChapterNumber>
            <ChapterName>
              <FormattedMessage id={`${chapter.name}_heading`} />
            </ChapterName>
          </Chapter>
        </Link>
      </ChapterWrapper>
    );
  });

Chapters.propTypes = {
  locale: PropTypes.string
};

Chapters.defaultProps = {
  locale: 'en'
};

export default Chapters;
