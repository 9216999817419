import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, SVGWrapper, SVG, G, Bar, Rect } from './styled';

const Hamburger = ({ selected, handleClick, colors: { select, unselect } }) => (
  <Wrapper>
    <SVGWrapper>
      <SVG
        version="1.1"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        onClick={handleClick}
      >
        <title>Menu</title>
        <G
          stroke={selected ? select : unselect}
          strokeWidth="1.5"
          fill="none"
          stroke-linecap="square"
          selected={selected}
          colors={{ select, unselect }}
        >
          <Bar selected={selected} d="M30 20 H 0" />
          <Bar selected={selected} d="M30 29 H 0" />
          <Rect width="30" height="30" fill="none" border="none" />
        </G>
      </SVG>
    </SVGWrapper>
  </Wrapper>
);

const { string, bool, shape, func } = PropTypes;
Hamburger.propTypes = {
  selected: bool,
  handleClick: func,
  colors: shape({
    select: string,
    unselect: string
  })
};

Hamburger.defaultProps = {
  selected: false,
  handleClick: () => {},
  colors: {
    select: '#fff',
    unselect: '#000'
  }
};

// <rect width="100" height="100" fill="none" />

export default Hamburger;
