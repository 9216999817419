import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import CloudinaryImage from 'components/Image';
import ClouindaryVideo from 'components/Player';

import Content from '../Content';
import Controller from '../Controller';
import Screen from '../Screen';
import Stage from '../Stage';
import Layer from '../Layer';
import { Wrapper, Main } from './styled';

class Teaser extends Component {
  static propTypes = {
    progress: PropTypes.number.isRequired,
    setProgress: PropTypes.func.isRequired,
    done: PropTypes.bool.isRequired,
    chapter: PropTypes.string.isRequired
  };

  getImageSrc = id => {
    const { chapter } = this.props;
    return CloudinaryImage.getImageUrlById(`${chapter}/${chapter}-${id}`);
  };

  getVideoSrc = id => {
    const { chapter } = this.props;
    return ClouindaryVideo.getVideoUrlById(`${chapter}/${chapter}-${id}.mp4`);
  };

  initialMask = 210;

  render() {
    const { progress, setProgress, done, chapter } = this.props;
    return (
      <Wrapper>
        <Main>
          <Screen>
            <Controller
              progress={progress}
              done={done}
              setProgress={setProgress}
            >
              {({ stage, mask, active, toggleActive }) => (
                <Fragment>
                  <Stage stage={stage}>
                    <Layer
                      index={0}
                      type="image"
                      stage={stage}
                      mask={mask * 7}
                      src={this.getImageSrc('teaser-bg')}
                    />
                    <Layer
                      index={1}
                      type="image"
                      stage={stage}
                      src={this.getImageSrc('teaser-outercircle')}
                      mask={this.initialMask + mask * 3}
                      size={650}
                    />
                    <Layer
                      index={2}
                      type="image"
                      stage={stage}
                      mask={this.initialMask + mask * 1.8}
                      src={this.getImageSrc('teaser-innercircle')}
                      size={440}
                    />
                    <Layer
                      index={3}
                      type="video"
                      stage={stage}
                      mask={this.initialMask + 7}
                      src={`${this.getVideoSrc('teaser-video')}`}
                      size={this.initialMask + 7}
                      offset={1.5}
                    />
                  </Stage>
                  <Content
                    active={active}
                    chapter={chapter}
                    progress={progress} // eslint-disable-line react/prop-types
                    toggleActive={toggleActive} // eslint-disable-line react/prop-types
                  />
                </Fragment>
              )}
            </Controller>
          </Screen>
        </Main>
      </Wrapper>
    );
  }
}

export default Teaser;
