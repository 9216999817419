import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import breakpoints from 'styles/breakpoints';

const grid = {
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 0, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen'
  }
};

grid.flexboxgrid.breakpoints = breakpoints;

const Layout = ({ children }) => <ThemeProvider theme={grid}>{children}</ThemeProvider>;

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
