import styled from 'styled-components';
import media from 'styles/media';
import { P } from 'styles/typography';

export const Wrapper = styled(P)`
  font-size: 0.75rem !important;
  line-height: 0.75rem !important;
  font-weight: normal;
  color: #fff;
  text-align: center;
  padding: 0 0 1rem;
  margin: 0;
`;

export const Link = styled.a`
  text-decoration: none;
  color: white;

  &:after {
    content: ' | ';
  }

  &:last-child:after {
    content: '';
  }

  ${media.xs`
    display: block;
    padding: 0;
    margin: 7px 0;

    &:after {
      content: '';
    }

  `};
`;
