import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  margin: 5rem 0;

  ${media.xs`
    margin: 2rem 0;
  `}
`;

export const InnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.sm`
    height: 30rem;
  `}

  ${media.xs`
    height: auto;
  `}
`;
