/* global soundManager:false */
/* eslint-disable no-nested-ternary */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';
import CloudinarySound from 'components/Sound';
import withSound from 'components/Sound/withSound';
import Responsive from 'containers/Responsive';
import { ThemeContext } from 'components/Chapter';
import { sound } from 'styles/variables';

class Music extends PureComponent {
  static contextType = ThemeContext;

  static propTypes = {
    sound: PropTypes.shape({
      playStatus: PropTypes.string
    })
  };

  static defaultProps = {
    sound: {
      playStatus: 'STOPPED'
    }
  };

  state = {
    volume: sound.volume.mediumSoft
  };

  componentDidMount() {
    soundManager.setup({ debugMode: false, ignoreMobileRestrictions: true });
  }

  render() {
    const { chapter } = this.context;
    const {
      sound: { playStatus },
      ...restProps
    } = this.props;
    const { volume } = this.state;
    return (
      <Responsive only="desktop">
        <Sound
          autoLoad
          loop
          url={CloudinarySound.getUrlfromId(`${chapter}/${chapter}_theme`)}
          playStatus={Sound.status[playStatus]}
          volume={volume}
          {...restProps}
        />
      </Responsive>
    );
  }
}

export default withSound(Music);
