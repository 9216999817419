import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import InfoBox from 'components/InfoBox';
import Collage from 'components/Collage';
import {
  OuterWrapper,
  InnerWrapper,
  CollageWrapper,
  MobileRow, MobileCol
} from './styled';

const getInfoBoxOrder = (i) => {
  if (i + 1 < 2) {
    return i + 1;
  }
  return i + 2;
};

const InfoCollage = ({ collage, infoBoxes }) => (
  <OuterWrapper>
    <InnerWrapper>
      <MobileRow>
        <MobileCol xs={12} order={2}>
          <CollageWrapper>
            <Collage
              imageStyle={{
                height: { lg: 'auto' },
                width: { lg: '100% ' }
              }}
              layerStyle={{
                height: 'auto',
                width: '100%'
              }}
              layers={collage.layers}
              caption={collage.caption}
            />
          </CollageWrapper>
        </MobileCol>
        {infoBoxes.map((infoBox, i) => (
          <MobileCol xs={12} order={getInfoBoxOrder(i, collage.order)}>
            <InfoBox {...infoBox.infoBox}>
              <FormattedHTMLMessage id={infoBox.message} />
            </InfoBox>
          </MobileCol>
        ))}
      </MobileRow>
    </InnerWrapper>
  </OuterWrapper>
);

InfoCollage.propTypes = {
  collage: PropTypes.shape({
    order: PropTypes.number
  }),
  infoBoxes: PropTypes.arrayOf(PropTypes.shape({})),
  voffset: PropTypes.shape({})
};

InfoCollage.defaultProps = {
  collage: {
    colProps: {},
    layers: [],
    caption: '',
    captionStyle: null,
    order: 2
  },
  infoBoxes: [
    {
      colProps: {},
      message: '',
      justify: 'flex-start'
    }
  ],
  voffset: {
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0
  }
};

export default InfoCollage;
