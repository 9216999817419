import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
import Layout from 'containers/Layout';
import Background from 'containers/Background';
import Title from 'modules/Title';
import Intro from 'modules/Intro';
import Head from 'components/Head';
import Menu from 'components/Menu';
import Chapter from 'components/Chapter';
import Sound from 'components/Sound';
import Music from 'components/Sound/Music';
import Loader from 'components/Loader';
import Fade from 'components/Fade';

const loadingTime = 3000;

class DesktopTemplate extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({}).isRequired,
    children: PropTypes.node,
    unpublished: PropTypes.bool,
    hideSoundButton: PropTypes.bool
  };

  static defaultProps = {
    children: null,
    unpublished: false,
    hideSoundButton: false
  };

  state = {
    loading: true
  };

  componentDidMount() {
    this.isLoading();
  }

  isLoading = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, loadingTime);
  };

  render() {
    const { theme, children, unpublished, hideSoundButton } = this.props;
    const { loading } = this.state;
    // Toggle this in on or off in /.env.development
    if (process.env.NO_INTRO && process.env.NO_INTRO === 'true') {
      return (
        <ParallaxProvider>
          <Chapter theme={theme}>
            <Head
              title={`${theme.chapter}_heading`}
              description={`${theme.chapter}_title`}
              shareImg="intro/sm-story"
            />
            <Sound>
              <Fragment>
                <Music />
                <Menu hideSoundButton={hideSoundButton}>
                  <Layout>
                    <Background theme="grey">
                      <Title unpublished={unpublished} />
                      {children}
                    </Background>
                  </Layout>
                </Menu>
              </Fragment>
            </Sound>
          </Chapter>
        </ParallaxProvider>
      );
    }
    if (unpublished) {
      return (
        <Fragment>
          <ParallaxProvider>
            <Chapter theme={theme}>
              <Head
                title={`${theme.chapter}_heading`}
                description={`${theme.chapter}_title`}
                shareImg="intro/sm-story"
              />
              <Sound>
                <Fragment>
                  <Music />
                  <Menu>
                    <Layout>
                      <Background theme="grey">
                        <Title unpublished={unpublished} />
                        {children}
                      </Background>
                    </Layout>
                  </Menu>
                </Fragment>
              </Sound>
            </Chapter>
          </ParallaxProvider>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Fade inProp={loading} appear unmountOnExit>
          <Loader delay={loadingTime} />
        </Fade>
        <Fade inProp={!loading}>
          <ParallaxProvider>
            <Chapter theme={theme}>
              <Head
                title={`${theme.chapter}_heading`}
                description={`${theme.chapter}_title`}
                shareImg="intro/sm-story"
              />
              <Sound>
                <Fragment>
                  <Music />
                  <Menu>
                    <Layout>
                      <Background theme="grey">
                        <Intro>
                          {(done, handleAnimationEnd) => (
                            <Fragment>
                              <Title
                                ready={done}
                                unpublished={unpublished}
                                handleAnimationEnd={handleAnimationEnd}
                              />
                              {children}
                            </Fragment>
                          )}
                        </Intro>
                      </Background>
                    </Layout>
                  </Menu>
                </Fragment>
              </Sound>
            </Chapter>
          </ParallaxProvider>
        </Fade>
      </Fragment>
    );
  }
}

export default DesktopTemplate;
