import styled, { css } from 'styled-components';
import media from 'styles/media';
import { sans, serif, titleSans, grime } from './variables';

export const h1Mixin = css`
  font-family: ${titleSans};
  font-size: 5.625rem;
  line-height: 6.625rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #f7f3ef;
  text-align: center;

  ${media.md`
    font-size: 5rem;
    line-height: 6rem;
  `};

  ${media.sm`
    font-size: 4.375rem;
    line-height: 5.375rem;
  `};

  ${media.xs`
    font-size: 3rem;
    line-height: 4rem;
  `};
`;

export const H1 = styled.h1`
  ${h1Mixin};
`;

export const h2Mixin = css`
  font-family: ${serif};
  font-size: 2.5rem;
  margin: 4.625rem 0;
`;

export const H2 = styled.h2`
  ${h2Mixin};
`;

export const h3Mixin = css`
  font-family: ${sans};
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  display: inline;
  text-align: center;
`;

export const H3 = styled.h3`
  ${h3Mixin};
`;

export const paragraphMixin = css`
  font-family: ${sans};
  font-size: 1rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.8rem;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0 0 1.8rem 0;

  ${media.md`
    font-size: 0.9375rem;
    line-height: 1.625rem;
  `};
`;

export const P = styled.p`
  ${paragraphMixin};
`;

export const captionMixin = css`
  color: ${grime};
  font-family: ${sans};
  font-size: 0.875rem;
  line-height: 1.375rem;

  ${media.md`
    font-size: 0.8125rem;
  `};
`;
