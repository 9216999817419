/* eslint-disable prefer-destructuring, import/prefer-default-export */

/**
 * Gets the scale ratio for full-screen cover sprite
 * @param {object} img - height, width
 * @param {object} container - height, width
 * @returns {number} - Scale ratio
 */
export const cover = (img, container) => {
  const imageRatio = img.height / img.width;
  const containerRatio = container.height / container.width;
  if (containerRatio > imageRatio) {
    return container.height / img.height;
  }
  return container.width / img.width;
};

export const openMask = (mask, rate) => {
  let m = mask;
  if (m === 0) {
    m = 1;
  }
  if (m <= 100) {
    return m + m * rate + 1;
  }
  return m + m * rate + 4;
};

export const closeMask = (mask, rate) => {
  if (mask - rate < 0) {
    return 0;
  }
  return mask - mask * rate;
};
