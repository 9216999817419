import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.div`
  pointer-events:all;


  height: 47px;
  ${media.md`
    height: 47px;
  `};

  ${media.sm`
    height: 45px;
  `};

  ${media.xs`
    height: 40px;
  `};
`;

export const Image = styled.img`
  height: 100%;
`;
