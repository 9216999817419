import 'video-react/dist/video-react.css';
import styled from 'styled-components';
import { Player, PlayToggle as PlayToggleBase } from 'video-react';

import media from 'styles/media';
import play from 'assets/images/player/play.svg';
import pause from 'assets/images/player/pause.svg';
import volumeOn from 'assets/images/player/sound-on.svg';
import volumeOff from 'assets/images/player/sound-off.svg';
import fsOpen from 'assets/images/player/fullscreen-open.svg';
import fsClose from 'assets/images/player/fullscreen-close.svg';
import { sans } from 'styles/variables';

export default styled(Player)`
  video::-webkit-media-text-track-display {
    font-size: 1.4rem;
    line-height: 2rem;

    ${media.xs`
      font-size: 1.4rem;
      line-height: 2rem;
    `};
  }

  video::cue {
    font-size: 1.4rem;
    line-height: 2rem;

    ${media.xs`
      font-size: 1.4rem;
      line-height: 2rem;
    `};
  }

  &.video-react-fluid.video-react-fluid {
    max-height: 100vh;
    max-width: 100vw;
    padding: 0 !important;
    height: 100%;
    font-family: ${sans};

    .video-react-big-play-button {
      display: none;
    }

    .video-react-slider:focus {
      text-shadow: none;
      box-shadow: none;
    }

    .video-react-control-bar {
      display: flex;
      background-color: transparent;
      padding: 2rem;
      align-items: center;
      height: auto;

      ${media.xs`
        padding: 2rem 15px;
      `};

      .video-react-play-control {
        width: 52px;
        height: 52px;
        margin-right: 2rem;
        background-position: center center;

        ${media.xs`
          width: 40px;
          height: 40px;
          margin-right: 0;
        `};

        &::before {
          display: none;
        }

        &.video-react-paused {
          background: transparent url(${play}) no-repeat;
          background-size: cover;
        }

        &.video-react-playing {
          background: transparent url(${pause}) no-repeat;
          background-size: cover;
        }
      }

      .video-react-volume-menu-button {
        width: 25px;
        height: 22px;
        margin-right: 2rem;
        background: transparent url(${volumeOn}) no-repeat;
        background-position: center center;

        ${media.xs`
          margin-right: 15px;
        `};

        &:hover {
          .video-react-menu-content {
            //width: 0.5rem;
          }
        }

        &.video-react-vol-muted {
          background: transparent url(${volumeOff}) no-repeat;
        }

        .video-react-menu {
          left: 0;

          .video-react-menu-content {
            background: transparent;
            height: 8rem;
            bottom: 0.5em;

            .video-react-slider-vertical {
              height: 7rem;
              width: 100%;
              overflow: hidden;
              background-color: transparent;
              margin: 0 auto;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 0.5rem;
                top: 0;
                bottom: 0;
                background-color: #373738;
                margin: 0 auto;
                border-radius: 10px;
              }

              .video-react-volume-level {
                margin: 0 auto;
                right: 0;
                width: 0.5rem;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                &::before {
                  display: none;
                }
              }
            }
          }

          .video-react-volume-level {
            width: 100%;
          }
        }

        &::before {
          display: none;
        }
      }

      .video-react-time-control {
        font-size: 1.1rem;
        padding-left: 2px;
        padding-right: 2px;

        ${media.xs`
          position: absolute;
          line-height: 1;
          height: auto;
          top: 30px;
          left: 74px;
          font-size: 12px;
        `};
      }

      .video-react-time-divider {
        ${media.xs`
          margin-left: 25px;
        `};
      }

      .video-react-duration {
        ${media.xs`
          margin-left: 32px;
        `};
      }

      .video-react-progress-control {
        margin: 0 2rem;

        ${media.xs`
          margin: 0 15px;
        `};
      }

      .video-react-fullscreen-control {
        width: 25px;
        height: 20px;
        background-position: center center;
        background: transparent url(${fsOpen}) no-repeat;

        &.video-react-icon-fullscreen-exit {
          background: transparent url(${fsClose}) no-repeat;
        }

        &::before {
          display: none;
        }
      }

      .video-react-progress-holder {
        height: 10px;
        border-radius: 10px;
        overflow: hidden;

        .video-react-load-progress,
        .video-react-play-progress,
        .video-react-load-progress div {
          height: 10px;
        }

        .video-react-load-progress,
        .video-react-load-progress div {
          background: #373738;
        }
        .video-react-play-progress:before {
          display: none;
        }
      }
    }
  }

  .video-react
    .video-react-volume-menu-button-vertical:hover
    .video-react-menu-content,
  .video-react
    .video-react-volume-menu-button-vertical:focus
    .video-react-menu-content,
  .video-react
    .video-react-volume-menu-button-vertical.video-react-slider-active
    .video-react-menu-content,
  .video-react
    .video-react-volume-menu-button-vertical
    .video-react-lock-showing
    .video-react-menu-content {
    width: 1.5rem;
    height: 5rem;
  }
`;

export const PlayToggle = styled(PlayToggleBase)`
  //background: transparent url(${play}) no-repeat;
`;
