import styled from 'styled-components';
import media from 'styles/media';
import { pavement, grey } from 'styles/variables';

import { navWidth, topBarHeight, animation } from './variables';

export const Row = styled.div`
  display: flex;
  flex-flow: ${props => (props.row ? 'row' : 'column')};
  border-bottom: ${props => (props.border ? `1px solid ${pavement}` : null)};
  position: relative;
  width: 100%;
  background-color: ${props => (props.bgcolor ? props.bgcolor : 'none')};
  overflow: hidden;
  flex: ${props =>
    props.flex ? `1 1 ${props.size.lg}rem` : `0 1 ${props.size.lg}rem`};

  ${media.md`
    flex: ${props =>
      props.flex ? `1 1 ${props.size.md}rem` : `0 1 ${props.size.md}rem`};
  `};

  ${media.sm`
    flex: ${props =>
      props.flex ? `1 1 ${props.size.sm}rem` : `0 1 ${props.size.sm}rem`};
  `};

  ${media.xs`
    flex: ${props => (props.flex ? `1 1 auto` : `0 1 ${props.size.xs}rem`)};
  `};
`;

export const Col = styled.div`
  display: inline-flex;
  flex-flow: column;
  flex: 0 1 ${props => (!props.size ? 'auto' : props.size)};
`;

export const EverythingWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
`;

export const BodyWrapper = styled.div`
  width: ${props => (props.menuOpen ? '100vw' : '100%')};
  height: ${props => (props.menuOpen ? '70rem' : 'auto')};
  overflow: ${props => (props.menuOpen ? 'hidden' : 'visible')};
  display: ${props => (props.menuOpen ? 'none' : 'block')};
`;

export const Menu = styled.menu`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 50;
  min-height: 600px;
  height: 100vh;
  margin: 0;
  padding: 0;
  pointer-events: none;

  ${media.xs`
    display: flex;
    position: absolute;
    flex-flow: column;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    min-height: initial;
  `};
`;

export const Main = styled.section`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  flex: 1 0 auto;
  background-color: ${grey};
  width: ${navWidth.lg.width};
  min-height: inherit;
  height: inherit;
  top: 0;
  transform: ${props => (props.open ? 'translate(0)' : 'translate(100%)')};
  transition: transform ${animation.slow}ms ease,
    visibility ${animation.medium}ms ease, opacity ${animation.medium}ms ease;
  pointer-events: auto;

  ${media.md`
    width: ${navWidth.md.width};
  `};

  ${media.xs`
    position: absolute;
    width: 100%;
    min-height: ${props => (props.open ? '68rem' : 'initial')};
    min-width: initial;
    max-width: initial;
    right: 0;
    transform: none;
    height: ${props => (props.open ? 'auto' : 0)};
    padding: ${topBarHeight}rem 0 0;
    opacity: ${props => (props.open ? 1 : 0)};
    visibility: ${props => (props.open ? 'visible' : 'hidden')};
  `};
`;
