/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import { white, black, sans } from 'styles/variables';
import media from 'styles/media';
import { animation, margin } from '../variables';
import { slideIn, fadeIn } from '../effects';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${black};
  opacity: ${props => (props.open ? 0.6 : 0)};
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  z-index: 40;
  transition: opacity ${animation.slow}ms ease;
  cursor: ${props => (props.open ? 'pointer' : 'unset')};
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  backspace-visibility: hidden;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  font-family: ${sans};
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  width: ${margin.right.lg}rem;
  background-color: ${white};
  min-height: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  pointer-events: auto;
  animation: ${slideIn} ease ${animation.slow}ms;
  animation-fill-mode: forwards;
  animation-delay: 800ms;
  transform: translateX(100%);

  ${media.md`
    width: ${margin.right.md}rem;
  `};
`;

export const SoundButtonWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: inherit;
  height: inherit;
`;

export const ShareLabel = styled.label`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  background-color: none;
  justify-content: center;
  align-items: center;
`;

export const ShareIcon = styled.img`
  width: 24px;
  height: 22px;
`;

export const Label = styled.label`
  padding: 0;
  position: relative;
  top: ${props =>
    props.locale === 'zh' || props.locale === 'ar' ? '0' : '50%'};
  left: ${props =>
    props.locale === 'zh' || props.locale === 'ar' ? '0' : '50%'};
  display: flex;
  flex: 1 0 auto;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  letter-spacing: ${props => (props.locale === 'zh' ? '0.1rem' : 'initial')};
  transform: ${props =>
    props.locale === 'zh' || props.locale === 'ar'
      ? 'initial'
      : 'translateX(-50%) translateY(-50%) rotate(-90deg)'};
  writing-mode: ${props =>
    props.locale === 'zh'
      ? 'vertical-lr'
      : props.locale === 'ar'
        ? 'vertical-rl'
        : 'initial'};
`;

export const LogoWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props =>
    props.locale === 'ar' ? 'flex-end' : 'flex-start'};
  align-items: flex-end;
  padding: ${props =>
    props.locale === 'ar'
      ? `0 ${margin.right.lg * 2}rem 2rem`
      : `0 0 2rem ${margin.left.lg + margin.inner.lg}rem`};
  ${fadeIn};

  ${media.md`
    padding: ${props =>
      props.locale === 'ar'
        ? `0 ${margin.right.lg + margin.inner.lg}rem 2rem`
        : `0 0 2rem ${margin.left.lg + margin.inner.lg}rem`};
    `};
`;
