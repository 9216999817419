import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Ring } from './styled.js';

const Rings = ({ children }) => (
  <Wrapper>
    <Ring shade="pavement" size={1}>
      <Ring shade="pavement" size={0.8}>
        <Ring shade="jailcell" size={0.6}>
          {children}
        </Ring>
      </Ring>
    </Ring>
  </Wrapper>
);

Rings.propTypes = {
  children: PropTypes.node.isRequired
};

export default Rings;
