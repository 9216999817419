import styled from 'styled-components';
import { sans, white } from 'styles/variables';

import media from 'styles/media';

export default styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px 25px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  font-size: 0.875rem;
  font-family: ${sans};
  line-height: 1.5;
  color: ${white};
  width: 100%;
  text-align: left;

  ${media.xs`
    max-width: 100%;
    > span {
      word-break: break-word;
    }
  `};
`;
