import styled from 'styled-components';
import media from 'styles/media';
import { H1, h3Mixin } from 'styles/typography';

import {
  yellow,
  white,
  silverChalice,
  padding,
  sans,
  black
} from 'styles/variables';

export const OuterWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
`;

export const InnerWrapper = styled.div`
  overflow-y: hidden;
  height: 100%;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: no nowrap;
  justify-content: flex-start;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

   padding: ${padding.top.lg}rem ${padding.right.lg}rem 0 ${padding.left.lg}rem;

  ${media.md`
    padding: ${padding.top.md}rem ${padding.right.md}rem 0 ${
    padding.left.md
    }rem;
`};

  ${media.sm`
    padding: ${padding.top.sm}rem ${padding.right.sm}rem 0 ${
    padding.left.sm
    }rem;
  `};

  ${media.xs`
    padding: ${padding.inner.xs}rem ${padding.right.xs}rem 0 ${
    padding.left.xs
    }rem;
    min-height: initial;
    height: calc(100vh - 64px);
    overflow: hidden;
  `};
`;

export const TitleWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled(H1)`
  position: relative;
  font-size: 8.225rem;
  line-height: normal;
  letter-spacing: -3px;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
  margin: 0;

  ${media.md`
    font-size: 7rem;
  `}

  ${media.sm`
    font-size: 6rem;
  `}

  span {
    line-height: inherit;
  }
`;

export const ChapterButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50vh;
  padding: 7rem 0 0;
`;

export const Chapter = styled.h2`
  ${h3Mixin};
  display: block;
  color: ${black};
  font-size: 1.375rem;
  position: relative;
  padding: 0 0 2rem 0;
  letter-spacing: -1px
`;

export const Button = styled.button`
  border: 0;
  border-radius: 5px;
  font-family: ${sans};
  font-size: 14px;
  background-color: ${yellow};
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 214px;
  height: 62px;
  overflow: hidden;
  user-select: none;
  -webkit-touch-callout: none;
  box-shadow: ${props =>
    props.active || props.progress >= 100
      ? `0 0 0 ${silverChalice}`
      : `0px 5px 0px ${silverChalice}`};
  top: ${props => (props.active || props.progress >= 100 ? '5px' : '0')};

  ${media.xs`
    width: 80vw;
    height: 70px;
     top: ${props =>
       props.active || props.progress >= 100 ? 'calc(15vh + 5px)' : '15vh'};
  `};
`;

export const ProgressBar = styled.span`
  background-color: ${white};
  border: 0;
  top: 0;
  left: 0;
  border-radius: 5px;
  height: 63px;
  outline: none;
  cursor: pointer;
  position: absolute;
  z-index: auto;
  width: 100%;
  transition: translateX;

  ${media.xs`
    height: 70px;
  `};
`;

export const Label = styled.label`
  margin: 0;
  padding: 0;
  z-index: 1;
  user-select: none;
  -webkit-touch-callout: none;
  cursor: pointer;
  ${media.sm`
    display: none;
  `};
`;

export const MobileLabel = styled(Label)`
  display: none;
  user-select: none;
  -webkit-touch-callout: none;
  ${media.sm`
    display: block;
  `};
`;
