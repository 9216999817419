import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';
import { ScrollArrow, ScrollDown } from './styled.js';

class Arrow extends Component {
  static propTypes = {
    color: PropTypes.string,
    cta: PropTypes.string
  };

  static defaultProps = {
    color: 'white',
    cta: null
  };

  state = {
    style: { opacity: 1 }
  };

  handleChange = isVisible => {
    if (!isVisible) {
      return this.setState({
        style: { opacity: 0 }
      });
    }
    return this.setState({
      style: { opacity: 1 }
    });
  };

  render() {
    const { cta, color } = this.props;
    return (
      <VisibilitySensor
        offset={{ top: 400 }}
        intervalDelay={250}
        onChange={this.handleChange}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'opacity ease 750ms',
            ...this.state.style
          }}
        >
          <ScrollDown color={color}>
            <span>
              <ScrollArrow />
            </span>
          </ScrollDown>
          {cta ? (
            <p>
              <FormattedMessage id={cta} />
            </p>
          ) : null}
        </div>
      </VisibilitySensor>
    );
  }
}

export default Arrow;
