import styled from 'styled-components';
import media from 'styles/media';
import { P } from 'styles/typography';
import { margin, animation } from '../variables';

export const ChapterWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  direction: inherit;
  align-items: center;
  flex: 1 1 auto;

  ${media.xs`
    margin: 0;
    flex-flow: row;
    align-items: flex-start;

    &:before {
      font-size: 1.5rem;
      content: '${props => (props.isSelected ? '●' : '○')}';
      margin: ${props =>
        props.locale === 'ar'
          ? `0 0 0 ${margin.inner.sm}rem`
          : `0 ${margin.inner.sm}rem 0 0`};
    }
  `};
`;

export const ChapterNumber = styled(P)`
  text-transform: capitalize;
  font-weight: inherit;
  font-size: inherit;
  transition: inherit;
  white-space: nowrap;

  ${media.md`
     margin: 0 1rem 0 0;
  `};

  ${media.xs`
    margin: 0;
  `};
`;

export const ChapterName = styled(P)`
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
  transition: inherit;
  white-space: nowrap;
`;

export const Chapter = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  direction: inherit;
  margin: ${props =>
    props.locale === 'ar' ? `0 ${margin.right.md * 1.5}rem 0 0` : 0};
  font-size: 0.9375rem;
  font-weight: ${props => (props.isSelected ? 500 : 400)};
  opacity: ${props => (props.isSelected ? 1 : 0.5)};
  transition: opacity ${animation.medium}ms ease;

  &:hover {
    opacity: 1;
  }

  ${ChapterName} {
    margin: ${props => (props.locale === 'ar' ? '0 2rem 0 0' : 0)};
    ${media.xs`
      margin: 0;
    `};
  }

  ${ChapterNumber} {
    margin: ${props => (props.locale === 'ar' ? 0 : '0 2rem 0 0')};
  }

  ${media.md`
    margin: ${props =>
      props.locale === 'ar' ? `0 ${margin.right.md}rem 0 0` : 0};
  `};

  ${media.xs`
    flex-flow: column;
    font-size: 1.1rem;
    line-height: 1.6rem;
    animation: none;
    margin: 0;
  `};
`;
