import styled from 'styled-components';

import {white, mineSahft, sans, black, dustyGray, karla, titleSans, codGray, cloudBurst} from "styles/variables";
import {P as PBase} from "styles/typography";
import {LastUpdated as LastUpdatedBase} from "modules/Conventions/styled";
import {ColorLabel as ColorLabelBase} from "modules/Conventions/ConventionItem/styled";
import {CountryName} from "modules/Conventions/CountryModal/styled";
import media from 'styles/media';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  background-color: ${white};
  //z-index: 11;

  @media screen and (max-width: 751px) {
    min-height: 100vh;
    height: auto;
    width: 100%;
    z-index: 1;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 55px;
  right: 50px;
  z-index: 3;
  cursor: pointer;
  width: 70px;

  @media screen and (max-width: 1024px) {
    top: 35px;
    right: 60px;
  }

  ${media.xs`
    top: 75px;
    right: 18px;
  `};
`;

export const Left = styled.div`
  background-color: ${white};
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => `transparent url(${props.imageSrc}) no-repeat`};
  background-size: cover;
`;

export const Content = styled.div`
  background-color: ${white};
  z-index: 2;
  width: 50%;
  padding: 3.5rem;
  position: relative;
  left: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    left: 0;
  }

  @media only screen and (min-device-width : 1024px) and (max-width: 1280px) {
    padding: 2.5rem;
  }

  ${media.xs`
    padding: 50px 18px 18px 18px;
    position: absolute;
  `};
`;

export const LastUpdated = styled(LastUpdatedBase)`
  top: auto;
  right: auto;
  position: relative;
  margin-bottom: 5rem;
  font-family: ${sans};
  font-size: 14px;
  color: ${black};

  ${media.xs`
    display: none;
  `};
`;

export const ColorLabel = styled(ColorLabelBase)`
  font-size: 14px;
  padding: 4px 12px;
  margin-left: 0;
  font-weight: 400;
`;

export const ColorLabelToggle = styled(ColorLabel)`
  color: ${dustyGray};
  border: 1px solid ${dustyGray};
  background-color: ${white};
  margin-right: 10px;
  cursor: pointer;

  &.active {
    color: ${white};
    border-color: ${props => props.bgColor || white};
    background-color: ${props => props.bgColor || white};
  }

  ${media.xs`
    margin-bottom: 5px;
  `};
`;

export const ToggleWrapper = styled.div`
  display: flex;

  ${media.xs`
    flex-direction: column;
  `};

  @media screen and (max-width: 680px) {
    flex-direction: row;
  }
`;

export const Title = styled(CountryName)`
  text-align: left;
  color: ${mineSahft};
  margin-top: 20px;

  @media screen and (max-width: 1024px) {
    font-size: 30px
  }

  ${media.xs`
    margin-top: 45px;
    padding-right: 0;
    font-size: 40px;
  `};
`;

export const P = styled(PBase)`
  font-size: 14px;
  text-align: left;
  color: ${black};
  margin-bottom: 6.5rem;

  ${media.xs`
    margin-bottom: 35px;
    font-size: 15px;
  `};
`;

export const Filter = styled(ColorLabel)`
  width: 49%;
  color: ${dustyGray};
  border: 1px solid ${dustyGray};
  margin: 0 5px;
  background-color: ${white};
  max-width: 100%;
  text-align: center;
  padding: 8px 12px;
  font-weight: 400;
  cursor: pointer;
  font-family: ${sans};

  &.active {
    color: ${white};
    border-color: ${props => props.bgColor || white};
    background-color: ${props => props.bgColor || white};
    font-weight: 700;
  }

  @media only screen and (min-device-width : 1024px) and (max-width: 1280px) {
    margin: 5px;
    font-size: 13px;
    padding: 5px;
  }

  ${media.xs`
    width: 100%;
    display: none;

    &.active {
      display: block;
    }
  `};
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const ListWrapper = styled.div`
  display: flex;

  ${media.xs`
    flex-direction: column;
  `};
`;

export const ListItem = styled.div`
  display: flex;
  font-family: ${sans};
  font-size: 16px;
  font-weight: 400;
  color: ${black};
  padding: 10px 0;

  @media screen and (max-width: 1280px) {
    font-size: 15px;
  }

  span {
    font-weight: 600;
    max-width: 60%;
    width: 100%;
    margin-right: 10px;

    ${media.xs`
      max-width: 100%;
      text-align: center;
      font-size: 16px;
    `};
  }
`;

export const Count = styled.div`
  color: ${codGray};
  font-family: ${titleSans};
  font-size: 4.3rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: -5px;
  padding: 0 3rem;
  position: relative;

  div {
    font-size: 18px;
    font-family: ${karla};
    color: ${black};
    padding-left: 9px;
    letter-spacing: 0;
  }

  ${media.xs`
    margin-bottom: 20px;
    padding-bottom: 20px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      bottom: 0;
      margin: 0 auto;
      width: 70%;
      background-color: ${cloudBurst};
    }
  `};

  @media only screen and (min-device-width : 769px) and (max-width: 1024px) {
    padding: 0 2rem;
  }
`;