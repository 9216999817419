import styled from 'styled-components';
import media from 'styles/media';
import { padding } from 'styles/variables';

export const Wrapper = styled.section`
  background-color: #000;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100vh;
  min-height: 900px;
  width: 100vw;
  margin: auto;

  ${media.md`
    min-height: 800px;
  `}

  ${media.xs`
    height: 100%;
    min-height: 100vh;
    justify-content: flex-start;
    padding: 1rem;
  `};
`;

export const InnerWrapper = styled.div`
  position: relative;
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${media.md`
    height: 85vh;
  `}

  ${media.sm`
    height: 90vh;
  `};

  ${media.xs`
    height: 100%;
    justify-content: flex-start;
    margin: ${padding.top.xs + 1}rem 0 0 0;
  `};

  ul {
    columns: 1;
  }
`;

export const FigureWrapper = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 0;

  ${media.xs`
    display: none;
  `};
`;

export default Wrapper;
