/* eslint-disable global-require */

export default {
  en: {
    color: require('../../assets/images/en-logo100-web.svg'),
    white: require('../../assets/images/en-logo100-web-white.svg'),
    black: require('../../assets/images/en-logo100-web-black.svg')
  },
  fr: {
    color: require('../../assets/images/fr-logo100-web.svg'),
    white: require('../../assets/images/fr-logo100-web-white.svg'),
    black: require('../../assets/images/fr-logo100-web-black.svg')
  },
  es: {
    color: require('../../assets/images/es-logo100-web.svg'),
    white: require('../../assets/images/es-logo100-web-white.svg'),
    black: require('../../assets/images/es-logo100-web-black.svg')
  },
  ru: {
    color: require('../../assets/images/ru-logo100-web.svg'),
    white: require('../../assets/images/ru-logo100-web-white.svg'),
    black: require('../../assets/images/ru-logo100-web-black.svg')
  },
  ar: {
    color: require('../../assets/images/ar-logo100-web.svg'),
    white: require('../../assets/images/ar-logo100-web-white.svg'),
    black: require('../../assets/images/ar-logo100-web-black.svg')
  },
  zh: {
    color: require('../../assets/images/zh-logo100-web.svg'),
    white: require('../../assets/images/zh-logo100-web-white.svg'),
    black: require('../../assets/images/zh-logo100-web-black.svg')
  }
};
