/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import { SoundContext } from 'components/Sound/index';

const withSound = WrappedComponent =>
  class extends Component {
    render() {
      return (
        <SoundContext.Consumer>
          {value => <WrappedComponent sound={value} {...this.props} />}
        </SoundContext.Consumer>
      );
    }
  };

export default withSound;
