/* eslint-disable no-nested-ternary */

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Outer, Inner } from 'containers/Wrapper';
import PropTypes from 'prop-types';
import Tear from 'components/Tear';
import { cobalt } from 'styles/variables';

import {
  Wrapper,
  LargeCallout,
  MediumCallout,
  SmallCalloutWrapper,
  SmallCallout,
  TearsCallout,
  TopTear,
  Center
} from './styled';

const Callout = ({
  className,
  color,
  backgroundColor,
  children,
  size,
  stamp,
  intl: { locale }
}) => {
  const getCallout = () => {
    const direction = locale === 'ar' ? 'rtl' : 'ltr';

    if (size === 'large') {
      return (
        <LargeCallout
          dir={direction}
          color={color}
          locale={locale}
          className={className}
        >
          {children}
        </LargeCallout>
      );
    }
    if (size === 'medium') {
      return (
        <MediumCallout
          dir={direction}
          color={color}
          locale={locale}
          className={className}
        >
          {children}
        </MediumCallout>
      );
    }
    if (size === 'tears') {
      return (
        <TearsCallout color={backgroundColor} lassName={className}>
          <TopTear />
          <Outer>
            <Inner>
              <Row center="xs">
                <Col xs={12} sm={10} md={7}>
                  <Center>{children}</Center>
                </Col>
              </Row>
            </Inner>
          </Outer>
          <Tear />
        </TearsCallout>
      );
    }

    return (
      <SmallCalloutWrapper stamp={stamp} className={className}>
        <SmallCallout dir={direction} color={color}>
          {children}
        </SmallCallout>
      </SmallCalloutWrapper>
    );
  };
  return <Wrapper className={className}>{getCallout()}</Wrapper>;
};

Callout.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  size: PropTypes.string,
  stamp: PropTypes.string,
  intl: intlShape.isRequired,
  backgroundColor: PropTypes.string
};

Callout.defaultProps = {
  color: 'black',
  className: null,
  size: 'medium',
  stamp: null,
  backgroundColor: cobalt
};

export default injectIntl(Callout);
