import React from 'react';
import BaseSelect, { components } from 'react-select';
import styled  from 'styled-components';

import SelectScrollBar from 'components/ScrollBar/index';
import {sans, emperor, black, grey, white} from 'styles/variables';

const ArrowDown = styled.div`
  width: 0; 
  height: 0; 
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  
  border-top: 9px solid ${props => props.color};
`;

const ArrowUp = styled.div`
  width: 0; 
  height: 0; 
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  
  border-bottom: 9px solid ${props => props.color};
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '14px 25px',
    textAlign: 'left',
    fontFamily: sans,
    fontSize: 15,
    color: black,
    cursor: 'pointer',
    backgroundColor: state.isSelected && grey,
    '&:hover': {
      backgroundColor: grey,
    },
    '@media screen and (max-width: 752px)': {
      fontSize: 16,
    }
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '5px',
    minHeight: 50,
    cursor: 'pointer',
    borderColor: black,
    boxShadow: 'none',
    '&:hover': {
      borderColor: black
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 20px 8px 8px',
    color: 'initial',
    '&:hover': {
      color: 'initial'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)' : {
      paddingTop: 10
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '8px 8px 8px 20px',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)' : {
      paddingTop: 12
    }
  }),
  menu: (provided) => ({
    ...provided,
    padding: 0,
    boxShadow: '0 3px 6px 0 rgba(0,0,0,0.5)',
    marginTop: 30,
    '&:before': {
      content: '""',
      height: 23.24,
      width: 23.24,
      transform: 'rotate(315deg)',
      backgroundColor: white,
      boxShadow: '0 -1px 6px 0 rgba(0,0,0,0.5)',
      zIndex: -1,
      position: 'absolute',
      top: -10,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: '5px',
    maxHeight: 234,
    minHeight: 45,
    backgroundColor: white,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    fontFamily: sans,
    color: emperor,
    '@media screen and (max-width: 752px)': {
      fontSize: 13,
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)' : {
      paddingTop: 2
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 14,
    fontFamily: sans,
    '@media screen and (max-width: 768px)': {
      fontSize: 16,
    }
  }),
  input: (provided) => ({
    ...provided,
    fontSize: 14,
    fontFamily: sans,
    '@media screen and (max-width: 768px)': {
      fontSize: 16,
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '13px 12px',
    fontFamily: sans,
  }),
};

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuToggle: false,
      inputValue: '',
    };
  }

  handleMenuOpen(e) {
    this.setState({ 
      inputValue: e 
    }, () => {
      this.state.inputValue.length >= 1 ? this.setState({ menuToggle: true }) : this.setState({ menuToggle: false })
    })
  }

  render() {
    const { menuToggle } = this.state;
    const DropdownIndicator = (props) => {
      const { selectProps: { menuIsOpen } } = props;
    
      return components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {menuIsOpen
            ? (
              <ArrowUp
                primaryColor="#000"
              />
            )
            : (
              <ArrowDown
                primaryColor={black}
              />
            )
          }
    
        </components.DropdownIndicator>
      );
    };
    
    const renderThumbVertical = ({ style, ...props }) => (
      <div
        {...props}
        style={{
          ...style,
          backgroundColor: black,
          borderRadius: 20,
        }}
        className="thumb-vertical"
      />
    );
    
    const MenuList = (props) => {
      const { children, ...restProps } = props;
    
      return components.MenuList && (
        <components.MenuList {...restProps}>
          <SelectScrollBar
            renderThumbVertical={renderThumbVertical}
            height={234}
          >
            {children}
          </SelectScrollBar>
        </components.MenuList>
      );
    };

    const isMobile = window.innerWidth < 752;
    if(isMobile) {
      return (
        <BaseSelect
          components={{ DropdownIndicator, MenuList }}
          styles={customStyles}
          menuIsOpen={menuToggle}
          onInputChange={(e) => this.handleMenuOpen(e)}
          {...this.props}
        />
      );
    } else {
      return (
        <BaseSelect
          components={{ DropdownIndicator, MenuList }}
          styles={customStyles}
          {...this.props}
        />
      );
    }
  }

}

export default Select;