/* global soundManager:false */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';
import Responsive from 'containers/Responsive';
import CloudinarySound from 'components/Sound';
import withSound from 'components/Sound/withSound';
import { sound as sounds } from 'styles/variables';

class Soundbyte extends PureComponent {
  static propTypes = {
    volume: PropTypes.number,
    playing: PropTypes.bool,
    onPlaying: PropTypes.func,
    onFinishedPlaying: PropTypes.func,
    sound: PropTypes.shape({}).isRequired
  };

  static defaultProps = {
    playing: false,
    volume: sounds.volume.medium,
    onPlaying: () => {},
    onFinishedPlaying: () => {}
  };

  state = {
    volume: this.props.volume,
    playStatus: this.props.playing ? 'PLAYING' : 'STOPPED'
  };

  componentDidMount() {
    soundManager.setup({ debugMode: false });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.playing !== this.props.playing) {
      this.getDerivedPlayStatus(prevProps);
    }
  }

  getDerivedPlayStatus = prevProps => {
    if (prevProps.playing && !this.props.playing) {
      return this.fadeOut();
    }
    if (!prevProps.playing && this.props.playing) {
      return this.fadeIn();
    }
    return null;
  };

  setVolume = volume => {
    if (volume <= 100 && volume >= 0) {
      this.setState({
        volume
      });
    }
  };

  fadeIn = () => {
    this.setState({ playStatus: 'PLAYING', volume: this.props.volume });
  };

  fadeOut = () => {
    const fadeout = setInterval(() => {
      if (this.state.volume === 0) {
        this.setState({ playStatus: 'STOPPED' });
        return clearInterval(fadeout);
      }
      if (this.state.volume > 0) {
        return this.setVolume(this.state.volume - 1);
      }
      return null;
    }, 75);
  };

  render() {
    const { id, volume, sound, ...restProps } = this.props;
    const { playStatus } = this.state;
    return sound.playStatus === 'PLAYING' ? (
      <Responsive only="desktop">
        <Sound
          autoLoad
          volume={this.state.volume}
          url={CloudinarySound.getUrlfromId(id)}
          playStatus={Sound.status[playStatus]}
          {...restProps}
        />
      </Responsive>
    ) : null;
  }
}

export default withSound(Soundbyte);
