import * as vars from 'styles/variables';

const matchColor = color => {
  if (!vars[color]) {
    return color;
  }
  if (!/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(vars[color])) {
    return 'pink';
  }
  return vars[color];
};

export default matchColor;
