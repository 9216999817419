import styled from 'styled-components';

import {serif, white, offblue, indigo, titleSans, bunting, sans} from "styles/variables";
import {P as PBase} from "styles/typography";
import {Button} from "components/Modal/styled";
import {Circle} from "modules/Conventions/ConventionItem/styled";
import media from 'styles/media';

export const CountryConventions = styled.div`
  max-width: 855px;
  margin: 0 auto;
  position: relative;
`;

export const CountryName = styled.h3`
  font-family: ${serif};	
  font-size: 2.6rem;	
  color: ${white};
  text-align: center;
  font-weight: 400;
  
  ${media.xs`
    text-align: left;
    padding-right: 70px;
  `};
`;

export const CountryCloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: -8px;
  
  @media screen and (max-width: 900px) {
    right: 40px;
  } 
  
  ${media.xs`
    right: 0;
    top: -8px;
  `};
`;

export const CountryCircle = styled(Circle)`
  position: relative;
  border-radius: 50%;
  width: 275px;
  height: 275px;
  left: 0;
  right: 0;
  margin: 0 auto 4.6rem auto;
  background-color: ${white};
  padding: 49px;
`;

export const P = styled(PBase)`
  font-size: 16px;
  color: ${offblue};
  text-align: center;
  line-height: 1.2;

  ${media.xs`
    font-size: 15px;
  `};
`;

export const Row = styled.div`
  position: relative;
`;

export const Count = styled.div`
  font-size: 6.25rem;	
  font-weight: bold;
  text-align: center;
  position: relative;
  color: ${offblue};
  font-family: ${titleSans};
  
  &:before {
    content: ${props => `"/${props.allCount}"`};
    position: absolute;
    top: 13px;
    right: -32px;
    font-size: 1.875rem;
    color: ${indigo};
  }
`;

export const ConventionListItem = styled.div`
  position: relative;
  padding: 30px 36px;
  border-bottom: 2px solid ${bunting};
  color: ${white};
  font-size: 16px;
  font-family: ${sans};
  margin-right: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${media.xs`
    flex-direction: column;
    padding: 30px 15px 30px 0;
    margin-right: 5px;
  `};
`;

export const ConventionListItemName = styled.div`
  width: 60%;
  line-height: 1.5;
  
  ${media.xs`
    width: 100%;
    margin-bottom: 10px;
  `};
`;

export const ConventionListItemDate = styled.div`
  width: 20%;
  color: ${indigo};
  
  ${media.xs`
    width: 100%;
  `};
`;