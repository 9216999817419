import React from 'react';
import withResponsive from 'containers/Responsive/withResponsive';
import DesktopInfoCollage from './Desktop';
import MobileInfoCollage from './Mobile';

const InfoCollage = ({ isMobile, ...props }) =>
  isMobile ? (
    <MobileInfoCollage {...props} />
  ) : (
      <DesktopInfoCollage {...props} />
  );

export default withResponsive(InfoCollage);
