/* eslint-disable react/no-unused-state, react/prop-types, react/no-string-refs, react/no-multi-comp, prefer-destructuring, react/sort-comp */

import React from 'react';
import { Motion, spring } from 'react-motion';
import PropTypes from 'prop-types';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isReady: false };
  }

  componentDidMount() {
    this.setState({ isReady: true });
  }

  adjustContainer = e => {
    this.setState({ height: spring(e.currentTarget.innerHeight) });
  };

  render() {
    const { children } = this.props;

    if (this.state.isReady) {
      if (this.props.fullHeight) {
        /* This will throw an error, you can't do this */
        this.props.containerStyle.height = window.innerHeight;
      }
      return (
        <div ref="container" style={this.props.containerStyle}>
          {children}
        </div>
      );
    }
    return <div>Parallax hover is not yet ready.</div>;
  }

  static Layer = class extends React.Component {
    constructor(props) {
      super(props);
      this.config = this.props.config;
      this.state = {
        toStyle: {
          x: 0,
          y: 0
        }
      };
    }

    static propTypes = {
      config: PropTypes.shape({}),
      disabled: PropTypes.bool
    };

    static defaultProps = {
      config: {},
      disabled: true
    };

    componentDidMount() {
      if (!this.props.disabled) {
        window.addEventListener('mousemove', this.handleMouseMove, false);
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.disabled && this.props.disabled) {
        window.removeEventListener('mousemove', this.handleMouseMove, false);
      }
      if (prevProps.disabled && !this.props.disabled) {
        window.addEventListener('mousemove', this.handleMouseMove, false);
      }
    }

    componentWillUnmount() {
      if (!this.props.disabled) {
        window.removeEventListener('mousemove', this.handleMouseMove, false);
      }
    }

    updatePosition = e => {
      const xFactor = this.config.xFactor;
      const yFactor = this.config.yFactor;
      const getYFromCenter = yFactor * (e.view.innerHeight / 2 - e.clientY);
      const getXFromCenter = xFactor * (e.view.innerWidth / 2 - e.clientX);
      this.setState({
        toStyle: {
          x: spring(getXFromCenter, this.config.springSettings),
          y: spring(getYFromCenter, this.config.springSettings)
        }
      });
    };

    handleMouseMove = e => {
      window.requestAnimationFrame(() => this.updatePosition(e));
    };

    render() {
      const { children } = this.props;

      return (
        <Motion style={this.state.toStyle}>
          {motionStyle => (
            <div
              className="parallax-mousemove-container"
              ref="layer"
              style={{
                transform: `translate(${motionStyle.x}px, ${motionStyle.y}px)`,
                ...this.props.layerStyle
              }}
            >
              {children}
            </div>
          )}
        </Motion>
      );
    }
  };
}
