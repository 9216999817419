/* eslint-disable import/prefer-default-export */
import {amber, fuchsiaBlue, redRibbon, turquoise} from "styles/variables";

export const labelColorsByCode = {
  C182: fuchsiaBlue,
  C029: redRibbon,
  C105: redRibbon,
  C111: turquoise,
  C100: turquoise,
  C138: fuchsiaBlue,
  C098: amber,
  C087: amber,
  P029: redRibbon,
};

export const labelIdByCode = {
  C182: 'rights_visConventionsC182',
  C029: 'rights_visConventionsC29',
  C105: 'rights_visConventionsC105',
  C111: 'rights_visConventionsC111',
  C100: 'rights_visConventionsC100',
  C138: 'rights_visConventionsC138',
  C098: 'rights_visConventionsC98',
  C087: 'rights_visConventionsC87',
  P029: 'rights_visConventionsP29',
};

