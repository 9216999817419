import styled from 'styled-components';
import { white, blue, grey, offblue, offwhite } from 'styles/variables';
import media from 'styles/media';

const colors = {
  white,
  blue,
  offblue,
  grey
};

export const Background = styled.div`
  position: relative;
  width: 100%;
  height: auto;
   backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`;

export const SolidBackground = styled(Background)`
  background-color: ${props => colors[props.color]};
`;

export const StripedBackground = styled(Background)`
  background: linear-gradient(${offwhite} 65%, ${offblue} 35%);

  ${media.md`
    background: linear-gradient(${offwhite} 70%, ${offblue} 30%);
  `};

  ${media.sm`
    background: linear-gradient(${offwhite} 55%, ${offblue} 45%);
  `};
`;
