import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import { FormattedHTMLMessage } from 'react-intl';
import InfoBox from 'components/InfoBox';
import Collage from 'components/Collage';
import {
  OuterWrapper,
  InnerWrapper,
  BottomLayer,
  CollageWrapper,
  TopLayer
} from './styled';

const InfoCollage = ({ className, collage, infoBoxes, voffset }) => (
  <OuterWrapper className={className}>
    <InnerWrapper>
      <BottomLayer voffset={voffset}>
        <Row>
          <Col {...collage.colProps}>
            <CollageWrapper>
              <Collage
                imageStyle={{
                  height: { lg: 'auto' },
                  width: { lg: '100% ' }
                }}
                layerStyle={{
                  height: 'auto',
                  width: '100%'
                }}
                layers={collage.layers}
                caption={collage.caption}
              />
            </CollageWrapper>
          </Col>
        </Row>
      </BottomLayer>
      {infoBoxes.map(infoBox => (
        <TopLayer
          key={infoBox.message}
          justify={infoBox.justify}
          position={infoBox.position}
        >
          <Row>
            <Col {...infoBox.colProps}>
              <InfoBox {...infoBox.infoBox}>
                <FormattedHTMLMessage id={infoBox.message} />
              </InfoBox>
            </Col>
          </Row>
        </TopLayer>
      ))}
    </InnerWrapper>
  </OuterWrapper>
);

InfoCollage.propTypes = {
  collage: PropTypes.shape({}),
  infoBoxes: PropTypes.arrayOf(PropTypes.shape({})),
  voffset: PropTypes.shape({}),
  className: PropTypes.string
};

InfoCollage.defaultProps = {
  className: null,
  collage: {
    colProps: {},
    layers: [],
    caption: {
      message: '',
      colProps: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
      }
    }
  },
  infoBoxes: [
    {
      colProps: {},
      message: '',
      justify: 'flex-start',
      position: {}
    }
  ],
  voffset: {
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0
  }
};

export default InfoCollage;
