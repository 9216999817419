import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import Logo from 'components/Logo';

const Header = ({ color, children }) => (
  <header>
    <Row between="xs">
      <Col>
        <Logo color={color} />
      </Col>
      <Col>{children}</Col>
    </Row>
  </header>
);

Header.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string
}

Header.defaultProps = {
  children: null,
  color: 'color'
}

export default Header;
