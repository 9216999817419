import styled from 'styled-components';

export const Widget = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;
  vertical-align: middle;
  width: 100%;
`;

function buttonSize(size) {
  switch (size) {
    case 'large':
      return '2.5rem';
    case 'medium':
      return '2.0rem';
    case 'small':
      return '1.5rem';
    default:
      return '1.5rem';
  }
}

export const Button = styled.button`
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  height: ${props => buttonSize(props.size)};
  width: ${props => buttonSize(props.size)};
  margin: 0;
  border: none;

  &:focus {
    outline: 0;
  }
`;

export const Link = styled.a`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit;
`;
