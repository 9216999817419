/* eslint-disable no-prototype-builtins */

import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Details from 'components/Details';
import Modal from 'components/Modal';
import { Wrapper, Item } from './styled';

const List = ({ items, intl: { locale } }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);

  const handleOpenModal = i => {
    if (
      items[i].hasOwnProperty('detailFigure') &&
      items[i].hasOwnProperty('detailMessages')
    ) {
      setCurrentItem(i);
      setShowModal(true);
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderModal = () => (
    <Modal showModal={showModal} onRequestClose={handleCloseModal}>
      <Details
        figureProps={items[currentItem].detailFigure}
        messages={items[currentItem].detailMessages}
      />
    </Modal>
  );

  const itemsToRender = () => {
    if (items.every(item => typeof item === 'string')) {
      return items.map(item => ({ description: item }));
    }
    return items;
  };

  const itemHasDetail = item => {
    if (
      item.hasOwnProperty('detailFigure') &&
      item.hasOwnProperty('detailMessages')
    ) {
      return true;
    }
    return false;
  };

  return (
    <Outer>
      <Inner>
        <Wrapper>
          <Row className="list">
            {itemsToRender().map((item, i) => (
              <Col xs={12} sm={6} key={item.description}>
                <Item
                  locale={locale}
                  onClick={() => handleOpenModal(i)}
                  withDetail={itemHasDetail(item)}
                >
                  <div className="item-inner">
                    <span className="item-number ">{i + 1}</span>
                    <div className="item-content">
                      <span className="item-description">
                        <FormattedMessage id={item.description} />
                      </span>
                      {item.subtext ? (
                        <span className="item-subtext">
                          <FormattedMessage id={item.subtext} />
                        </span>
                      ) : null}
                    </div>
                  </div>
                </Item>
              </Col>
            ))}
          </Row>
        </Wrapper>
      </Inner>
      {showModal ? renderModal() : null}
    </Outer>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ).isRequired,
  intl: PropTypes.shape({}).isRequired
};

export default injectIntl(List);
