import styled from 'styled-components';

import {white} from "styles/variables";

export default styled.ul`
  &.slick-dots {
    bottom: -60px;
    li {
      margin: 0;
      
      button:before {
        font-size: 12px;
        color: ${white};
        opacity: 0.4;
      }
      
      &.slick-active {
        button:before {
          color: ${white};
          opacity: 1;
        }
      }
    }
  }
`;