import React from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedMessage, injectIntl, intlShape} from "react-intl";
import {renderTrackVerticalDefault} from "react-custom-scrollbars/lib/Scrollbars/defaultRenderElements";
import MediaQuery from 'react-responsive';

import SelectScrollBar from "components/ScrollBar";
import {cloudBurst, indigo} from "styles/variables";
import {Icon} from "components/Modal/styled";
import modalCloseIcon from "assets/images/modal-close.svg";

import {
  CountryCircle,
  CountryCloseButton,
  CountryConventions,
  CountryName,
  P,
  Row,
  Count,
  ConventionListItem,
  ConventionListItemName,
  ConventionListItemDate,
} from "./styled";

class CountryModal extends React.PureComponent {
  renderTrackVertical = ({ style, ...props }) => renderTrackVerticalDefault({
    ...props,
    className: 'track-vertical',
    style: {
      ...style,
      backgroundColor: cloudBurst,
    }
  });

  renderThumbVertical = ({ style, ...props }) => (
    <div
      {...props}
      style={{
        ...style,
        backgroundColor: indigo,
        borderRadius: 20,
      }}
      className="thumb-vertical"
    />
  );

  render() {
    const {
      intl: { locale: language },
      allConventions,
      conventions,
      selectedCountry,
      onClose,
    } = this.props;
    const {value: countryCode, label: countryName} = selectedCountry;

    return (
      <CountryConventions>

        <CountryCloseButton onClick={onClose}>
          <Icon src={modalCloseIcon} alt="modal close" />
        </CountryCloseButton>
        <CountryName>{countryName}</CountryName>

        <Row>
          <CountryCircle>
            <Count allCount={allConventions.length}>
              {conventions.length}
            </Count>
            <P><FormattedMessage id="rights_visConventionsFundratif" /></P>
          </CountryCircle>
        </Row>

        <Row>
          <MediaQuery maxDeviceWidth={751}>
            {(matches) => (
              <SelectScrollBar
                renderThumbVertical={this.renderThumbVertical}
                renderTrackVertical={this.renderTrackVertical}
                height={matches ? 350 : 290}
              >
                {conventions.map(convention => {
                  const { convention: { code, [language]: name } } = convention;
                  const { ratification: { date } } = convention.countries.filter(country => country.country.isocode3 === countryCode)[0];

                  return (
                    <ConventionListItem key={code}>
                      <ConventionListItemName>{code} - {name}</ConventionListItemName>
                      <ConventionListItemDate>
                        <FormattedMessage
                          id="ratificationDate"
                          tagName="div"
                          defaultMessage="{month} {year}"
                          values={{
                            month: <FormattedDate value={date} month="long">{value => value}</FormattedDate>,
                            year: <FormattedDate value={date} year="numeric">{value => value}</FormattedDate>
                          }}
                        />
                      </ConventionListItemDate>
                    </ConventionListItem>
                  )
                })}
              </SelectScrollBar>
            )}
          </MediaQuery>
        </Row>
      </CountryConventions>
    )
  }
}

CountryModal.propTypes = {
  allConventions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  conventions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedCountry: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default injectIntl(CountryModal);