import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const defaultContext = {
  chapter: 'tripartism',
  color: 'offblue'
};

export const ThemeContext = React.createContext(defaultContext);

class Chapter extends PureComponent {
  render() {
    const { children, theme } = this.props;
    return (
      <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
    );
  }
}

Chapter.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired
};


export default Chapter;
