import styled from 'styled-components';
import { H2, h2Mixin, paragraphMixin } from 'styles/typography';
import matchColor from 'utils/matchColor';
import { titleSans, serif, black, rose, white } from 'styles/variables';
import media from 'styles/media';
import circle from 'assets/images/circle-stamp.png';
import bluecircle from 'assets/images/blue-circle.png';
import fingerprint from 'assets/images/fingerprint.png';
import pinkcircle from 'assets/images/pink-circle.png';
import greencircle from 'assets/images/green-circle.png';
import Tear from 'components/Tear';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const LargeCallout = styled(H2)`
  font-size: 11.5rem;
  font-family: ${titleSans};
  text-align: center;
  text-transform: uppercase;
  color: ${props => matchColor(props.color)};
  line-height: 13.125rem;
  margin: 0;
  letter-spacing: -10px;

  ${media.md`
    font-size: 9.375rem;
    line-height: 11.25rem;
    letter-spacing: -5px;
  `};

  ${media.xs`
    font-size: ${props => (props.locale === 'ru' ? '4.5rem' : '5.625rem')}
    font-size: 5.625rem;
    line-height: 5.625rem;
    letter-spacing: -4px;
  `};
`;

export const MediumCallout = styled(H2)`
  font-size: 8.125rem;
  font-family: ${titleSans};
  text-align: center;
  text-transform: uppercase;
  color: ${props => matchColor(props.color)};
  line-height: 8.75rem;
  margin: 0;
  letter-spacing: -4px;

  ${media.md`
    font-size: 5rem;
    line-height: 5.1rem;
    letter-spacing: 0px;
  `}

  ${media.sm`
    font-size: 5rem;
    line-height: 5.12rem;
    letter-spacing: -4px;
  `}

  ${media.xs`
    font-size: ${props => (props.locale === 'ru' ? '2.8rem' : '3.125rem')};
    line-height: 3.625rem;
    letter-spacing: -4px;
  `};
`;

const stamp = {
  circle,
  fingerprint,
  bluecircle,
  pinkcircle,
  greencircle
};

export const SmallCalloutWrapper = styled.div`
  min-height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem;
  text-align: center;
  background: url(${props => stamp[props.stamp]}) center center no-repeat;
  background-size: contain;

  ${media.xs`
    margin: 0;
  `};
`;

export const SmallCallout = styled(H2)`
  font-size: 2.5rem;
  font-family: ${serif};
  font-weight: 400;
  letter-spacing: -0.5px;
  color: ${black};
  line-height: 3.4375rem;

  ${media.md`
    font-size: 1.875rem;
    line-height: 2.8125rem;
  `};

  ${media.xs`
    margin: 1rem 0;
  `};
`;

export const TearsCallout = styled.section`
  width: 100%;
  height: auto;
  background-color: ${props => props.color};
  position: relative;
  padding: 150px 0;
  text-align: center;

  ${media.xs`
    padding: 50px 0 100px;
  `};

  h3 {
    ${h2Mixin};
    color: ${rose};
    font-weight: 500;
  }

  p {
    ${paragraphMixin};
    color: ${white};
    font-family: ${serif};
    font-size: 1.625rem;
    text-align: center;
    margin: 0 0 3rem;
    line-height: 2.5rem;
    width: 100%;

    ${media.md`
      font-size: 1.375rem;
      line-height: 2rem;
  `};

    ${media.xs`
      text-align: left;
      font-size: 1.375rem;
      line-height: 2rem;
  `};
  }

  .caption {
    ${paragraphMixin};
  }
`;

export const Center = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TopTear = styled(Tear)`
  transform: scaleY(-1) scaleX(-1);
  bottom: initial;
  top: -1px;
  bottom: 0;
`;
