import React from 'react';
import PropTypes from 'prop-types';
import { Button, Bars, Bar } from './styled';

const SoundIcon = ({ onClick, status, hidden }) => (
  <Button onClick={onClick} style={hidden ? { display: 'none' } : null}>
    <Bars>
      {[0, 1, 2, 3, 4].map(icon => (
        <Bar key={icon} status={status} />
      ))}
    </Bars>
  </Button>
);

SoundIcon.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func,
  hidden: PropTypes.bool
};

SoundIcon.defaultProps = {
  status: 'STOPPED',
  onClick: () => {},
  hidden: false
};

export default SoundIcon;
