import styled, { keyframes } from 'styled-components';
import media from 'styles/media';

export const Button = styled.button`
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  width: 20px;
  height: 16px;
  position: relative;
  outline: none;

  ${media.xs`
    display: none;
  `};
`;

export const Bars = styled.div`
  border: 0;
  padding: 0;
`;

const sound = keyframes`
  {
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(2);
    }
  }
`;

export const Bar = styled.span`
  background: #666;
  opacity: ${props => (props.status === 'PLAYING' ? 1 : 0.5)};
  bottom: 1px;
  height: 9px;
  position: absolute;
  width: 1px;
  animation: ${sound} 0ms -800ms linear infinite alternate;
  animation-play-state: ${props =>
    props.status === 'PLAYING' ? 'running' : 'paused'};
  transition: opacity 150ms ease;
  transform-origin: bottom;

  &:nth-child(1) {
    left: 1px;
    animation-duration: 474ms;
  }

  &:nth-child(2) {
    left: 5px;
    animation-duration: 433ms;
  }

  &:nth-child(3) {
    left: 9px;
    animation-duration: 407ms;
  }

  &:nth-child(4) {
    left: 13px;
    animation-duration: 458ms;
  }

  &:nth-child(5) {
    left: 17px;
    animation-duration: 400ms;
  }
`;

export default Button;
