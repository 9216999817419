import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Player from 'components/Player';
import { Wrapper, Overlay, Details } from './styled';

const DesktopVideo = ({ videoID, details, handleShowModal, fullwidth }) => (
  <Wrapper onClick={handleShowModal} fullwidth={fullwidth}>
    <video
      loop
      muted
      preload="auto"
      autoPlay
      poster={`${Player.getVideoUrlById(`${videoID}Thumb`)}.jpg`}
    >
      <source
        src={Player.getVideoUrlById(`${videoID}Thumb`, 'auto', 75, 'auto', {
          ac: 'none',
          f: 'webm'
        })}
        type="video/webm"
      />
      <source
        src={Player.getVideoUrlById(`${videoID}Thumb`, 'auto', 75, 'auto', {
          ac: 'none',
          f: 'mp4'
        })}
        type="video/mp4"
      />
      <p>
        <FormattedMessage id="interface_html5VideoError" />
      </p>
    </video>
    <Overlay>
      <Details dangerouslySetInnerHTML={{ __html: details }} />
    </Overlay>
  </Wrapper>
);

DesktopVideo.propTypes = {
  videoID: PropTypes.string,
  details: PropTypes.string,
  handleShowModal: PropTypes.func,
  fullwidth: PropTypes.bool
};

DesktopVideo.defaultProps = {
  videoID: '',
  details: '',
  handleShowModal: () => {},
  fullwidth: false
};

export default DesktopVideo;
