import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoBox from 'components/InfoBox/InfoBox';
import Details from 'components/Details';
import Modal from 'components/Modal';

export default class Info extends Component {
  static propTypes = {
    detailMessages: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    detailFigure: PropTypes.shape({})
  };

  static defaultProps = {
    detailMessages: null,
    detailFigure: null
  };

  state = {
    showModal: false
  };

  componentDidMount() {
    if (this.props.detailMessages && this.props.id) {
      const { id } = this.props;
      this.linkedText = document.getElementById(id);
      if (this.linkedText) {
        this.linkedText.addEventListener('click', this.handleOpenModal);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.detailMessages && this.linkedText) {
      this.linkedText.removeEventListener('click', this.handleOpenModal);
    }
  }

  handleOpenModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  renderModal = () => {
    const { showModal } = this.state;
    const { detailFigure, detailMessages } = this.props;
    return (
      <Modal showModal={showModal} onRequestClose={this.handleCloseModal}>
        <Details figureProps={detailFigure} messages={detailMessages} />
      </Modal>
    );
  };

  render() {
    const { detailMessages } = this.props;
    const { showModal } = this.state;
    return (
      <Fragment>
        <InfoBox {...this.props} />
        {showModal && detailMessages ? this.renderModal() : null}
      </Fragment>
    );
  }
}
